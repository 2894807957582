// @flow
import { Dispatch } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get } from 'api/RestApi';
import { FARMER_TELEMETRY_STATUS_CHECK, MANAGER_TELEMETRY_STATUS_CHECK } from 'constants/RestApiConstants';

export async function checkManagerTelemetriesStatus(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(MANAGER_TELEMETRY_STATUS_CHECK)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function checkFarmerTelemetriesStatus(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(FARMER_TELEMETRY_STATUS_CHECK)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}
