// @flow

import { Divider, Paper, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useIntl } from 'react-intl';
import { WORLD_MAP_LEGEND_ELEMENTS } from 'constants/GlobalConstants';
import { WorldMapElementsTranslationId } from 'mappers/translationMappers';
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import { storeWorldMapElementsSettings } from 'api/service/LocalStorageService';

type Props = {
  mapElementsSettings: Object,
  setMapElementsSettings: Function
};
const MapElementsToggle = ({ mapElementsSettings, setMapElementsSettings }: Props): React$Node => {
  const theme = useTheme();
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, element: string) => {
    const updatedSettings = { ...mapElementsSettings, [element]: event.target.checked };
    setMapElementsSettings(updatedSettings);
    storeWorldMapElementsSettings(updatedSettings);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 1,
        pb: 0,
        pt: 0.5,
        maxHeight: theme.spacing(9)
      }}
    >
      <Typography variant="subtitle1">{intl.formatMessage({ id: 'app.general.overview.map.settings' })}</Typography>
      <Divider sx={{ borderColor: grey[300] }} />
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', pt: 1, pb: 1 }}>
        {WORLD_MAP_LEGEND_ELEMENTS?.map((element) => (
          <Box sx={{ display: 'flex', mr: 1.5, alignItems: 'center' }} key={element}>
            <Switch
              size="small"
              checked={mapElementsSettings[element]}
              onChange={(event) => handleChange(event, element)}
            />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {intl.formatMessage({ id: WorldMapElementsTranslationId[element] })}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default MapElementsToggle;
