// @flow

import { grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type { TbAssetTelemetriesExtendedItem } from 'types/Thingsboard.types';
import Box from '@mui/material/Box';
import { FARMER_DEVICE_ATTRIBUTE_ENUM, FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/DeviceConstants';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { isDefined, isEmpty, isNotEmpty } from 'util/ObjectUtils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateTelemetryAttribute } from 'api/service/ThingsboardFarmerTelemetryService';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { DATA_GRID_DIALOG_TYPE } from 'constants/GlobalConstants';
import { GRAIN_ANALYZERS, TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/ThingsboardConstants';
import TextField from '@mui/material/TextField';

type Props = {
  open: boolean,
  handleClose: Function,
  device: TbAssetTelemetriesExtendedItem,
  attributeToUpdate: String,
  fetchDevices: Function,
  tabSelected?: String
};

const DevicesAttributesDialog = ({
  open,
  handleClose,
  device,
  attributeToUpdate,
  fetchDevices,
  tabSelected
}: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isFreeValue, setIsFreeValue] = useState(null);
  const [prepaidYearsValue, setPrepaidYearsValue] = useState('');
  const [orderNumberValue, setOrderNumberValue] = useState('');

  useEffect(() => {
    const attribute = device?.attributes?.find((attribute) => attribute.key === attributeToUpdate);
    const attributeValue = attribute?.value;
    if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE) {
      setIsFreeValue(attributeValue);
    } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS) {
      setPrepaidYearsValue(attributeValue);
    } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER) {
      setOrderNumberValue(attributeValue);
    }
  }, []);

  const handleDialogClose = () => {
    setIsFreeValue(null);
    setPrepaidYearsValue(null);
    handleClose();
  };

  const handleIsFreeChange = () => {
    const newValue = !isFreeValue;
    setIsFreeValue(newValue);
  };

  const handlePrepaidYearsChange = (event: Event) => {
    let newValue = event.target.value ?? null;
    if (isNotEmpty(newValue)) {
      newValue = parseInt(newValue, 10);
    }
    setPrepaidYearsValue(newValue);
  };

  const handleOrderNumberChange = (event: Event) => {
    let newValue = event.target.value ?? null;
    setOrderNumberValue(newValue);
  };

  const handleSave = () => {
    let newUpdateObject;
    if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE) {
      const body = JSON.stringify({
        [FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE]: isFreeValue
      });
      newUpdateObject = {
        entityId: device.assetDto.id.id,
        entityType: device.assetDto.id.entityType,
        body: body
      };
    } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS) {
      const body = JSON.stringify({
        [FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS]: prepaidYearsValue
      });
      newUpdateObject = {
        entityId: device.assetDto.id.id,
        entityType: device.assetDto.id.entityType,
        body: body
      };
    } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER) {
      const body = JSON.stringify({
        [FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER]: orderNumberValue
      });
      newUpdateObject = {
        entityId: device.assetDto.id.id,
        entityType: device.assetDto.id.entityType,
        body: body
      };
    }

    updateTelemetryAttribute(dispatch, newUpdateObject)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.alert.success.device.update' })
        };
        dispatch(addAlert(alert));
      })
      .then(() => {
        if (isDefined(tabSelected)) {
          let devicesToFetch = null;
          if (tabSelected === FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR) {
            devicesToFetch = [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR];
          } else if (tabSelected === DATA_GRID_DIALOG_TYPE.DEVICE_DIALOG_GRAIN_ANALYZERS) {
            devicesToFetch = GRAIN_ANALYZERS;
          }
          fetchDevices(devicesToFetch);
        } else {
          fetchDevices();
        }
      })
      .then(handleDialogClose);
  };

  let ResolvedContent;
  if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE) {
    const label = isFreeValue
      ? intl.formatMessage({ id: 'app.datagrid.subscription.free' })
      : intl.formatMessage({ id: 'app.datagrid.subscription.notFree' });
    ResolvedContent = (
      <Box>
        <FormControlLabel
          control={<Switch checked={isFreeValue ?? false} onChange={handleIsFreeChange} />}
          label={label}
        />
      </Box>
    );
  } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS) {
    ResolvedContent = (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={intl.formatMessage({ id: 'app.devices.update.prepaidYears' })}
          type={'number'}
          value={prepaidYearsValue}
          onChange={handlePrepaidYearsChange}
        />
      </Box>
    );
  } else if (attributeToUpdate === FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER) {
    ResolvedContent = (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={intl.formatMessage({ id: 'app.devices.update.orderNumber' })}
          value={orderNumberValue}
          onChange={handleOrderNumberChange}
        />
      </Box>
    );
  }

  const resolveIsSaveDisabled = () => {
    return isEmpty(isFreeValue) && isEmpty(prepaidYearsValue) && isEmpty(orderNumberValue);
  };

  const isSaveDisabled = resolveIsSaveDisabled();
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.devices.update.attributes' }, { name: device?.assetDto?.name })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>{ResolvedContent}</Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button onClick={handleSave} variant="contained" size="small" disabled={isSaveDisabled}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleDialogClose}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DevicesAttributesDialog;
