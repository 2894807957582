export const MANAGER_TENANT_TELEMETRY_ENUM = {
  LAST_ACTIVITY_TIME: 'lastActivityTime',
  TOTAL_ACTIVE_ALARMS_COUNT: 'totalActiveAlarmsCount',
  TOTAL_STALE_ALARMS_COUNT: 'totalStaleAlarmsCount',
  TOTAL_ACTIVE_ALARMS_TYPES: 'totalActiveAlarmsTypes',
  TOTAL_STALE_ALARMS_TYPES: 'totalStaleAlarmsTypes',
  TOTAL_CO2_SENSOR_COUNT: 'totalCO2SensorCount',
  TOTAL_LEVEL_INDICATOR_SENSOR_COUNT: 'totalLevelIndicatorSensorCount',
  TOTAL_HEAD_SPACE_SENSOR_COUNT: 'totalHeadSpaceSensorCount',
  TOTAL_MOISTURE_SENSOR_COUNT: 'totalMoistureSensorCount',
  TOTAL_TEMPERATURE_SENSOR_COUNT: 'totalTemperatureSensorCount',
  TOTAL_SILO_COUNT: 'totalSiloCount',
  TOTAL_AERATION_SYSTEM_COUNT: 'totalAerationSystemCount'
};

export const MANAGER_TENANT_ATTRIBUTE_ENUM = {
  ODOO_TENANT: 'odooTenant',
  CHARGEBEE_CUSTOMER: 'chargebee_customer',
  CHARGEBEE_SUBSCRIPTIONS: 'chargebee_subscriptions',
  TENANT_STATUS: 'tna_status',
  SHUTOUT_DATE: 'shutout_date',
  COMMENT: 'comment',
  TENANT_NOTE: 'tenant_note',
  ON_PREMISE_CONFIG: 'on_premise_config',
  IS_ON_PREMISE: 'is_on_premise'
};

export const TENANT_STATUS_ENUM = {
  DEVELOPMENT: 'development',
  ARCHIVED: 'archived',
  CANCELED: 'canceled'
};
