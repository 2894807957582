// @flow
import { createSlice } from '@reduxjs/toolkit';

import type { Alert, ApplicationState } from 'types/State.types';
import { SUPPORTED_LOCALES } from 'i18n/LocaleUtils';
import { getSelectedThemeMode } from 'api/service/LocalStorageService';

const initialState = {
  themeMode: getSelectedThemeMode(),
  booted: false,
  loadingCounter: 0,
  locale: SUPPORTED_LOCALES.EN.locale,
  alerts: [],
  settings: {
    registrationEnabled: false,
    invitationEnabled: false,
    managerUrl: '',
    farmerAppUrl: '',
    odooUrl: '',
    chargeBeeUrl: ''
  }
};

export const ApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setThemeMode: (draftState: ApplicationState, action: any) => {
      draftState.themeMode = action.payload;
    },
    setBooted: (draftState: ApplicationState, action: any) => {
      draftState.booted = action.payload;
    },
    startLoading: (draftState: ApplicationState) => {
      draftState.loadingCounter += 1;
    },
    stopLoading: (draftState: ApplicationState) => {
      if (draftState.loadingCounter > 0) {
        draftState.loadingCounter -= 1;
      }
    },
    setLocale: (draftState: ApplicationState, action: any) => {
      draftState.locale = action.payload;
    },
    addAlert: (draftState: ApplicationState, action: any) => {
      draftState.alerts.push(action.payload);
    },
    removeAlert: (draftState: ApplicationState, action: any) => {
      draftState.alerts = draftState.alerts.filter((alert: Alert) => alert.id !== action.payload);
    },
    setApplicationSettings: (draftState: ApplicationState, action: any) => {
      draftState.settings = action.payload;
    }
  }
});

const { actions, reducer } = ApplicationSlice;
export const {
  setThemeMode,
  setBooted,
  startLoading,
  stopLoading,
  setLocale,
  addAlert,
  removeAlert,
  setApplicationSettings
} = actions;

export default reducer;
