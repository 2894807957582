// @flow
import { boolean, object, string } from 'yup';

const LoginSchema = object({
  username: string().required('app.validation.required'),
  password: string().required('app.validation.required'),
  rememberMe: boolean().notRequired()
});

const RequestPasswordResetSchema = object({
  email: string().required('app.validation.required').email('app.validation.invalid.format')
});

const PasswordResetSchema = object({
  password: string().required('app.validation.required'),
  confirmPassword: string()
    .required('app.validation.required')
    .test('password-validation', 'app.validation.password.missmatch', (value: string, context: any): boolean => {
      return context.parent.password === value;
    })
});

export const validateLoginForm = (loginData): Promise =>
  LoginSchema.validate(loginData, { strict: true, abortEarly: false });

export const validateRequestResetForm = (requestResetData): Promise =>
  RequestPasswordResetSchema.validate(requestResetData, { strict: true, abortEarly: false });

export const validateResetPasswordForm = (resetData): Promise =>
  PasswordResetSchema.validate(resetData, { strict: true, abortEarly: false });
