// @flow

import { Dispatch } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get, post } from 'api/RestApi';
import { ODOO_NOTE, ODOO_SALES_ORDERS, ODOO_TENANTS } from 'constants/RestApiConstants';
import type { OdooTenant } from 'types/Odoo.types';
import { handleError } from 'api/RestApiHelper';

export async function getOdooTenants(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ODOO_TENANTS)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getSalesOrders(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ODOO_SALES_ORDERS)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function updateOdooTenantNote(odooTenant: OdooTenant, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ODOO_NOTE, odooTenant)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
