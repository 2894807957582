// @flow
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import {
  AdminPanelSettingsOutlined,
  EngineeringOutlined,
  ExpandLess,
  ExpandMore,
  GroupsOutlined,
  HolidayVillageOutlined,
  PeopleOutline,
  PhonelinkRingOutlined,
  VillaOutlined
} from '@mui/icons-material';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';

import {
  ADMINISTRATION,
  ADMINISTRATION_SERVICES,
  ADMINISTRATION_USERS,
  ANALYTICS,
  FARMER_APP,
  FARMER_APP_CUSTOMERS,
  FARMER_APP_DEVICES,
  GENERAL,
  GENERAL_NODES_MAP_MANAGER,
  GENERAL_OVERVIEW,
  MANAGER,
  MANAGER_GATEWAYS,
  MANAGER_SUBSCRIPTIONS,
  MANAGER_TENANTS,
  SERVICE_OVERVIEW,
  SERVICE_STATUS_APP,
  SERVICE_STATUS_MANAGER
} from 'routing/RouteConstants';
import TopNavigationMenu from 'components/navigation/TopNavigationMenu';
import ThemeMode from 'components/ThemeMode';
import { CardMedia, Collapse, useMediaQuery } from '@mui/material';
import { isFunctionDefined, isNotEmptyList } from 'util/ObjectUtils';
import { isAdministrator } from 'store/selector/UserSelector';
import AppLogoIcon from 'assets/app_logo.svg';
import { useTheme } from '@mui/styles';

const drawerWidth = 240;
export const topBarHeight = 8; // MUI unit

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  border: 'none'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  border: 'none'
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: theme.spacing(topBarHeight),
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function NavigationBar({ children }: any): React$Node {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const administrator = useSelector(isAdministrator);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [generalOpen, setGeneralOpen] = React.useState(false);
  const [managerOpen, setManagerOpen] = React.useState(false);
  const [farmerAppOpen, setFarmerAppOpen] = React.useState(false);
  const [serviceOverviewOpen, setServiceOverviewOpen] = React.useState(false);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleAdminOpen = () => {
    setAdminOpen(!adminOpen);
  };
  const toggleGeneralOpen = () => {
    setGeneralOpen(!generalOpen);
  };
  const toggleManagerOpen = () => {
    setManagerOpen(!managerOpen);
  };
  const toggleFarmerAppOpen = () => {
    setFarmerAppOpen(!farmerAppOpen);
  };
  const toggleServiceOverviewOpen = () => {
    setServiceOverviewOpen(!serviceOverviewOpen);
  };

  const SideMenuItems = [
    {
      textKey: 'app.navigation.general',
      icon: <TravelExploreOutlinedIcon />,
      path: GENERAL,
      onClick: toggleGeneralOpen,
      isSubmenuOpen: generalOpen,
      submenu: [
        {
          textKey: 'app.navigation.general.worldMap',
          icon: <MapOutlinedIcon />,
          path: GENERAL_OVERVIEW
        },
        {
          textKey: 'app.navigation.general.nodesMap',
          icon: <HubOutlinedIcon />,
          path: GENERAL_NODES_MAP_MANAGER
        }
      ]
    },
    {
      textKey: 'app.navigation.service.overview',
      icon: <InfoOutlinedIcon />,
      path: SERVICE_OVERVIEW,
      onClick: toggleServiceOverviewOpen,
      isSubmenuOpen: serviceOverviewOpen,
      submenu: [
        {
          textKey: 'app.navigation.service.overview.statusManager',
          icon: <VillaOutlined />,
          path: SERVICE_STATUS_MANAGER
        },
        {
          textKey: 'app.navigation.service.overview.statusApp',
          icon: <EngineeringOutlined />,
          path: SERVICE_STATUS_APP
        }
      ]
    },
    {
      textKey: 'app.navigation.manager',
      icon: <VillaOutlined />,
      path: MANAGER,
      onClick: toggleManagerOpen,
      isSubmenuOpen: managerOpen,
      submenu: [
        {
          textKey: 'app.navigation.manager.tenants',
          icon: <HolidayVillageOutlined />,
          path: MANAGER_TENANTS
        },
        {
          textKey: 'app.navigation.manager.subscriptions',
          icon: <CardMembershipOutlinedIcon />,
          path: MANAGER_SUBSCRIPTIONS
        },
        {
          textKey: 'app.navigation.manager.gateways',
          icon: <PhonelinkRingOutlinedIcon />,
          path: MANAGER_GATEWAYS
        }
      ]
    },
    {
      textKey: 'app.navigation.farmerApp',
      icon: <EngineeringOutlined />,
      path: FARMER_APP,
      onClick: toggleFarmerAppOpen,
      isSubmenuOpen: farmerAppOpen,
      submenu: [
        {
          textKey: 'app.navigation.farmerApp.customers',
          icon: <GroupsOutlined />,
          path: FARMER_APP_CUSTOMERS
        },
        {
          textKey: 'app.navigation.farmerApp.devices',
          icon: <PhonelinkRingOutlined />,
          path: FARMER_APP_DEVICES
        }
      ]
    },
    {
      textKey: 'app.navigation.analytics',
      icon: <QueryStatsOutlinedIcon />,
      path: ANALYTICS
    }
  ];

  if (administrator) {
    const adminNavigationItem = {
      textKey: 'app.navigation.administration',
      icon: <AdminPanelSettingsOutlined />,
      path: ADMINISTRATION,
      onClick: toggleAdminOpen,
      isSubmenuOpen: adminOpen,
      submenu: [
        {
          textKey: 'app.navigation.administration.users',
          icon: <PeopleOutline />,
          path: ADMINISTRATION_USERS
        },
        {
          textKey: 'app.navigation.administration.services',
          icon: <SettingsSuggestOutlinedIcon />,
          path: ADMINISTRATION_SERVICES
        }
      ]
    };

    SideMenuItems.push(adminNavigationItem);
  }

  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: 'white' }} elevation={0}>
        <Toolbar>
          <CardMedia
            component="img"
            image={AppLogoIcon}
            alt="Agrolog Logo"
            sx={{ pt: 1, pb: 1, ml: '22px', width: '148px', height: 'auto' }}
          />
          <TopNavigationMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={!isSmallDevice}>
        <List sx={{ mt: 7 }}>
          {SideMenuItems.map((menuItem) => (
            <React.Fragment key={`menu-item-fragment-${menuItem.path}`}>
              <ListItem key={`side-menu-${menuItem.path}`} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                  onClick={() => (isFunctionDefined(menuItem.onClick) ? menuItem.onClick() : navigate(menuItem.path))}
                  selected={location.pathname.includes(menuItem.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: menuItem.textKey })} sx={{ opacity: open ? 1 : 0 }} />
                  {isNotEmptyList(menuItem.submenu) &&
                    open &&
                    (menuItem.isSubmenuOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </ListItem>
              {isNotEmptyList(menuItem.submenu) && (
                <Collapse
                  key={`side-submenu-collapse-${menuItem.path}`}
                  in={menuItem.isSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List key={`submenu-list-${menuItem.path}`} component="div" disablePadding>
                    {menuItem.submenu.map((submenuItem) => (
                      <ListItemButton
                        key={`side-submenu-item-${submenuItem.path}`}
                        sx={{ pl: 4 }}
                        selected={location.pathname.includes(submenuItem.path)}
                        onClick={() =>
                          isFunctionDefined(submenuItem.onClick) ? submenuItem.onClick : navigate(submenuItem.path)
                        }
                      >
                        <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: submenuItem.textKey })} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
        <ThemeMode sx={{ m: 0.5 }} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2, width: `calc(100vw - ${drawerWidth}px)`, height: '100%' }}>
        {children}
      </Box>
    </Box>
  );
}
