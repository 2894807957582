// @flow
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { IntlShape, useIntl } from 'react-intl';
import type { TbAssetTelemetriesItem, TelemetryAttribute, TelemetryData } from 'types/Thingsboard.types';
import { TbAssetTelemetriesExtendedItem } from 'types/Thingsboard.types';
import { DateTime } from 'luxon';
import { isDefined, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import Tooltip from '@mui/material/Tooltip';
import {
  FARMER_DEVICE_ATTRIBUTE_ENUM,
  FARMER_DEVICE_TAB_NAVIGATION_ENUM,
  FARMER_DEVICE_TELEMETRY_ENUM,
  SENSOR_TYPES_ENUM
} from 'constants/DeviceConstants';
import { InputAdornment, Stack } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import StatisticsItem from 'components/dashboard/StatisticsItem';
import { TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/ThingsboardConstants';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { max, round } from 'lodash';
import Typography from '@mui/material/Typography';
import {
  DEVICES_DATAGRID_COLUMN_VISIBILITY_V3,
  getDataGridColumnVisibility,
  storeDataGridColumnVisibility
} from 'api/service/LocalStorageService';
import { DEVICES_DATAGRID_COLUMNS, DEVICES_DATAGRID_GROUP_COLUMNS } from 'constants/DataGridConstants';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { DATA_GRID_TYPE, WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import DataGridSettingsDrawer from 'components/drawer/DataGridSettingsDrawer';
import WorldMapDialog from 'components/dialog/WorldMapDialog';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useTheme } from '@mui/styles';
import { getDetailedRelativeTime } from 'util/DateUtils';
import DevicesAttributesDialog from 'components/dialog/farmer/DevicesAttributesDialog';
import { useDispatch, useSelector } from 'react-redux';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeviceAssignmentDialog from 'components/dialog/farmer/DeviceAssignementDialog';
import { getSalesOrders } from 'api/service/OdooApiService';
import { OdooSalesOrder } from 'types/Odoo.types';
import { getOdooUrlSetting } from 'store/selector/ApplicationSelector';
import Link from '@mui/material/Link';

const resolveCropTypeKey = (cropType: string, sensorType: string): string => {
  if (sensorType === SENSOR_TYPES_ENUM.SENSOR_1) {
    return `emc-${cropType}-1`;
  } else if (sensorType === SENSOR_TYPES_ENUM.SENSOR_AMBIENT) {
    return `emc-${cropType}-ambient`;
  }
};

const resolveColumnVisibilityModel = (visibleColumns: Array): any => {
  return {
    claimedTs: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.CLAIMED],
    isFree: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.IS_FREE],
    prepaidUntil: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.PREPAID_YEARS],
    periodEnd: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.SUBSCRIPTION_END],
    orderNumber: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.ORDER_NUMBER],
    distributor: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.INFO][DEVICES_DATAGRID_COLUMNS.DISTRIBUTOR],
    latestSample: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.LATEST_SAMPLE],
    temperature1: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.TEMPERATURE_1],
    temperature2: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.TEMPERATURE_2],
    temperatureAmbient:
      visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.TEMPERATURE_AMBIENT],
    humidity1: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.HUMIDITY_1],
    humidityAmbient: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.HUMIDITY_AMBIENT],
    cropType: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.CROP_TYPE],
    cropSensor1: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.CROP_SENSOR_1],
    cropAmbient: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.SENSOR][DEVICES_DATAGRID_COLUMNS.CROP_AMBIENT],
    longitude: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.LOCATION][DEVICES_DATAGRID_COLUMNS.LONGITUDE],
    latitude: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.LOCATION][DEVICES_DATAGRID_COLUMNS.LATITUDE],
    simLongitude: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.LOCATION][DEVICES_DATAGRID_COLUMNS.SIM_LONGITUDE],
    simLatitude: visibleColumns[DEVICES_DATAGRID_GROUP_COLUMNS.LOCATION][DEVICES_DATAGRID_COLUMNS.SIM_LATITUDE]
  };
};

const customNumberComparator = (v1: number, v2: number): number => {
  if (isDefined(v1) && isDefined(v2)) {
    return v1 - v2;
  } else if (isDefined(v1)) {
    return 1;
  } else if (isDefined(v2)) {
    return -1;
  }
  return 0;
};

const createColumns = (
  intl: IntlShape,
  handleOpenDevicesAttributesDialog: Function,
  handleOpenDeviceAssignmentDialog: Function,
  isAdminOrSupport: boolean,
  tabSelected: string,
  odooUrl: string
): GridColDef[] => {
  const columns = [
    { field: 'id', headerName: '#', width: 60 },
    {
      field: 'type',
      headerName: intl.formatMessage({ id: 'app.datagrid.type' }),
      width: 150
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 200
    },
    {
      field: 'customerName',
      headerName: intl.formatMessage({ id: 'app.datagrid.assignedTo' }),
      width: 200,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        let customerName = cellValues.row.customerName;
        const id = cellValues.row.entityId?.id;

        const handleItemClick = () => {
          handleOpenDeviceAssignmentDialog(id);
        };

        const cursor = isAdminOrSupport ? 'pointer' : 'default';
        return (
          <Tooltip
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>{customerName ?? intl.formatMessage({ id: 'app.common.not.available' })}</span>
              </Box>
            }
          >
            <Typography
              variant="body2"
              sx={{
                '&:hover': {
                  cursor: cursor
                }
              }}
              onClick={isAdminOrSupport ? handleItemClick : null}
            >
              {customerName ??
                (isAdminOrSupport && (
                  <IconButton onClick={isAdminOrSupport ? handleItemClick : null} size="small">
                    <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                ))}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'claimedTs',
      headerName: intl.formatMessage({ id: 'app.datagrid.claimedAt' }),
      valueFormatter: (v: any): string => {
        const claimedTs = v.value;
        let claimedDateTimeFormatted;

        if (claimedTs > 0) {
          let claimedDateTime = DateTime.fromSeconds(claimedTs);
          claimedDateTimeFormatted = claimedDateTime?.toLocaleString(DateTime.DATETIME_MED);
        }

        return claimedDateTimeFormatted ?? intl.formatMessage({ id: 'app.common.not.available' });
      },
      width: 180,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const claimedTs = cellValues.row.claimedTs;
        let claimedDateTimeFormatted;

        if (claimedTs > 0) {
          let claimedDateTime = DateTime.fromSeconds(claimedTs);
          claimedDateTimeFormatted = claimedDateTime?.toLocaleString(DateTime.DATETIME_MED);
        }

        return (
          <Tooltip
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>{`${intl.formatMessage({ id: 'app.datagrid.claimed' })}: ${
                  claimedDateTimeFormatted ?? intl.formatMessage({ id: 'app.common.not.available' })
                }`}</span>
              </Box>
            }
          >
            <Typography variant="body2">
              {claimedDateTimeFormatted ?? intl.formatMessage({ id: 'app.common.not.available' })}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'longitude',
      headerName: intl.formatMessage({ id: 'app.datagrid.longitude' }),
      width: 200
    },
    {
      field: 'latitude',
      headerName: intl.formatMessage({ id: 'app.datagrid.latitude' }),
      width: 200
    },
    {
      field: 'simLongitude',
      headerName: intl.formatMessage({ id: 'app.datagrid.simLongitude' }),
      width: 180
    },
    {
      field: 'simLatitude',
      headerName: intl.formatMessage({ id: 'app.datagrid.simLatitude' }),
      width: 180
    }
  ];

  if (tabSelected === FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR) {
    columns.splice(
      5,
      0,
      {
        field: 'orderNumber',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.orderNumber' }),
        width: 180,
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let orderNumber = cellValues.row.orderNumber;
          const id = cellValues.row.entityId?.id;
          const orderId = cellValues.row.orderId;

          let odooSalesOrderLink = `${odooUrl}web#id=${orderId}&model=sale.order&view_type=form`;

          const handleIconClick = () => {
            handleOpenDevicesAttributesDialog(FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER, id);
          };

          return (
            <>
              <Tooltip title={orderNumber}>
                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <Link href={odooSalesOrderLink} target="_blank">
                    {orderNumber}
                  </Link>
                </Typography>
              </Tooltip>
              {isAdminOrSupport && (
                <IconButton onClick={isAdminOrSupport ? handleIconClick : null} size="small">
                  <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
            </>
          );
        }
      },
      {
        field: 'distributor',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.distributor' }),
        width: 180,
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let distributor = cellValues.row.distributor;

          return (
            <>
              <Tooltip title={distributor}>
                <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {distributor}
                </Typography>
              </Tooltip>
            </>
          );
        }
      },
      {
        field: 'isFree',
        headerName: intl.formatMessage({ id: 'app.datagrid.isFree' }),
        width: 120,
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          const isFree = cellValues.row.isFree;
          const id = cellValues.row.entityId?.id;
          let ResolvedIcon;
          let label;

          const handleIconClick = () => {
            handleOpenDevicesAttributesDialog(FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE, id);
          };

          const cursor = isAdminOrSupport ? 'pointer' : 'default';
          if (isFree) {
            ResolvedIcon = (
              <CheckCircleOutlinedIcon
                color="success"
                sx={{
                  '&:hover': {
                    cursor: cursor
                  }
                }}
                onClick={isAdminOrSupport ? handleIconClick : null}
              />
            );
            label = intl.formatMessage({ id: 'app.datagrid.subscription.free' });
          } else {
            ResolvedIcon = (
              <HighlightOffOutlinedIcon
                color="error"
                sx={{
                  '&:hover': {
                    cursor: cursor
                  }
                }}
                onClick={isAdminOrSupport ? handleIconClick : null}
              />
            );
            label = intl.formatMessage({ id: 'app.datagrid.subscription.notFree' });
          }

          return <Tooltip title={label}>{ResolvedIcon}</Tooltip>;
        }
      },
      {
        field: 'prepaidUntil',
        headerName: intl.formatMessage({ id: 'app.datagrid.prepaidYears' }),
        width: 200,
        headerAlign: 'left',
        align: 'left',
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let prepaidYears = cellValues.row.prepaidUntil;
          let id = cellValues.row.entityId?.id;

          const handleClick = () => {
            handleOpenDevicesAttributesDialog(FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS, id);
          };

          const cursor = isAdminOrSupport ? 'pointer' : 'default';

          return (
            <>
              {isDefined(prepaidYears) ? (
                <Typography
                  onClick={isAdminOrSupport ? handleClick : null}
                  sx={{
                    '&:hover': {
                      cursor: cursor
                    }
                  }}
                >
                  {prepaidYears}
                </Typography>
              ) : (
                isAdminOrSupport && (
                  <IconButton onClick={isAdminOrSupport ? handleClick : null} size="small">
                    <EditOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                )
              )}
            </>
          );
        }
      },
      {
        field: 'periodEnd',
        headerName: intl.formatMessage({ id: 'app.datagrid.periodEnd' }),
        width: 200,
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          return <Typography variant="body2">{cellValues.row.periodEnd}</Typography>;
        },
        valueGetter: (params) => params?.row?.periodEndTs
      },
      {
        field: 'latestSample',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.latestSample' }),
        width: 180,
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let value = cellValues.row.latestSample;
          let latestSample = isDefined(value) ? `${value} ago` : intl.formatMessage({ id: 'app.common.not.available' });

          return <Typography variant="body2">{latestSample}</Typography>;
        },
        valueGetter: (params) => params?.row?.latestSampleTs
      },
      {
        field: 'temperature1',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.temperature.sensor1' }),
        width: 150,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          const temperatureValue1 = v?.value?.value;
          return isDefined(temperatureValue1)
            ? `${round(temperatureValue1, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let temperatureValue1 = cellValues.row.temperature1?.value;
          let temperature1 = isDefined(temperatureValue1)
            ? `${round(temperatureValue1, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let temperature1TsValue = cellValues.row.temperature1?.ts;
          let temperature1Ts = isDefined(temperature1TsValue)
            ? DateTime.fromMillis(temperature1TsValue).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip variant="body2" title={temperature1Ts}>
              <Typography>{temperature1}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'temperature2',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.temperature.sensor2' }),
        width: 150,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let temperatureValue2 = v?.value?.value;
          return isDefined(temperatureValue2)
            ? `${round(temperatureValue2, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let temperatureValue2 = cellValues.row.temperature2?.value;
          let temperature2 = isDefined(temperatureValue2)
            ? `${round(temperatureValue2, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let temperature2TsValue = cellValues.row.temperature2?.ts;
          let temperature2Ts = isDefined(temperature2TsValue)
            ? DateTime.fromMillis(temperature2TsValue).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={temperature2Ts}>
              <Typography variant="body2">{temperature2}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'temperatureAmbient',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.temperature.ambient' }),
        width: 150,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let temperatureValueAmbient = v?.value?.value;
          return isDefined(temperatureValueAmbient)
            ? `${round(temperatureValueAmbient, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let temperatureValueAmbient = cellValues.row.temperatureAmbient?.value;
          let temperatureAmbient = isDefined(temperatureValueAmbient)
            ? `${round(temperatureValueAmbient, 2)}°C`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let temperatureAmbientTsValue = cellValues.row.temperatureAmbient?.ts;
          let temperatureAmbientTs = isDefined(temperatureAmbientTsValue)
            ? DateTime.fromMillis(temperatureAmbientTsValue).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={temperatureAmbientTs}>
              <Typography variant="body2">{temperatureAmbient}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'humidity1',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.humidity.sensor1' }),
        width: 150,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let humidityValue1 = v?.value?.value;
          return isDefined(humidityValue1)
            ? `${round(humidityValue1, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let humidityValue1 = cellValues.row.humidity1?.value;
          let humidity1TsValue = cellValues.row.humidity1?.ts;

          let humidity1 = isDefined(humidityValue1)
            ? `${round(humidityValue1, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let humidity1Ts = isDefined(humidity1TsValue)
            ? DateTime.fromMillis(humidity1TsValue).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={humidity1Ts}>
              <Typography variant="body2">{humidity1}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'humidityAmbient',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.humidity.ambient' }),
        width: 150,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let humidityValueAmbient = v?.value?.value;
          return isDefined(humidityValueAmbient)
            ? `${round(humidityValueAmbient, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let humidityValueAmbient = cellValues.row.humidityAmbient?.value;
          let humidityAmbientTsValue = cellValues.row.humidityAmbient?.ts;

          let humidityAmbient = isDefined(humidityValueAmbient)
            ? `${round(humidityValueAmbient, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let humidityAmbientTs = isDefined(humidityAmbientTsValue)
            ? DateTime.fromMillis(humidityAmbientTsValue).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={humidityAmbientTs}>
              <Typography variant="body2">{humidityAmbient}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'cropType',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.emc.cropType' }),
        width: 180,
        valueFormatter: (v: any): React$Node => {
          let cropType = v?.value;

          return isDefined(cropType) ? cropType : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let cropType = cellValues.row.cropType;

          let cropTypeLabel = isDefined(cropType) ? cropType : intl.formatMessage({ id: 'app.common.not.available' });

          return <Typography variant="body2">{cropTypeLabel}</Typography>;
        }
      },
      {
        field: 'cropSensor1',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.emc.sensor1' }),
        width: 180,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let cropSensor1 = v?.value;

          let cropHumidity1;
          if (isDefined(cropSensor1)) {
            cropHumidity1 = cropSensor1.value;
          }

          return isDefined(cropHumidity1)
            ? `${round(cropHumidity1, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let cropSensor1 = cellValues.row.cropSensor1;

          let cropHumidity1;
          let cropHumidity1Ts;
          if (isDefined(cropSensor1)) {
            cropHumidity1 = cropSensor1.value;
            cropHumidity1Ts = cropSensor1.ts;
          }

          let humidity1Label = isDefined(cropHumidity1)
            ? `${round(cropHumidity1, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });
          let humidity1Ts = isDefined(cropHumidity1Ts)
            ? DateTime.fromMillis(cropHumidity1Ts).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={humidity1Ts}>
              <Typography variant="body2">{humidity1Label}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'cropAmbient',
        headerName: intl.formatMessage({ id: 'app.datagrid.devices.emc.ambient' }),
        width: 180,
        sortComparator: (o1, o2) => customNumberComparator(o1?.value, o2?.value),
        valueFormatter: (v: any): React$Node => {
          let cropHumidity = v?.value;

          let cropHumidityAmbient;
          if (isDefined(cropHumidity)) {
            cropHumidityAmbient = cropHumidity?.value;
          }

          return isDefined(cropHumidityAmbient)
            ? `${round(cropHumidityAmbient, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });
        },
        renderCell: (cellValues: GridRenderCellParams): React$Node => {
          let cropHumidity = cellValues.row.cropAmbient;

          let cropHumidityAmbient;
          let cropHumidityAmbientTs;
          if (isDefined(cropHumidity)) {
            cropHumidityAmbient = cropHumidity?.value;
            cropHumidityAmbientTs = cropHumidity?.ts;
          }

          let humidityAmbientLabel = isDefined(cropHumidityAmbient)
            ? `${round(cropHumidityAmbient, 2)}%`
            : intl.formatMessage({ id: 'app.common.not.available' });

          let humidityAmbientTs = isDefined(cropHumidityAmbientTs)
            ? DateTime.fromMillis(cropHumidityAmbientTs).toLocaleString(DateTime.DATETIME_MED)
            : intl.formatMessage({ id: 'app.common.not.available' });

          return (
            <Tooltip title={humidityAmbientTs}>
              <Typography variant="body2">{humidityAmbientLabel}</Typography>
            </Tooltip>
          );
        }
      }
    );
  }

  return columns;
};

const resolveDeviceDataGridData = (
  items: Array<TbAssetTelemetriesExtendedItem>,
  odooSalesOrders: Array<OdooSalesOrder>
): any => {
  const devicesDataGrid = [];

  items?.map((item: TbAssetTelemetriesExtendedItem, index: number) => {
    const assetDto = item.assetDto;
    const telemetryData = item.telemetryData;
    const attributes = item.attributes;

    let claimedTs = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.CLAIM_TS
    );

    let orderNumber = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER
    );

    let matchedSalesOrder;
    let distributor;
    let orderId;
    if (isNotEmpty(orderNumber)) {
      matchedSalesOrder = odooSalesOrders?.find((salesOrder) => salesOrder.name === orderNumber.value);
      distributor = matchedSalesOrder?.partnerName;
      orderId = matchedSalesOrder?.id;
    }

    const { latestSample, latestSampleTs } = resolveLatestSample(telemetryData);
    const cropHumidity = resolveCropTypeHumidityValues(telemetryData);

    let dataGridItem = {
      id: index + 1,
      entityId: assetDto.id,
      customerName: item.customerName,
      name: assetDto.name,
      type: assetDto.type,
      claimedTs: claimedTs?.value,
      latitude: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.LATITUDE]?.[0]?.value ?? null,
      longitude: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.LONGITUDE]?.[0]?.value ?? null,
      simLatitude: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.SIM_LATITUDE]?.[0]?.value ?? null,
      simLongitude: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.SIM_LONGITUDE]?.[0]?.value ?? null,
      temperature1: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_1]?.[0],
      temperature2: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_2]?.[0],
      temperatureAmbient: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_AMBIENT]?.[0],
      humidity1: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.HUMIDITY_1]?.[0],
      humidityAmbient: telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.HUMIDITY_AMBIENT]?.[0],
      latestSample: latestSample,
      latestSampleTs: latestSampleTs,
      cropType: cropHumidity?.cropType,
      cropSensor1: cropHumidity?.humidity1Crop,
      cropAmbient: cropHumidity?.humidityAmbientCrop,
      orderNumber: orderNumber?.value,
      distributor: distributor,
      orderId: orderId
    };

    const { claimedDateTime, periodEnd, prepaidUntil, isFree, periodEndTimestamp } = resolveSubscription(attributes);
    dataGridItem = {
      ...dataGridItem,
      claimedDateTime: claimedDateTime,
      periodEnd: periodEnd,
      periodEndTs: periodEndTimestamp,
      prepaidUntil: prepaidUntil,
      isFree: isFree
    };

    devicesDataGrid.push(dataGridItem);
  });

  return devicesDataGrid;
};

const resolveLatestSample = (telemetryData: Array<TelemetryData>): any => {
  let temperature1Ts = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_1]?.[0].ts ?? 0;
  let temperature2Ts = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_2]?.[0].ts ?? 0;
  let temperatureAmbientTs = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.TEMPERATURE_AMBIENT]?.[0].ts ?? 0;
  let humidity1Ts = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.HUMIDITY_1]?.[0].ts ?? 0;
  let humidityAmbientTs = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.HUMIDITY_AMBIENT]?.[0].ts ?? 0;

  let latestSampleTs = max([temperature1Ts, temperature2Ts, temperatureAmbientTs, humidity1Ts, humidityAmbientTs]);
  let relativeTime;
  if (isDefined(latestSampleTs) && latestSampleTs > 0) {
    relativeTime = getDetailedRelativeTime(latestSampleTs);
  }

  return { latestSample: relativeTime, latestSampleTs: latestSampleTs };
};

const resolveCropTypeHumidityValues = (telemetryData: Array<TelemetryData>): any => {
  let cropType = telemetryData[FARMER_DEVICE_TELEMETRY_ENUM.CROP_TYPE]?.[0]?.value;

  let humidity1;
  let humidityAmbient;
  if (isDefined(cropType)) {
    const humidity1Key = resolveCropTypeKey(cropType, SENSOR_TYPES_ENUM.SENSOR_1);
    humidity1 = telemetryData[humidity1Key]?.[0];

    const humidityAmbientKey = resolveCropTypeKey(cropType, SENSOR_TYPES_ENUM.SENSOR_AMBIENT);
    humidityAmbient = telemetryData[humidityAmbientKey]?.[0];
  }

  return {
    cropType: cropType,
    humidity1Crop: humidity1,
    humidityAmbientCrop: humidityAmbient
  };
};

const resolveSubscription = (attributes: Array<TelemetryAttribute>): any => {
  let isFreeAttribute = attributes.find(
    (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.IS_FREE
  );
  let claimAttribute = attributes.find(
    (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.CLAIM_TS
  );
  let prepaidAttribute = attributes.find(
    (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS
  );
  let periodEndAttribute = attributes.find(
    (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.PERIOD_END
  );

  let claimedDateTimeFormatted;
  let prepaidYears;
  let periodEndFormatted;
  let isFree = isFreeAttribute?.value ?? false;

  if (isDefined(claimAttribute)) {
    claimedDateTimeFormatted =
      claimAttribute?.value > 0
        ? DateTime.fromSeconds(claimAttribute.value).toLocaleString(DateTime.DATETIME_MED)
        : claimAttribute?.value;
  }

  let periodEndTimestamp;
  if (isDefined(periodEndAttribute)) {
    periodEndTimestamp = periodEndAttribute?.value;
    if (periodEndTimestamp > 0) {
      let periodEnd = DateTime.fromSeconds(periodEndTimestamp);
      periodEndFormatted = periodEnd?.toLocaleString(DateTime.DATETIME_MED);
    }
  }

  if (isDefined(prepaidAttribute)) {
    prepaidYears = prepaidAttribute?.value;
  }

  return {
    claimedDateTime: claimedDateTimeFormatted,
    prepaidUntil: prepaidYears,
    periodEnd: periodEndFormatted,
    periodEndTimestamp: periodEndTimestamp,
    isFree: isFree
  };
};

const resolveStatisticsComponent = (items: Array<TbAssetTelemetriesItem>, selectedTab: string): any => {
  const totalSpears = items?.filter(
    (item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR
  ).length;
  const totalSuperPro = items?.filter(
    (item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_PRO
  ).length;
  const totalSuperPoint = items?.filter(
    (item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_POINT
  ).length;
  const totalSuperCombi = items?.filter(
    (item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_COMBI
  ).length;
  const totalCPro = items?.filter(
    (item) =>
      item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V1 ||
      item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V2
  ).length;
  const totalFarmPro = items?.filter((item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_PRO).length;
  const totalFarmPoint = items?.filter(
    (item) => item.assetDto.type === TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_POINT
  ).length;

  let numberOfClaimedDevices = 0;
  let numberOf3YearPrepaidDevices = 0;
  let numberOf1YearPrepaidDevices = 0;
  let numberOf3YearSubscribedDevices = 0;
  let numberOf2YearSubscribedDevices = 0;
  let numberOf1YearSubscribedDevices = 0;
  items?.forEach((item: TbAssetTelemetriesItem): React$Node => {
    let attributes = item.attributes;

    let claimedAtribute = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.CLAIM_TS
    );
    let periodEndAttribute = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.PERIOD_END
    );

    if (isDefined(claimedAtribute)) {
      let claimedTs = claimedAtribute.value;
      if (claimedTs > 0) {
        numberOfClaimedDevices++;

        if (isDefined(periodEndAttribute)) {
          let periodEndTs = periodEndAttribute.value;
          if (periodEndTs > 0) {
            const claimedDateTime = DateTime.fromSeconds(claimedTs);
            const periodEndDateTime = DateTime.fromSeconds(periodEndTs);
            let durationInYears = periodEndDateTime.diff(claimedDateTime, 'years').toObject().years;
            const subscriptionTime = Math.round(durationInYears);

            if (subscriptionTime === 3) {
              numberOf3YearSubscribedDevices++;
            } else if (subscriptionTime === 2) {
              numberOf2YearSubscribedDevices++;
            } else if (subscriptionTime === 1) {
              numberOf1YearSubscribedDevices++;
            }
          }
        }
      }
    }

    let prepaidAttribute = attributes.find(
      (attribute: TelemetryAttribute) => attribute.key === FARMER_DEVICE_ATTRIBUTE_ENUM.PRE_PAID_YEARS
    );
    if (isDefined(prepaidAttribute)) {
      let prepaidYears = prepaidAttribute.value;
      if (prepaidYears === 3) {
        numberOf3YearPrepaidDevices++;
      } else if (prepaidYears === 1) {
        numberOf1YearPrepaidDevices++;
      }
    }
  });

  let Statistics;
  if (!isDefined(selectedTab)) {
    Statistics = (
      <Stack direction="row" spacing={1}>
        {totalSpears > 0 && <StatisticsItem titleKey="app.general.overview.stat.spears" value={totalSpears} />}
        {totalSuperPro > 0 && <StatisticsItem titleKey="app.device.farmerApp.superPro" value={totalSuperPro} />}
        {totalSuperPoint > 0 && <StatisticsItem titleKey="app.device.farmerApp.superPoint" value={totalSuperPoint} />}
        {totalSuperCombi > 0 && <StatisticsItem titleKey="app.device.farmerApp.superCombi" value={totalSuperCombi} />}
        {totalCPro > 0 && <StatisticsItem titleKey="app.device.farmerApp.cPro" value={totalCPro} />}
        {totalFarmPro > 0 && <StatisticsItem titleKey="app.device.farmerApp.farmPro" value={totalFarmPro} />}
        {totalFarmPoint > 0 && <StatisticsItem titleKey="app.device.farmerApp.farmPoint" value={totalFarmPoint} />}
        {numberOfClaimedDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.numberClaimed" value={numberOfClaimedDevices} />
        )}
        {numberOf3YearPrepaidDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.3year.prepaid" value={numberOf3YearPrepaidDevices} />
        )}
        {numberOf1YearPrepaidDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.1year.prepaid" value={numberOf1YearPrepaidDevices} />
        )}
        {numberOf3YearSubscribedDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.3year.subscription" value={numberOf3YearSubscribedDevices} />
        )}
        {numberOf2YearSubscribedDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.2year.subscription" value={numberOf2YearSubscribedDevices} />
        )}
        {numberOf1YearSubscribedDevices > 0 && (
          <StatisticsItem titleKey="app.datagrid.devices.1year.subscription" value={numberOf1YearSubscribedDevices} />
        )}
      </Stack>
    );
  } else {
    if (selectedTab === FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR) {
      Statistics = (
        <Stack direction="row" spacing={1}>
          <StatisticsItem titleKey="app.general.overview.stat.spears" value={totalSpears} />
          <StatisticsItem titleKey="app.datagrid.devices.numberClaimed" value={numberOfClaimedDevices} />
          <StatisticsItem titleKey="app.datagrid.devices.3year.prepaid" value={numberOf3YearPrepaidDevices} />
          <StatisticsItem titleKey="app.datagrid.devices.1year.prepaid" value={numberOf1YearPrepaidDevices} />
          <StatisticsItem titleKey="app.datagrid.devices.3year.subscription" value={numberOf3YearSubscribedDevices} />
          <StatisticsItem titleKey="app.datagrid.devices.2year.subscription" value={numberOf2YearSubscribedDevices} />
          <StatisticsItem titleKey="app.datagrid.devices.1year.subscription" value={numberOf1YearSubscribedDevices} />
        </Stack>
      );
    } else {
      Statistics = (
        <Stack direction="row" spacing={1}>
          <StatisticsItem titleKey="app.device.farmerApp.superPro" value={totalSuperPro} />
          <StatisticsItem titleKey="app.device.farmerApp.superPoint" value={totalSuperPoint} />
          <StatisticsItem titleKey="app.device.farmerApp.superCombi" value={totalSuperCombi} />
          <StatisticsItem titleKey="app.device.farmerApp.cPro" value={totalCPro} />
          <StatisticsItem titleKey="app.device.farmerApp.farmPro" value={totalFarmPro} />
          <StatisticsItem titleKey="app.device.farmerApp.farmPoint" value={totalFarmPoint} />
        </Stack>
      );
    }
  }

  return Statistics;
};

type Props = {
  items: Array<TbAssetTelemetriesExtendedItem>,
  tabSelected: string,
  fetchDevices: Function
};

const DevicesDataGrid = ({ items, tabSelected, fetchDevices }: Props): React$Node => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);
  const odooUrl = useSelector(getOdooUrlSetting);

  const [searchText, setSearchText] = useState('');
  const [dataGridItems, setDataGridItems] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(
    getDataGridColumnVisibility(DEVICES_DATAGRID_COLUMN_VISIBILITY_V3)
  );
  const [visibleColumnsDrawerOpen, setVisibleColumnsDrawerOpen] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [devicesLocation, setDevicesLocation] = useState([]);
  const [openDevicesWorldMapDialog, setOpenDevicesWorldMapDialog] = useState(false);
  const [attributeToUpdate, setAttributeToUpdate] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceAssignmentDialogOpen, setDeviceAssignmentDialogOpen] = useState(false);

  const [odooSalesOrders, setOdooSalesOrders] = useState([]);

  useEffect(() => {
    getSalesOrders(dispatch).then((response) => setOdooSalesOrders(response));
  }, []);

  useEffect(() => {
    const filteredItems = items?.filter(
      (item: TbAssetTelemetriesExtendedItem) =>
        item.assetDto.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.assetDto.type.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        item.customerName?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setDataGridItems(filteredItems);
  }, [items, searchText]);

  const handleCloseDialog = () => {
    setDevicesLocation([]);
    setOpenDevicesWorldMapDialog(false);
  };

  const closeVisibleColumnsDrawer = () => {
    setVisibleColumnsDrawerOpen(false);
  };

  const handleCloseDevicesAttributesDialog = () => {
    setSelectedDevice(null);
    setAttributeToUpdate(null);
  };

  const handleOpenDevicesAttributesDialog = (attribute: string, deviceId: string) => {
    const device = items?.find((item) => item.assetDto.id.id === deviceId);
    setSelectedDevice(device);
    setAttributeToUpdate(attribute);
  };

  const handleCloseDeviceAssignmentClose = () => {
    setSelectedDevice(null);
    setDeviceAssignmentDialogOpen(false);
  };

  const handleDeviceAssignmentDialogOpen = (deviceId: string) => {
    const device = items?.find((item) => item.assetDto.id.id === deviceId);
    setSelectedDevice(device);
    setDeviceAssignmentDialogOpen(true);
  };

  const handleSearchChange = (event: Event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  useEffect(() => {
    setColumnVisibilityModel(resolveColumnVisibilityModel(visibleColumns));
    storeDataGridColumnVisibility(DEVICES_DATAGRID_COLUMN_VISIBILITY_V3, visibleColumns);
  }, [visibleColumns]);

  const rows = resolveDeviceDataGridData(dataGridItems, odooSalesOrders);
  let Statistics = isNotEmptyList(dataGridItems) ? resolveStatisticsComponent(dataGridItems, tabSelected) : null;
  let columns = createColumns(
    intl,
    handleOpenDevicesAttributesDialog,
    handleDeviceAssignmentDialogOpen,
    isAdminOrSupport,
    tabSelected,
    odooUrl
  );

  const setDevicesLocationItems = () => {
    const devicesLocationItems = [];
    rows?.forEach((item: any) => {
      const longitude = item.longitude;
      const latitude = item.latitude;
      const simLongitude = item.simLongitude;
      const simLatitude = item.simLatitude;
      const name = item.name;
      const claimedTs = item.claimedTs;

      if (claimedTs > 0) {
        let newLocationItem = {
          name: name,
          type: WORLD_MAP_ELEMENTS.SENSOR_SPEAR
        };
        if (isDefined(longitude) && isDefined(latitude)) {
          newLocationItem = {
            ...newLocationItem,
            longitude: longitude,
            latitude: latitude
          };
        } else if (isDefined(simLongitude) && isDefined(simLatitude)) {
          newLocationItem = {
            ...newLocationItem,
            longitude: simLongitude,
            latitude: simLatitude
          };
        }
        devicesLocationItems.push(newLocationItem);
      }
    });
    setDevicesLocation(devicesLocationItems);
    setOpenDevicesWorldMapDialog(true);
  };

  let showAttributeUpdateDialog = isAdminOrSupport && isDefined(attributeToUpdate) && isDefined(selectedDevice);
  return (
    <Box>
      <Box sx={{ height: '70vh', width: '100%' }}>
        {openDevicesWorldMapDialog && (
          <WorldMapDialog
            open={openDevicesWorldMapDialog}
            handleClose={handleCloseDialog}
            locationItems={devicesLocation}
          />
        )}
        {showAttributeUpdateDialog && (
          <DevicesAttributesDialog
            open
            handleClose={handleCloseDevicesAttributesDialog}
            device={selectedDevice}
            attributeToUpdate={attributeToUpdate}
            fetchDevices={fetchDevices}
            tabSelected={tabSelected}
          />
        )}
        {deviceAssignmentDialogOpen && (
          <DeviceAssignmentDialog
            open
            handleClose={handleCloseDeviceAssignmentClose}
            device={selectedDevice}
            fetchDevices={fetchDevices}
            tabSelected={tabSelected}
          />
        )}
        <DataGridSettingsDrawer
          dataGridType={DATA_GRID_TYPE.DEVICES_DATA_GRID}
          open={visibleColumnsDrawerOpen}
          closeDrawer={closeVisibleColumnsDrawer}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              label={intl.formatMessage({ id: 'app.common.search' })}
              type={'text'}
              sx={{
                width: theme.spacing(29)
              }}
              InputProps={{
                style: {
                  borderRadius: '8px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon color="primary" />
                  </InputAdornment>
                ),
                endAdornment: isNotEmpty(searchText) ? (
                  <InputAdornment position="end">
                    <IconButton aria-label="search bar" edge="end" onClick={handleClearSearch}>
                      <CloseOutlinedIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
              value={searchText}
              onChange={handleSearchChange}
            />
            {Statistics}
          </Box>
          <Box>
            <Tooltip title={intl.formatMessage({ id: 'app.datagrid.location.devices.tooltip' })}>
              <IconButton onClick={setDevicesLocationItems}>
                <LocationOnOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'app.datagrid.drawer.visible.columns' })}>
              <IconButton onClick={() => setVisibleColumnsDrawerOpen(true)}>
                <SettingsOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <DataGrid
          rows={rows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          slots={{
            toolbar: GridToolbar
          }}
          density={'compact'}
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default DevicesDataGrid;
