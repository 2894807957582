const withBasePath = (path) => `/client/${path}`;

export const LOGIN = withBasePath('login');
export const RESET_PASSWORD = withBasePath('reset-password/:token');
export const REQUEST_RESET_PASSWORD = withBasePath('request-reset-password');
export const REGISTER_USER = withBasePath('register');
export const COMPLETE_REGISTRATION = withBasePath('complete-registration/:token');
export const PUBLIC_WORLD_MAP = withBasePath('2bea5076-ace2-499e-9b20-53a182ff4e60');

export const GENERAL = withBasePath('general');
export const GENERAL_OVERVIEW = withBasePath('general/overview');
export const GENERAL_NODES_MAP = withBasePath('general/nodes-map');
export const GENERAL_NODES_MAP_MANAGER = withBasePath('general/nodes-map/manager');
export const GENERAL_NODES_MAP_FARMER = withBasePath('general/nodes-map/farmer');

export const SERVICE_OVERVIEW = withBasePath('service');
export const SERVICE_STATUS_APP = withBasePath('service/status-app');
export const SERVICE_STATUS_MANAGER = withBasePath('service/status-manager');

export const MANAGER = withBasePath('manager');
export const MANAGER_TENANTS = withBasePath('manager/tenants');
export const MANAGER_GATEWAYS = withBasePath('manager/gateways');
export const MANAGER_SUBSCRIPTIONS = withBasePath('manager/subscriptions');

export const FARMER_APP = withBasePath('farmer');
export const FARMER_APP_CUSTOMERS = withBasePath('farmer/customers');
export const FARMER_APP_DEVICES = withBasePath('farmer/devices');

export const ANALYTICS = withBasePath('analytics');

export const ADMINISTRATION = withBasePath('administration');
export const ADMINISTRATION_USERS = withBasePath('administration/users');
export const ADMINISTRATION_SERVICES = withBasePath('administration/services');
