// @flow

export const copyToClipboard = (textToCopy: string) => {
  const clipBoard = navigator.clipboard;
  clipBoard.writeText(textToCopy).then(() => {
    console.debug('Copied to clipboard');
  });
};

export const AUTHENTICATION_ACTION = {
  ALLOW_AUTH: 'ALLOW_AUTH',
  ALLOW_AUTH_WITH_SMS: 'ALLOW_AUTH_WITH_SMS'
};
