//@flow
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

type Props = {
  titleKey: string
};
const PageTitle = ({ titleKey }: Props): React$Node => {
  const intl = useIntl();
  return (
    <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
      {intl.formatMessage({ id: titleKey })}
    </Typography>
  );
};

export default PageTitle;
