// @flow
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import DoneIcon from '@mui/icons-material/Done';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';

import { fetchUserDetails, logout } from 'api/service/UserApiService';
import { getLoggedUser } from 'store/selector/UserSelector';
import { isNotEmpty } from 'util/ObjectUtils';
import { getSelectedLocale } from 'store/selector/ApplicationSelector';
import { setLocale } from 'store/slice/ApplicationSlice';
import { SUPPORTED_LOCALES } from 'i18n/LocaleUtils';
import UserProfileDialog from 'components/user/UserProfileDialog';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: '0'
    }
  })
);

export default function TopNavigationMenu(): React$Node {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userProfile = useSelector(getLoggedUser);
  const selectedLocale = useSelector(getSelectedLocale);

  const [userProfileDialogOpen, setUserProfileDialogOpen] = React.useState(false);
  const [userDropdownElement, setUserDropdownElement] = React.useState(null);
  const [languageDropdownElement, setLanguageDropdownElement] = React.useState(null);
  const userMenuOpen = Boolean(userDropdownElement);
  const languageMenuOpen = Boolean(languageDropdownElement);

  const handleUserClick = (event: Event) => {
    setUserDropdownElement(event.currentTarget);
  };
  const handleUserDropdownClose = () => {
    setUserDropdownElement(null);
  };

  const handleLanguageClick = (event: Event) => {
    setLanguageDropdownElement(event.currentTarget);
  };
  const handleLanguageDropdownClose = () => {
    setLanguageDropdownElement(null);
  };

  const userFnInitials = userProfile.firstName?.substring(0, 1) ?? 'U';
  const userLnInitials = userProfile.lastName?.substring(0, 1) ?? '';
  const fullName = `${userProfile.firstName} ${userProfile.lastName}`;
  let userInitials = 'N/A';
  if (isNotEmpty(userFnInitials) || isNotEmpty(userLnInitials)) {
    userInitials = `${userFnInitials}${userLnInitials}`;
  }

  const dropdownMenuStyles = useStyles();
  const handleCloseUserProfile = () => setUserProfileDialogOpen(false);
  const handleOpenUserProfile = () => setUserProfileDialogOpen(true);

  return (
    <React.Fragment>
      <UserProfileDialog
        userProfile={userProfile}
        open={userProfileDialogOpen}
        handleClose={handleCloseUserProfile}
        successCallback={() => fetchUserDetails(dispatch)}
      />
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={intl.formatMessage({ id: 'app.language.chooser' })}>
          <IconButton
            onClick={handleLanguageClick}
            size="small"
            aria-controls={languageMenuOpen ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={languageMenuOpen ? 'true' : undefined}
            color="primary"
          >
            <LanguageIcon sx={{ width: 36, height: 36, color: 'primary.light' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'app.user.settings' })}>
          <IconButton
            onClick={handleUserClick}
            size="small"
            sx={{ ml: 1 }}
            aria-controls={userMenuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={userMenuOpen ? 'true' : undefined}
          >
            <Avatar sx={{ width: 36, height: 36, bgcolor: 'primary.light' }}>
              <Typography variant="body2" sx={{ color: 'white', fontWeight: 500, textTransform: 'uppercase' }}>
                {userInitials}
              </Typography>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={languageDropdownElement}
        id="language-menu"
        open={languageMenuOpen}
        onClose={handleLanguageDropdownClose}
        onClick={handleLanguageDropdownClose}
        classes={{ list: dropdownMenuStyles.list }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ p: 2 }}
          selected={selectedLocale === SUPPORTED_LOCALES.EN.locale}
          onClick={() => dispatch(setLocale(SUPPORTED_LOCALES.EN.locale))}
        >
          {intl.formatMessage({ id: 'app.language.english' })}
          {selectedLocale === SUPPORTED_LOCALES.EN.locale && <DoneIcon sx={{ ml: 2 }} />}
        </MenuItem>
        <MenuItem
          sx={{ p: 2 }}
          selected={selectedLocale === SUPPORTED_LOCALES.DA.locale}
          onClick={() => dispatch(setLocale(SUPPORTED_LOCALES.DA.locale))}
        >
          {intl.formatMessage({ id: 'app.language.danish' })}
          {selectedLocale === SUPPORTED_LOCALES.DA.locale && <DoneIcon sx={{ ml: 2 }} />}
        </MenuItem>
        <MenuItem
          sx={{ p: 2 }}
          selected={selectedLocale === SUPPORTED_LOCALES.SV.locale}
          onClick={() => dispatch(setLocale(SUPPORTED_LOCALES.SV.locale))}
        >
          {intl.formatMessage({ id: 'app.language.swedish' })}
          {selectedLocale === SUPPORTED_LOCALES.SV.locale && <DoneIcon sx={{ ml: 2 }} />}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={userDropdownElement}
        id="account-menu"
        open={userMenuOpen}
        onClose={handleUserDropdownClose}
        onClick={handleUserDropdownClose}
        classes={{ list: dropdownMenuStyles.list }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleOpenUserProfile} sx={{ height: 50 }}>
          <Avatar sx={{ bgcolor: 'primary.light' }} />
          <Stack direction="column" spacing={1}>
            {intl.formatMessage({ id: 'app.user.profile' })}
            <Typography variant="body2" sx={{ fontSize: 'small', fontWeight: 300, textTransform: 'capitalize' }}>
              {fullName}
            </Typography>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => logout(dispatch)} sx={{ height: 50 }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {intl.formatMessage({ id: 'app.user.logout' })}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
