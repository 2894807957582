// @flow

import { TbLocationItem } from 'types/Thingsboard.types';
import { isDefined } from 'util/ObjectUtils';
import WorldMap from 'components/worldmap/WorldMap';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import type { WorldMapLocationItem } from 'types/Global.types';

type Props = {
  items: Array<WorldMapLocationItem>,
  searchedItem?: TbLocationItem,
  view: string,
  useStoredPosition?: boolean
};

const WorldMapWrapper = ({ items, searchedItem, view, useStoredPosition }: Props): React$Node => {
  const sitesOnPremiseGeoJson = { features: [], type: 'FeatureCollection' };
  const sitesCloudGeoJson = { features: [], type: 'FeatureCollection' };
  const spearsGeoJson = { features: [], type: 'FeatureCollection' };
  const buildingsGeoJson = { features: [], type: 'FeatureCollection' };
  const usersGeoJson = { features: [], type: 'FeatureCollection' };

  items
    .filter((item: TbLocationItem) => isDefined(item.latitude) && isDefined(item.longitude))
    .forEach((item: TbLocationItem) => {
      const locationItem = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude]
        },
        properties: {
          name: item.name
        }
      };

      if (item.type === WORLD_MAP_ELEMENTS.SITE) {
        if (item.onPremise) {
          sitesOnPremiseGeoJson.features.push(locationItem);
        } else {
          sitesCloudGeoJson.features.push(locationItem);
        }
      } else if (item.type === WORLD_MAP_ELEMENTS.SENSOR_SPEAR) {
        spearsGeoJson.features.push(locationItem);
      } else if (item.type === WORLD_MAP_ELEMENTS.BUILDING) {
        buildingsGeoJson.features.push(locationItem);
      } else if (item.type === WORLD_MAP_ELEMENTS.USER) {
        usersGeoJson.features.push(locationItem);
      }
    });

  const locationGeoJsonMap = {
    [WORLD_MAP_ELEMENTS.SITE]: sitesCloudGeoJson,
    [WORLD_MAP_ELEMENTS.SITE_ON_PREMISE]: sitesOnPremiseGeoJson,
    [WORLD_MAP_ELEMENTS.SENSOR_SPEAR]: spearsGeoJson,
    [WORLD_MAP_ELEMENTS.BUILDING]: buildingsGeoJson,
    [WORLD_MAP_ELEMENTS.USER]: usersGeoJson
  };

  return (
    <WorldMap
      locationGeoJsonMap={locationGeoJsonMap}
      searchedItem={searchedItem}
      view={view}
      useStoredPosition={useStoredPosition}
    />
  );
};

export default WorldMapWrapper;
