// @flow
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = (props): React$Node => (
  <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }} {...props}>
    {'Copyrights © '}
    <Link color="inherit" href="https://www.agrolog.io/">
      AgroLog
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

export default Copyright;
