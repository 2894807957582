// @flow
import { Dispatch } from '@reduxjs/toolkit';

import { handleError } from 'api/RestApiHelper';
import { USER_2FA_TOTP_URL_API, USER_DETAILS_API, USER_LOGOUT_API } from 'constants/RestApiConstants';
import { get, post, put } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { removeLoggedUser, setLoggedUser } from 'store/slice/UserSlice';

export async function logout(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(USER_LOGOUT_API)
    .then(() => dispatch(removeLoggedUser()))
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function fetchUserDetails(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(USER_DETAILS_API)
    .then((response) => dispatch(setLoggedUser(response)))
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getGoogleAuthTOTPUrl(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(USER_2FA_TOTP_URL_API)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function saveUserDetails(body: any, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return put(USER_DETAILS_API, body)
    .then((response) => dispatch(setLoggedUser(response)))
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
