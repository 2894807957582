// @flow

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import DevicesDataGrid from '../../components/datagrid/farmer/DevicesDataGrid';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../common/PageContainer';
import FarmerAppDevicesTabNavigation from 'pages/farmerapp/FarmerAppDevicesTabNavigation';
import { getTbFarmerDevicesByTypeWithTelemetry } from 'api/service/ThingsboardApiService';
import { TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/ThingsboardConstants';
import { FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/DeviceConstants';
import type { TbFarmerDeviceType } from 'types/Thingsboard.types';

const FarmerAppDevices = (): React$Node => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [tabSelected, setTabSelected] = useState(null);

  const fetchDevices = (devicesToFetch: Array<TbFarmerDeviceType>) => {
    getTbFarmerDevicesByTypeWithTelemetry(dispatch, devicesToFetch, null).then((response) => {
      setItems(response);

      if (devicesToFetch.includes(TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR)) {
        setTabSelected(FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR);
      } else {
        setTabSelected(FARMER_DEVICE_TAB_NAVIGATION_ENUM.GRAIN_ANALYZERS);
      }
    });
  };

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.farmerApp.devices'} />
      <FarmerAppDevicesTabNavigation fetchDevices={fetchDevices} />
      <DevicesDataGrid items={items} tabSelected={tabSelected} fetchDevices={fetchDevices} />
    </PageContainer>
  );
};

export default FarmerAppDevices;
