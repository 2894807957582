export const USER_ROLE_ENUM = {
  ADMIN: 'ROLE_ADMIN',
  SUPPORT: 'ROLE_SUPPORT',
  USER: 'ROLE_USER'
};

export const VALIDATION_TOKEN_ENUM = {
  PASSWORD_RESET: 'PASSWORD_RESET',
  REGISTER_USER: 'REGISTER_USER',
  INVITE_USERS: 'INVITE_USERS'
};

export const USER_ATTRIBUTE_ENUM = {
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude'
};
