// @flow
import { Dispatch } from '@reduxjs/toolkit';

import { getRequestTimeoutPromise, handleError, isJsonResponse } from 'api/RestApiHelper';
import {
  ACTIVATE_USER,
  APPLICATION_SETTINGS,
  INVITE_USERS,
  LOGIN_API,
  PASSWORD_RESET_API,
  REGISTER_USER,
  REQUEST_PASSWORD_RESET_API,
  VALIDATE_CREDENTIALS,
  VALIDATE_EMAIL,
  VERIFY_TOKEN_API
} from 'constants/RestApiConstants';
import { get, post, put } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import type { UserProfile } from 'types/State.types';
import { isNotEmpty } from '../../util/ObjectUtils';
import { store } from '../../store';
import { getSelectedLocale } from '../../store/selector/ApplicationSelector';

export const validateCredentials = (username: string, password: string, dispatch: Dispatch): Promise => {
  dispatch(startLoading());
  return post(VALIDATE_CREDENTIALS, { username, password })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
};

export async function login(data: any = {}, authCode?: string, dispatch: Dispatch): Promise {
  let locale = getSelectedLocale(store.getState());

  let defaultHeaders = {
    'Accept-Language': locale,
    'Content-Type': 'application/json'
  };

  if (isNotEmpty(authCode)) {
    defaultHeaders['X-Authorization-Code'] = authCode;
  }

  dispatch(startLoading());

  return Promise.race([
    fetch(LOGIN_API, {
      method: 'POST',
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: defaultHeaders,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    }).then((response: any): any => {
      if (response.status === 401 || response.status === 403) {
        throw Error('Authentication failed! Please try again.');
      } else if (response.status === 423) {
        throw Error('Your account has been locked! It will be unlocked after 5 minutes.');
      }
      if (isJsonResponse(response)) {
        return response.json();
      }
      return response;
    }),
    getRequestTimeoutPromise()
  ]).finally(() => dispatch(stopLoading()));
}

export async function requestPasswordReset(body: any = {}, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(REQUEST_PASSWORD_RESET_API, body)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function resetPassword(body: any = {}, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return put(PASSWORD_RESET_API, body)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function verifyToken(token: string, tokenType: string, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(VERIFY_TOKEN_API, { token, tokenType })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function registerUser(user: UserProfile, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(REGISTER_USER, user)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function activateUser(token: string, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return put(ACTIVATE_USER, { token })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function inviteUsers(emails: Array<string>, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(INVITE_USERS, { emails })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function fetchApplicationSettings(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(APPLICATION_SETTINGS)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function validateUserEmail(email: string, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(VALIDATE_EMAIL, { email })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
