// @flow

import { useEffect, useState } from 'react';
import { getTbFarmerCustomersData } from 'api/service/ThingsboardApiService';
import { useDispatch } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../common/PageContainer';
import CustomersDataGrid from 'components/datagrid/farmer/CustomersDataGrid';

const FarmerAppCustomers = (): React$Node => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = () => {
    getTbFarmerCustomersData(dispatch).then(setItems);
  };

  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.farmerApp.customers'} />
      <CustomersDataGrid items={items} fetchCustomers={fetchCustomers} />
    </PageContainer>
  );
};

export default FarmerAppCustomers;
