// @flow
import { Route } from 'react-router-dom';
import { Routes } from 'react-router';

import GeneralOverview from 'pages/general/GeneralOverview';
import PrivateRoute from 'routing/PrivateRoute';
import {
  ADMINISTRATION_SERVICES,
  ADMINISTRATION_USERS,
  ANALYTICS,
  COMPLETE_REGISTRATION,
  FARMER_APP,
  FARMER_APP_CUSTOMERS,
  FARMER_APP_DEVICES,
  GENERAL,
  GENERAL_NODES_MAP,
  GENERAL_NODES_MAP_FARMER,
  GENERAL_NODES_MAP_MANAGER,
  GENERAL_OVERVIEW,
  LOGIN,
  MANAGER,
  MANAGER_GATEWAYS,
  MANAGER_SUBSCRIPTIONS,
  MANAGER_TENANTS,
  PUBLIC_WORLD_MAP,
  REGISTER_USER,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SERVICE_OVERVIEW,
  SERVICE_STATUS_APP,
  SERVICE_STATUS_MANAGER
} from 'routing/RouteConstants';
import PublicRoute from 'routing/PublicRoute';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import RequestResetPassword from 'pages/RequestResetPassword';
import PageNotFound from 'pages/PageNotFound';
import AdminUsers from 'pages/admin/AdminUsers';
import RegisterUser from 'pages/RegisterUser';
import CompleteRegistration from 'pages/CompleteRegistration';
import { useSelector } from 'react-redux';
import { getInvitationEnabledSetting, getRegistrationEnabledSetting } from 'store/selector/ApplicationSelector';
import 'mapbox-gl/dist/mapbox-gl.css';
import General from './pages/general/General';
import ManagerTenants from './pages/manager/ManagerTenants';
import ManagerGateways from './pages/manager/ManagerGateways';
import Manager from './pages/manager/Manager';
import FarmerApp from './pages/farmerapp/FarmerApp';
import FarmerAppCustomers from './pages/farmerapp/FarmerAppCustomers';
import FarmerAppDevices from './pages/farmerapp/FarmerAppDevices';
import GeneralNodesGraph from './pages/general/GeneralNodesGraph';
import AdminServices from './pages/admin/AdminServices';
import GeneralManagerNodesGraph from './pages/general/GeneralManagerNodesGraph';
import GeneralFarmerNodesGraph from './pages/general/GeneralFarmerNodesGraph';
import ServiceOverview from 'pages/serviceOverview/ServiceOverview';
import ServiceStatusManager from 'pages/serviceOverview/ServiceStatusManager';
import ServiceStatusApp from 'pages/serviceOverview/ServiceStatusApp';
import Analytics from 'pages/analytics/Analytics';
import ManagerSubscriptions from 'pages/manager/ManagerSubscriptions';
import PublicWorldMapWrapper from './components/worldmap/PublicWorldMapWrapper';

const App = (): React$Node => {
  const registrationEnabled = useSelector(getRegistrationEnabledSetting);
  const invitationEnabled = useSelector(getInvitationEnabledSetting);
  return (
    <Routes>
      <Route
        path={GENERAL}
        element={
          <PrivateRoute>
            <General />
          </PrivateRoute>
        }
      >
        <Route path={GENERAL_OVERVIEW} element={<GeneralOverview />} />
        <Route path={GENERAL_NODES_MAP} element={<GeneralNodesGraph />}>
          <Route path={GENERAL_NODES_MAP_MANAGER} element={<GeneralManagerNodesGraph />} />
          <Route path={GENERAL_NODES_MAP_FARMER} element={<GeneralFarmerNodesGraph />} />
        </Route>
      </Route>
      <Route
        path={SERVICE_OVERVIEW}
        element={
          <PrivateRoute>
            <ServiceOverview />
          </PrivateRoute>
        }
      >
        <Route path={SERVICE_STATUS_APP} element={<ServiceStatusApp />} />
        <Route path={SERVICE_STATUS_MANAGER} element={<ServiceStatusManager />} />
      </Route>
      <Route
        path={MANAGER}
        element={
          <PrivateRoute>
            <Manager />
          </PrivateRoute>
        }
      >
        <Route path={MANAGER_TENANTS} element={<ManagerTenants />} />
        <Route path={MANAGER_SUBSCRIPTIONS} element={<ManagerSubscriptions />} />
        <Route path={MANAGER_GATEWAYS} element={<ManagerGateways />} />
      </Route>
      <Route
        path={FARMER_APP}
        element={
          <PrivateRoute>
            <FarmerApp />
          </PrivateRoute>
        }
      >
        <Route path={FARMER_APP_CUSTOMERS} element={<FarmerAppCustomers />} />
        <Route path={FARMER_APP_DEVICES} element={<FarmerAppDevices />} />
      </Route>
      <Route
        path={ANALYTICS}
        element={
          <PrivateRoute>
            <Analytics />
          </PrivateRoute>
        }
      />
      <Route
        path={ADMINISTRATION_USERS}
        element={
          <PrivateRoute restricted>
            <AdminUsers />
          </PrivateRoute>
        }
      />
      <Route
        path={ADMINISTRATION_SERVICES}
        element={
          <PrivateRoute restricted>
            <AdminServices />
          </PrivateRoute>
        }
      />
      <Route
        path={LOGIN}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={COMPLETE_REGISTRATION}
        element={
          <PublicRoute>
            <CompleteRegistration />
          </PublicRoute>
        }
      />
      <Route
        path={RESET_PASSWORD}
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={REQUEST_RESET_PASSWORD}
        element={
          <PublicRoute>
            <RequestResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path={PUBLIC_WORLD_MAP}
        element={
          <PublicRoute noRedirect withLogo={false}>
            <PublicWorldMapWrapper />
          </PublicRoute>
        }
      />
      {registrationEnabled || invitationEnabled ? (
        <Route
          path={REGISTER_USER}
          element={
            <PublicRoute>
              <RegisterUser />
            </PublicRoute>
          }
        />
      ) : null}
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
