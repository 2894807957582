// @flow
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyrights from 'components/Copyrights';
import AppLogo from 'components/AppLogo';
import { useNavigate } from 'react-router';

const PublicPage = ({ children, withLogo }: any): React$Node => {
  const navigate = useNavigate();
  return (
    <Container component="main" maxWidth="xs">
      {withLogo && <AppLogo onClick={() => navigate('/')} />}
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {children}
      </Box>
      {withLogo && <Copyrights />}
    </Container>
  );
};

export default PublicPage;
