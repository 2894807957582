export const addTooltipHandling = (map, popup) => {
  map.on('mouseenter', 'devices-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'devices-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'sites-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'sites-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'sites-on-premise-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'sites-on-premise-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'buildings-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'buildings-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });

  map.on('mouseenter', 'users-layer', (e) => {
    // Change the cursor style as a UI indicator.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(map);
  });

  map.on('mouseleave', 'users-layer', () => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
    popup.remove();
  });
};
