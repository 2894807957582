// @flow
import { createSlice } from '@reduxjs/toolkit';

import type { UserState } from 'types/State.types';

const initialState = {
  authenticated: false,
  profile: null
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedUser: (draftState: UserState, action: any) => {
      draftState.authenticated = true;
      draftState.profile = action.payload;
    },
    removeLoggedUser: () => initialState
  }
});

const { actions, reducer } = UserSlice;
export const { setLoggedUser, removeLoggedUser } = actions;

export default reducer;
