import { v4 as uuid } from 'uuid';
import { isDefined } from 'util/ObjectUtils';
import { addAlert } from 'store/slice/ApplicationSlice';

export const isJsonResponse = (response) => {
  const contentType = response.headers.get('content-type');
  return isDefined(contentType) && contentType.indexOf('application/json') !== -1;
};

export const isEmptyResponse = (response) => {
  const { status, headers } = response;
  const contentLength = headers.get('content-length');
  return status === 204 || (isDefined(contentLength) && contentLength === '0');
};

export const isHalResponse = (response) => {
  const contentType = response.headers.get('content-type');
  return isDefined(contentType) && contentType.indexOf('application/hal+json') !== -1;
};

export const isXmlResponse = (response) => {
  const contentType = response.headers.get('content-type');
  return isDefined(contentType) && contentType.indexOf('text/xml') !== -1;
};

export const getRequestTimeoutPromise = () => {
  return new Promise((_, reject) => setTimeout(() => reject(new Error('The request has timed out.')), 60000));
};

export const createFetchConfig = (method, locale, data) => {
  // Default options are marked with *
  const configData = {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer' // no-referrer, *client
  };

  if (data) {
    configData.body = JSON.stringify(data); // body data type must match "Content-Type" header
  }

  return configData;
};

export const handleError = (error, dispatch) => {
  const { title = 'An error occurred', message, status, errors } = error;

  // NOTE: If errors are provided for 422 status then the responsible caller should handle
  // the form field validation manually.
  const showErrorAlert = status !== 422 || (errors && errors.length === 0);
  if (showErrorAlert) {
    const alert = {
      id: uuid(),
      severity: 'error',
      title,
      message
    };
    dispatch(addAlert(alert));
  }

  return Promise.reject(error);
};
