import enTranslations from 'i18n/translations/en.json';
import svTranslations from 'i18n/translations/sv.json';
import daTranslations from 'i18n/translations/da.json';

export const SUPPORTED_LOCALES = {
  EN: { locale: 'en-GB', messages: enTranslations },
  SV: { locale: 'sv-SE', messages: svTranslations },
  DA: { locale: 'da-DK', messages: daTranslations }
};

export const getMessages = (locale) => {
  let messages = SUPPORTED_LOCALES.EN.messages;
  Object.values(SUPPORTED_LOCALES).forEach((value) => {
    if (locale === value.locale) {
      messages = value.messages;
    }
  });

  return messages;
};
