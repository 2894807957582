// @flow
import '@react-sigma/core/lib/react-sigma.min.css';
import { useWorkerLayoutForceAtlas2 } from '@react-sigma/layout-forceatlas2';
import { useEffect } from 'react';

const NetworkGraphAutoForceAtlas = (): React$Node => {
  const { start, kill } = useWorkerLayoutForceAtlas2({ settings: { slowDown: 10, barnesHutOptimize: true } });

  useEffect((): Function => {
    // start FA2
    start();
    return () => {
      // Kill FA2 on unmount
      kill();
    };
  }, [start, kill]);

  return null;
};

export default NetworkGraphAutoForceAtlas;
