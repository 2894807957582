// @flow

import { Dispatch } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get } from 'api/RestApi';
import { AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE } from 'constants/RestApiConstants';
import { handleError } from 'api/RestApiHelper';

export async function getAnalyticsBasicAuthorizationCode(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
