// @flow
import { Navigate } from 'react-router';
import { GENERAL_OVERVIEW } from 'routing/RouteConstants';
import { useSelector } from 'react-redux';
import { isDefined } from 'util/ObjectUtils';
import { isUserAuthenticated } from 'store/selector/UserSelector';
import PublicPage from 'pages/PublicPage';

const PublicRoute = ({ children, noRedirect = false, withLogo = true }: any): React$Node => {
  const authenticated = useSelector(isUserAuthenticated);

  let navigateTo = null;
  if (authenticated && !noRedirect) {
    navigateTo = GENERAL_OVERVIEW;
  }

  return isDefined(navigateTo) ? (
    <Navigate to={navigateTo} replace />
  ) : (
    <PublicPage withLogo={withLogo}>{children}</PublicPage>
  );
};

export default PublicRoute;
