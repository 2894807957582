// @flow
import { array, object, string } from 'yup';

const UpdateUserProfileSchema = object({
  firstName: string().required('app.validation.required'),
  lastName: string().required('app.validation.required'),
  email: string().required('app.validation.required').email('app.validation.invalid.format'),
  password: string().notRequired(),
  confirmPassword: string().test(
    'password-validation',
    'app.validation.password.missmatch',
    (value: string, context: any): boolean => {
      return context.parent.password === value;
    }
  )
});

const CreateUserProfileSchema = object({
  username: string().required('app.validation.required'),
  firstName: string().required('app.validation.required'),
  lastName: string().required('app.validation.required'),
  email: string().required('app.validation.required').email('app.validation.invalid.format'),
  password: string().required('app.validation.required'),
  confirmPassword: string()
    .required('app.validation.required')
    .test('password-validation', 'app.validation.password.missmatch', (value: string, context: any): boolean => {
      return context.parent.password === value;
    })
});

const UserEmailSchema = object({
  emails: array().of(string('app.validation.invalid.format')),
  email: string()
    .required('app.validation.required')
    .email('app.validation.invalid.format')
    .test(
      'email-list-validation',
      'app.validation.inviteUsers.email.duplicate',
      (value: string, context: any): boolean => {
        return !context.parent.emails.includes(value);
      }
    )
});

export const validateUpdateUserProfileForm = (userData): Promise =>
  UpdateUserProfileSchema.validate(userData, { strict: true, abortEarly: false });

export const validateCreateUserProfileForm = (userData): Promise =>
  CreateUserProfileSchema.validate(userData, { strict: true, abortEarly: false });

export const validateEmail = (userEmail): Promise =>
  UserEmailSchema.validate(userEmail, { strict: true, abortEarly: false });
