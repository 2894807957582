// @flow

import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = {
  titleKey: string,
  value: number | string
};
const StatisticsItem = ({ titleKey, value }: Props): React$Node => {
  const intl = useIntl();
  return (
    <Box
      sx={{ pl: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
      elevation={0}
    >
      <Typography variant="h5" color="primary" sx={{ lineHeight: '24px' }}>
        {value}
      </Typography>
      <Typography variant="subtitle1" sx={{ lineHeight: '20px' }}>
        {intl.formatMessage({ id: titleKey })}
      </Typography>
    </Box>
  );
};

export default StatisticsItem;
