// @flow
import { Dispatch } from '@reduxjs/toolkit';

import { handleError } from 'api/RestApiHelper';
import {
  ADMIN_REFRESH_LONG_TERM_CACHE,
  ADMIN_REFRESH_SHORT_TERM_CACHE,
  ADMIN_USERS,
  ADMIN_USERS_ACTIVATION
} from 'constants/RestApiConstants';
import { deleteRequest, get, post, put } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import type { UserProfile } from 'types/State.types';

export async function fetchAllUsers(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ADMIN_USERS)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function addUser(user: UserProfile, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return post(ADMIN_USERS, user)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function saveUserDetailsAsAdmin(body: any, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return put(ADMIN_USERS, body)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function deleteUser(userId: number, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return deleteRequest(`${ADMIN_USERS}/${userId}`)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function toggleUserActivation(id: number, activated: boolean, dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return put(ADMIN_USERS_ACTIVATION, { id, activated })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function refreshLongTermCache(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ADMIN_REFRESH_LONG_TERM_CACHE)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function refreshShortTermCache(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(ADMIN_REFRESH_SHORT_TERM_CACHE)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
