// @flow

import { number, object, string } from 'yup';

const CreateTenantSchema = object({
  tenantAdminUsername: string().required('app.validation.required').email('app.validation.invalid.format'),
  tenantUserPassword: string().required('app.validation.required'),
  customerUserUsername: string().required('app.validation.required').email('app.validation.invalid.format'),
  customerUserPassword: string().required('app.validation.required'),
  endPaymentDate: number().required('app.validation.required'),
  daysBeforeWarning: number().required('app.validation.required').min(0, 'app.validation.value.min'),
  productionDate: number().required('app.validation.required'),
  batchNumber: number().required('app.validation.required').min(0, 'app.validation.value.min')
});

const UpdateTenantSchema = object({
  tenantAdminUsername: string().required('app.validation.required').email('app.validation.invalid.format'),
  tenantUserPassword: string().required('app.validation.required'),
  customerUserUsername: string().required('app.validation.required').email('app.validation.invalid.format'),
  customerUserPassword: string().required('app.validation.required'),
  endPaymentDate: number().required('app.validation.required'),
  daysBeforeWarning: number().required('app.validation.required').min(0, 'app.validation.value.min')
});

const ShutoutDateUpdateSchema = object({
  endPaymentDate: number().required('app.validation.required')
});

export const validateCreateTenantForm = (tenantData): Promise =>
  CreateTenantSchema.validate(tenantData, { strict: true, abortEarly: false });

export const validateUpdateTenantForm = (tenantData): Promise =>
  UpdateTenantSchema.validate(tenantData, { strict: true, abortEarly: false });

export const validateShutoutDateUpdateForm = (tenantData): Promise =>
  ShutoutDateUpdateSchema.validate(tenantData, { strict: true, abortEarly: false });
