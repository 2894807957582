// @flow

import { grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { isEmpty, isNotEmpty } from 'util/ObjectUtils';
import { updateTelemetryAttribute } from 'api/service/ThingsboardFarmerTelemetryService';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import type { TbCustomerDataItem } from 'types/Farmer.types';
import { FARMER_CUSTOMER_ATTRIBUTE_ENUM } from 'constants/CustomerConstants';
import TextField from '@mui/material/TextField';

type Props = {
  open: boolean,
  handleClose: Function,
  customer: TbCustomerDataItem,
  attributeToUpdate: String,
  fetchCustomers: Function
};

const CustomerAttributesDialog = ({
  open,
  handleClose,
  customer,
  attributeToUpdate,
  fetchCustomers
}: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [numFreeDeviceValue, setNumFreeDevicesValue] = useState('');

  useEffect(() => {
    const attribute = customer?.attributes?.find((attribute) => attribute.key === attributeToUpdate);
    const attributeValue = attribute?.value;
    if (attributeToUpdate === FARMER_CUSTOMER_ATTRIBUTE_ENUM.NUM_FREE_DEVICES) {
      setNumFreeDevicesValue(attributeValue);
    }
  }, []);

  const handleDialogClose = () => {
    setNumFreeDevicesValue(null);
    handleClose();
  };

  const handleNumFreeDevicesChange = (event: Event) => {
    let newValue = event.target.value ?? null;
    if (isNotEmpty(newValue)) {
      newValue = parseInt(newValue, 10);
    }
    setNumFreeDevicesValue(newValue);
  };

  const handleSave = () => {
    const body = JSON.stringify({
      [FARMER_CUSTOMER_ATTRIBUTE_ENUM.NUM_FREE_DEVICES]: numFreeDeviceValue
    });
    const newUpdateObject = {
      entityId: customer.customerDetails.id.id,
      entityType: customer.customerDetails.id.entityType,
      body: body
    };
    updateTelemetryAttribute(dispatch, newUpdateObject)
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.alert.success.customer.update' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchCustomers)
      .then(handleDialogClose);
  };

  let ResolvedContent;
  if (attributeToUpdate === FARMER_CUSTOMER_ATTRIBUTE_ENUM.NUM_FREE_DEVICES) {
    ResolvedContent = (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          label={intl.formatMessage({ id: 'app.customers.update.numFreeDevices' })}
          type={'number'}
          value={numFreeDeviceValue}
          onChange={handleNumFreeDevicesChange}
        />
      </Box>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.customers.update.attributes' }, { name: customer?.customerDetails?.name })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>{ResolvedContent}</Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button onClick={handleSave} variant="contained" size="small" disabled={isEmpty(numFreeDeviceValue)}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleDialogClose}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerAttributesDialog;
