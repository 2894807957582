//@flow
import { useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl';
import Box from '@mui/material/Box';
import { isDefined } from 'util/ObjectUtils';
import { addTooltipHandling } from './WorldMapHelper';
import { useTheme } from '@mui/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import SatelliteAltOutlinedIcon from '@mui/icons-material/SatelliteAltOutlined';
import { getWorldMapFocusData, storeWorldMapFocusData } from 'api/service/LocalStorageService';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import { TbLocationItem } from 'types/Thingsboard.types';
import turfBbox from '@turf/bbox';

mapboxgl.accessToken =
  'pk.eyJ1IjoiamVwcGV3YWx0aGVyIiwiYSI6ImNsZjV1a28wOTFnM2Mzem56M2h0dTh0dGcifQ._tlpkVov-vf_4Knl-VSzcg';

export const MAP_BOX_STYLE = {
  LIGHT: 'mapbox://styles/mapbox/light-v11',
  DARK: 'mapbox://styles/mapbox/dark-v11',
  SATELLITE: 'mapbox://styles/mapbox/satellite-v9'
};

const resolveMapStyle = (theme: any, satelliteView: boolean): string => {
  if (satelliteView) {
    return MAP_BOX_STYLE.SATELLITE;
  }

  if (theme.palette.mode === 'dark') {
    return MAP_BOX_STYLE.DARK;
  }
  return MAP_BOX_STYLE.LIGHT;
};

const initializeMapSpin = (map: any) => {
  // At low zooms, complete a revolution every two minutes.
  const secondsPerRevolution = 120;
  // Above zoom level 5, do not rotate.
  const maxSpinZoom = 5;
  // Rotate at intermediate speeds between zoom levels 3 and 5.
  const slowSpinZoom = 3;

  let userInteracting = false;
  let spinEnabled = true;

  const spinGlobe = () => {
    const zoom = map.getZoom();
    if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
      let distancePerSecond = 360 / secondsPerRevolution;
      if (zoom > slowSpinZoom) {
        // Slow spinning at higher zooms
        const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
        distancePerSecond *= zoomDif;
      }
      const center = map.getCenter();
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      map.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  };

  // Pause spinning on interaction
  map.on('mousedown', () => {
    userInteracting = true;
  });

  // Restart spinning the globe when interaction is complete
  map.on('mouseup', () => {
    userInteracting = false;
    spinGlobe();
  });

  // These events account for cases where the mouse has moved
  // off the map, so 'mouseup' will not be fired.
  map.on('dragend', () => {
    userInteracting = false;
    spinGlobe();
  });
  map.on('pitchend', () => {
    userInteracting = false;
    spinGlobe();
  });
  map.on('rotateend', () => {
    userInteracting = false;
    spinGlobe();
  });

  // When animation is complete, start spinning if there is no ongoing interaction
  map.on('moveend', () => {
    spinGlobe();
  });

  spinGlobe();
};

type Props = {
  locationGeoJsonMap: Object,
  searchedItem?: TbLocationItem,
  view: string,
  useStoredPosition: boolean,
  planetView?: boolean
};

const WorldMap = ({
  locationGeoJsonMap,
  searchedItem,
  view,
  useStoredPosition = true,
  planetView = false
}: Props): React$Node => {
  const theme = useTheme();
  const [satelliteView, setSatelliteView] = useState(view === MAP_BOX_STYLE.SATELLITE);
  const [mapState, setMapState] = useState(null);
  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect((): Function => {
    const worldMapFocusData = getWorldMapFocusData();

    let sitesCloudGeoJson = locationGeoJsonMap[WORLD_MAP_ELEMENTS.SITE];
    let sitesOnPremiseGeoJson = locationGeoJsonMap[WORLD_MAP_ELEMENTS.SITE_ON_PREMISE];
    let devicesGeoJson = locationGeoJsonMap[WORLD_MAP_ELEMENTS.SENSOR_SPEAR];
    let buildingsGeoJson = locationGeoJsonMap[WORLD_MAP_ELEMENTS.BUILDING];
    let usersGeoJson = locationGeoJsonMap[WORLD_MAP_ELEMENTS.USER];

    let map;

    if (useStoredPosition) {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: resolveMapStyle(theme, satelliteView),
        center: [worldMapFocusData.longitude, worldMapFocusData.latitude],
        zoom: worldMapFocusData.zoomLevel
      });
    } else if (planetView) {
      const planetViewData = {
        zoomLevel: 2,
        longitude: 10.310726636036026,
        latitude: 55.536284982296145
      };

      // https://docs.mapbox.com/mapbox-gl-js/example/globe-spin/
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: resolveMapStyle(theme, satelliteView),
        center: [planetViewData.longitude, planetViewData.latitude],
        zoom: planetViewData.zoomLevel
      });

      map.on('style.load', () => {
        map.setFog({
          color: 'rgb(255,255,255)', // Lower atmosphere
          'high-color': 'rgb(159,159,159)', // Upper atmosphere
          'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
          'space-color': 'transparent', // Background color
          'star-intensity': 0 // Background star brightness (default 0.35 at low zoooms )
        });
      });

      initializeMapSpin(map);
    } else {
      // Note: to be able to find the center for all sites, not just for the cloud or on premise sites
      const tempSitesGeoJson = { features: [], type: 'FeatureCollection' };
      tempSitesGeoJson.features.push(...sitesCloudGeoJson.features);
      tempSitesGeoJson.features.push(...sitesOnPremiseGeoJson.features);
      const bbox = turfBbox(tempSitesGeoJson);
      const minX = bbox[0];
      const minY = bbox[1];
      const maxX = bbox[2];
      const maxY = bbox[3];

      const distance = Math.abs(minX - maxX);

      let bounds = [
        [Math.max(minX - 3 * distance, -90), Math.max(minY - 3 * distance, -90)], // southwestern corner of the bounds
        [Math.min(maxX + 3 * distance, 90), Math.min(maxY + 3 * distance, 90)] // northeastern corner of the bounds
      ];

      const featureCollection = [];
      featureCollection.push(...sitesCloudGeoJson.features);
      featureCollection.push(...sitesOnPremiseGeoJson.features);
      featureCollection.push(...devicesGeoJson.features);
      featureCollection.push(...buildingsGeoJson.features);
      featureCollection.push(...usersGeoJson.features);

      if (featureCollection.length === 1) {
        let location = featureCollection[0]?.geometry.coordinates;
        const centerCoords = [location[0], location[1]];

        map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: resolveMapStyle(theme, satelliteView),
          center: centerCoords,
          zoom: 17
        });
      } else if (featureCollection.length > 1) {
        const center = [(minX + maxX) / 2, (minY + maxY) / 2];
        const centerCoords = Number.isFinite(center)
          ? center
          : [worldMapFocusData?.longitude, worldMapFocusData?.latitude];
        const areBoundsFinite =
          Number.isFinite(minX) && Number.isFinite(minY) && Number.isFinite(maxX) && Number.isFinite(maxY);

        map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: resolveMapStyle(theme, satelliteView),
          center: centerCoords,
          bounds: areBoundsFinite ? bounds : undefined,
          zoom: 1
        });
      } else {
        map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: resolveMapStyle(theme, satelliteView),
          center: [worldMapFocusData.longitude, worldMapFocusData.latitude],
          zoom: worldMapFocusData.zoomLevel
        });
      }
    }

    var popup = new mapboxgl.Popup({
      offset: [0, -7],
      closeButton: false,
      closeOnClick: false
    });

    map?.on('load', function () {
      // Add sites source & layers
      map.addSource('sites-source', {
        type: 'geojson',
        data: {
          type: sitesCloudGeoJson.type,
          features: sitesCloudGeoJson.features
        }
      });
      map.addLayer({
        id: 'sites-layer',
        type: 'circle',
        source: 'sites-source',
        paint: {
          'circle-radius': 4,
          'circle-color': '#ed6d05'
        }
      });

      if (isDefined(sitesOnPremiseGeoJson)) {
        // Add sites on premise source & layers
        map.addSource('sites-on-premise-source', {
          type: 'geojson',
          data: {
            type: sitesOnPremiseGeoJson.type,
            features: sitesOnPremiseGeoJson.features
          }
        });
        map.addLayer({
          id: 'sites-on-premise-layer',
          type: 'circle',
          source: 'sites-on-premise-source',
          paint: {
            'circle-radius': 4,
            'circle-color': '#ed6d05',
            'circle-stroke-color': '#1068d5',
            'circle-stroke-width': 1
          }
        });
      }

      // Add spears source & layers
      map.addSource('devices-source', {
        type: 'geojson',
        data: {
          type: devicesGeoJson.type,
          features: devicesGeoJson.features
        }
      });
      map.addLayer({
        id: 'devices-layer',
        type: 'circle',
        source: 'devices-source',
        paint: {
          'circle-radius': 4,
          'circle-color': '#00b5d8'
        }
      });

      // Add buildings source & layers
      map.addSource('buildings-source', {
        type: 'geojson',
        data: {
          type: buildingsGeoJson.type,
          features: buildingsGeoJson.features
        }
      });
      map.addLayer({
        id: 'buildings-layer',
        type: 'circle',
        source: 'buildings-source',
        paint: {
          'circle-radius': 4,
          'circle-color': '#11ce36'
        }
      });

      // Add users source & layers
      map.addSource('users-source', {
        type: 'geojson',
        data: {
          type: usersGeoJson.type,
          features: usersGeoJson.features
        }
      });
      map.addLayer({
        id: 'users-layer',
        type: 'circle',
        source: 'users-source',
        paint: {
          'circle-radius': 4,
          'circle-color': '#b292f3'
        }
      });

      // Add tooltip on hover
      addTooltipHandling(map, popup);
      setMapState(map);
    });

    // Add navigation control (the +/- zoom buttons)
    map?.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Clean up on unmount
    return () => {
      if (useStoredPosition) {
        const zoomLevel = map.getZoom();
        const center = map.getCenter();
        storeWorldMapFocusData(zoomLevel, center.lat, center.lng);
      }
      map?.remove();
    };
  }, [theme, satelliteView, locationGeoJsonMap]);

  useEffect(() => {
    focusOnSearch();
  }, [searchedItem]);

  const focusOnSearch = () => {
    if (isDefined(searchedItem) && isDefined(searchedItem.longitude) && isDefined(searchedItem.latitude)) {
      mapState?.flyTo({
        center: [searchedItem.longitude, searchedItem.latitude],
        zoom: 10,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    }
  };

  const toggleSatelliteView = (e: Event, selected: boolean) => {
    setSatelliteView(selected ?? false);
  };

  return (
    <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} ref={mapContainerRef}>
      {!planetView && (
        <ToggleButtonGroup
          orientation="vertical"
          value={satelliteView}
          exclusive
          onChange={toggleSatelliteView}
          color="success"
          sx={{ position: 'absolute', top: 4, left: 4, zIndex: 999 }}
        >
          <ToggleButton value={true}>
            <SatelliteAltOutlinedIcon color={satelliteView ? 'info' : 'success'} />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default WorldMap;
