// @flow
import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationAlerts } from 'store/selector/ApplicationSelector';
import { removeAlert } from 'store/slice/ApplicationSlice';

const AppAlerts = (): React$Node => {
  const dispatch = useDispatch();
  const alerts = useSelector(getApplicationAlerts);

  const handleClose = (alertId: string) => {
    dispatch(removeAlert(alertId));
  };

  return (
    <React.Fragment>
      {alerts.map((alert) => (
        <Snackbar
          key={`snackbar-${alert.id}`}
          open
          autoHideDuration={6000}
          onClose={() => handleClose(alert.id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </React.Fragment>
  );
};

export default AppAlerts;
