// @flow
import { useSelector } from 'react-redux';
import { GENERAL_OVERVIEW, LOGIN } from 'routing/RouteConstants';
import { Navigate } from 'react-router';
import { isDefined } from 'util/ObjectUtils';
import { isAdministrator, isUserAuthenticated } from 'store/selector/UserSelector';
import NavigationBar from 'components/navigation/NavigationBar';

type Props = {
  restricted?: boolean, // if set to true than only Administrators can access this route
  children: React$Node
};

const PrivateRoute = (props: Props): React$Node => {
  const authenticated = useSelector(isUserAuthenticated);
  const administrator = useSelector(isAdministrator);
  const { restricted = false, children } = props;

  const enableRoute = restricted ? administrator : authenticated;

  let navigateTo = null;
  if (authenticated) {
    if (!enableRoute) {
      navigateTo = GENERAL_OVERVIEW;
    }
  } else {
    navigateTo = LOGIN;
  }

  return isDefined(navigateTo) ? <Navigate to={navigateTo} replace /> : <NavigationBar>{children}</NavigationBar>;
};

export default PrivateRoute;
