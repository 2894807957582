// @flow
import { Dispatch } from '@reduxjs/toolkit';
import {
  TB_FARMER_ALARMS,
  TB_FARMER_ASSETS_DATA,
  TB_FARMER_ASSETS_FILTERED,
  TB_FARMER_CUSTOMER_DEVICE_ASSIGN,
  TB_FARMER_CUSTOMER_DEVICE_UNASSIGN,
  TB_FARMER_CUSTOMERS,
  TB_FARMER_CUSTOMERS_DATA,
  TB_FARMER_CUSTOMERS_RELATIONS_DATA,
  TB_FARMER_DEVICES_DATA,
  TB_FARMER_DEVICES_DATA_BY_TYPE,
  TB_FARMER_DEVICES_TELEMETRY_DATA,
  TB_FARMER_RELATIONS_DELETE_CUSTOMER_TO_DEVICE,
  TB_FARMER_SPEARS_TELEMETRY_DATA,
  TB_FARMER_USER_ACCESS_TOKEN,
  TB_LOCATION_DATA,
  TB_LOCATION_SITES_DATA,
  TB_MANAGER_ASSETS_DATA,
  TB_MANAGER_ASSETS_DEVICES_WITH_TELEMETRY,
  TB_MANAGER_ASSETS_WITH_RELATIONS_DATA,
  TB_MANAGER_DEVICES_DATA,
  TB_MANAGER_DEVICES_WITH_TELEMETRY,
  TB_MANAGER_RPC,
  TB_MANAGER_SITES_DATA,
  TB_MANAGER_TENANTS_ASSET_INFO,
  TB_MANAGER_TENANTS_COUNTER,
  TB_MANAGER_TENANTS_MASTER,
  TB_MANAGER_TENANTS_WITH_TELEMETRY
} from 'constants/RestApiConstants';
import { deleteRequest, get, post, put } from 'api/RestApi';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import type { CreateTenantItem, TbAlarmsStatus, TbFarmerDeviceType, TbTenantMaster } from 'types/Thingsboard.types';
import { TbDeviceAssetType, TbDeviceType, TbRpcPayload } from 'types/Thingsboard.types';
import { handleError } from '../RestApiHelper';

export async function getTbLocationData(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_LOCATION_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbLocationSitesData(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_LOCATION_SITES_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerCustomers(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_CUSTOMERS)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerUserAccessToken(dispatch: Dispatch, userId: string): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_USER_ACCESS_TOKEN, { userId })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerCustomersRelations(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_CUSTOMERS_RELATIONS_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerCustomersData(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_CUSTOMERS_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerDevices(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_DEVICES_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerDevicesByType(dispatch: Dispatch, deviceType: TbFarmerDeviceType): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_DEVICES_DATA_BY_TYPE, { deviceType })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}
export async function getTbFarmerDevicesByTypeWithTelemetry(
  dispatch: Dispatch,
  deviceTypes: Array<TbFarmerDeviceType> = [],
  customerId: string
): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_DEVICES_TELEMETRY_DATA, { deviceTypes, customerId })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerAssets(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_ASSETS_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerAssetsFiltered(dispatch: Dispatch, type: string, customerId: string): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_ASSETS_FILTERED, { type, customerId })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerAlarmsFiltered(
  dispatch: Dispatch,
  customerId: string,
  alarmStatuses: Array<TbAlarmsStatus>
): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_ALARMS, { customerId, alarmStatuses })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbFarmerSpearsWithTelemetry(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_FARMER_SPEARS_TELEMETRY_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function sendTbManagerRpc(dispatch: Dispatch, payload: TbRpcPayload): Promise {
  dispatch(startLoading());
  return post(TB_MANAGER_RPC, payload)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbManagerSites(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_SITES_DATA)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbManagerTenantsAssetInfo(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_TENANTS_ASSET_INFO)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getTbManagerMasterTenantsData(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_TENANTS_MASTER)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all tenants with their telemetries.
 *
 * @param dispatch the Dispatch.
 * @returns {Promise<U>}
 */
export async function getTbManagerTenantsWithTelemetry(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_TENANTS_WITH_TELEMETRY)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Get tenants with elements(devices/assets) counted.
 *
 * @param dispatch the Dispatch.
 * @returns {Promise<U>}
 */
export async function getTbManagerTenantsCounter(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_TENANTS_COUNTER)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all devices for the given type. If device type is not provided it will fetch all devices.
 *
 * @param dispatch the Dispatch.
 * @param deviceType the TbDeviceType.
 * @returns {Promise<U>}
 */
export async function getTbManagerDevices(dispatch: Dispatch, deviceType: TbDeviceType = ''): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_DEVICES_DATA, { deviceType })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all devices with their telemetries for the given type.
 * If device type is not provided it will fetch all devices.
 *
 * @param dispatch the Dispatch.
 * @param deviceType the TbDeviceType.
 * @param tenantId the tenant id.
 * @returns {Promise<U>}
 */
export async function getTbManagerDevicesWithTelemetry(
  dispatch: Dispatch,
  deviceType: TbDeviceType = '',
  tenantId?: string
): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_DEVICES_WITH_TELEMETRY, { deviceType, tenantId })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all device assets for the given type. If asset type is not provided it will fetch all assets.
 *
 * @param dispatch the Dispatch.
 * @param assetType the TbAssetType.
 * @returns {Promise<U>}
 */
export async function getTbManagerDeviceAssets(dispatch: Dispatch, assetType: TbDeviceAssetType = ''): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_ASSETS_DATA, { assetType })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all sites with all their relations.
 *
 * @param dispatch the Dispatch.
 * @param assetType the asset type.
 * @returns {Promise<U>}
 */
export async function getTbManagerDeviceAssetsWithRelations(
  dispatch: Dispatch,
  assetType: TbDeviceAssetType = ''
): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_ASSETS_WITH_RELATIONS_DATA, { assetType })
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

/**
 * Fetches all assets and devices with all their telemetry data.
 *
 * @param dispatch the Dispatch.
 * @returns {Promise<U>}
 */
export async function getTbManagerDeviceAssetsWithTelemetries(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_ASSETS_DEVICES_WITH_TELEMETRY)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function deleteRelationFromCustomerToDevice(
  customerId: string,
  deviceId: string,
  dispatch: Dispatch
): Promise {
  dispatch(startLoading());
  return deleteRequest(`${TB_FARMER_RELATIONS_DELETE_CUSTOMER_TO_DEVICE}/${customerId}/${deviceId}`)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function unassignDeviceFromCustomer(dispatch: Dispatch, deviceId: string): Promise {
  dispatch(startLoading());
  return deleteRequest(`${TB_FARMER_CUSTOMER_DEVICE_UNASSIGN}/${deviceId}`)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function assignDeviceToCustomer(dispatch: Dispatch, customerId: string, deviceId: string): Promise {
  dispatch(startLoading());
  return post(TB_FARMER_CUSTOMER_DEVICE_ASSIGN, { customerId: customerId, deviceId: deviceId })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function createNewTenant(dispatch: Dispatch, createTenant: CreateTenantItem): Promise {
  dispatch(startLoading());
  return post(TB_MANAGER_TENANTS_MASTER, createTenant)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function updateTenant(dispatch: Dispatch, tenantMaster: TbTenantMaster): Promise {
  dispatch(startLoading());
  return put(TB_MANAGER_TENANTS_MASTER, tenantMaster)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
