// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import '@react-sigma/core/lib/react-sigma.min.css';
import { deepCopy, isDefined } from '../../util/ObjectUtils';
import NetworkNodeGraph, { DEFAULT_GRAPH_DATA } from '../../components/networkgraph/NetworkNodeGraph';
import {
  getTbFarmerAssets,
  getTbFarmerCustomers,
  getTbFarmerCustomersRelations,
  getTbFarmerDevices
} from '../../api/service/ThingsboardApiService';

const SPEAR_TB_VALUE = 'cellular_sensor_spear';

const createGraphData = (dispatch: Dispatch): Promise =>
  Promise.all([
    getTbFarmerCustomers(dispatch),
    getTbFarmerAssets(dispatch),
    getTbFarmerDevices(dispatch),
    getTbFarmerCustomersRelations(dispatch)
  ]).then((responses: Array<any>): any => {
    const customers = responses[0];
    const assets = responses[1];
    const devices = responses[2];
    const relations = responses[3];

    const nodeIds = [];
    const data = deepCopy(DEFAULT_GRAPH_DATA);

    customers.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 6,
            color: '#00b5d8',
            label: item.name
          }
        });
      }
    });

    assets.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id) && nodeIds.includes(item.customerId.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 4,
            color: '#2e9b2e',
            label: item.name
          }
        });
      }
    });

    devices.forEach((item: any) => {
      if (item.type === SPEAR_TB_VALUE && !nodeIds.includes(item.id.id) && nodeIds.includes(item.customerId.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 2,
            color: '#c58b35',
            label: item.name
          }
        });
      }
    });

    const assetEdges = assets.map((asset) => ({ from: asset.customerId.id, to: asset.id.id }));
    const relationsEdges = relations.map((relation) => ({ from: relation.from.id, to: relation.to.id }));
    const allEdges = [];
    allEdges.push(...assetEdges);
    allEdges.push(...relationsEdges);

    const allEdgesKeys = [];
    allEdges.forEach((edge: any) => {
      const edgeKey = `${edge.from}-${edge.to}`;
      if (nodeIds.includes(edge.from) && nodeIds.includes(edge.to) && !allEdgesKeys.includes(edgeKey)) {
        allEdgesKeys.push(edgeKey);

        data.edges.push({
          attributes: {
            size: 0.1
          },
          key: edgeKey,
          source: edge.from,
          target: edge.to
        });
      } else {
        console.debug(`Missing node for edge: ${edge.from}-${edge.to}`);
      }
    });

    return data;
  });

const GeneralFarmerNodesGraph = (): React$Node => {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    const graphJson = createGraphData(dispatch);
    graphJson.then((response) => setGraphData(response));
  }, []);

  return isDefined(graphData) ? (
    <NetworkNodeGraph graphData={graphData} manualLayoutSetup={false} />
  ) : (
    <div>No Graph Data</div>
  );
};

export default GeneralFarmerNodesGraph;
