// @flow

import { topBarHeight } from 'components/navigation/NavigationBar';
import { Drawer } from '@mui/material';
import { useTheme } from '@mui/styles';
import type { OdooTenant } from 'types/Odoo.types';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { blueGrey, grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { containersPadding } from 'components/drawer/DataGridSettingsDrawer';
import { useIntl } from 'react-intl';
import { isDefined, isNotEmptyList } from 'util/ObjectUtils';
import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/TenantConstants';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 63;

type Props = {
  open: boolean,
  closeDrawer: Function,
  odooTenants: Array<OdooTenant>,
  tenants: Array<TbAssetTelemetriesItem>
};

const TenantListDrawer = ({ open, closeDrawer, odooTenants, tenants }: Props) => {
  const theme = useTheme();
  const intl = useIntl();
  const odooTenantHeader = intl.formatMessage({ id: 'app.datagrid.drawer.tenant.list.odooTenant' });
  const tbTenantHeader = intl.formatMessage({ id: 'app.datagrid.drawer.tenant.list.tbTenant' });
  const [csvContent, setCsvContent] = useState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const items = [];
    odooTenants?.forEach((odooTenant) => {
      let matchedTenant = tenants?.find((tenant) => {
        let attributes = tenant.attributes;
        const odooTenantId = attributes.find(
          (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
        )?.value;

        return odooTenantId === odooTenant.id;
      });

      let odooTenantName = odooTenant.name;
      let tbTenantName = isDefined(matchedTenant) ? matchedTenant.assetDto.name : '';
      items.push([odooTenantName, tbTenantName]);
    });
    let sortedItems = items.sort((a, b) => (a[1] ? (b[1] ? a[1].localeCompare(b[1]) : -1) : 1));
    setRows(sortedItems);
  }, []);

  useEffect(() => {
    if (rows.length > 1) {
      let content = [odooTenantHeader, tbTenantHeader].join(';') + '\n';
      rows.forEach((row, index) => {
        const dataString = row.join(';');
        content += index < rows.length ? dataString + '\n' : dataString;
      });
      setCsvContent(content);
    }
  }, [rows]);

  const downloadCsvFile = () => {
    const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'Tenant-list(Odoo and TB).csv');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: theme.spacing(drawerWidth),
          overflow: 'hidden'
        }
      }}
      sx={{ zIndex: theme.zIndex.modal + 1 }}
    >
      <Box
        sx={{
          height: `calc(${window.innerHeight}px - ${theme.spacing(topBarHeight)})`,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            height: theme.spacing(topBarHeight),
            borderBottom: `1px solid ${grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: containersPadding
          }}
        >
          <Typography variant="h6" sx={{ color: blueGrey[800] }}>
            {intl.formatMessage({ id: 'app.datagrid.drawer.tenant.list' })}
          </Typography>
          <IconButton onClick={closeDrawer}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            p: containersPadding
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ flex: 1 }}>
                {odooTenantHeader}
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ flex: 1 }}>
                {tbTenantHeader}
              </Typography>
            </Box>
            <Tooltip title={intl.formatMessage({ id: 'app.common.download' })}>
              <IconButton onClick={downloadCsvFile}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {isNotEmptyList(odooTenants) ? (
            rows?.map((row, index) => {
              const odooTenantName = row[0];
              const tbTenantName = row[1];
              return (
                <Box
                  key={`odooTenant-${odooTenantName}-${index + 1}`}
                  sx={{ display: 'flex', flexDirection: 'row', width: '80%' }}
                >
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {`${index + 1}. ${odooTenantName}`}
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {tbTenantName}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Typography variant="caption">{intl.formatMessage({ id: 'app.common.noData' })}</Typography>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default TenantListDrawer;
