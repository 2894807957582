// @flow
import LocationData from './LocationData';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../common/PageContainer';

const GeneralOverview = (): React$Node => {
  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.general.overview'} />
      <LocationData />
    </PageContainer>
  );
};

export default GeneralOverview;
