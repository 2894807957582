// @flow
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import _isEmpty from 'lodash/isEmpty';
import { isStringValue } from 'util/StringUtils';

export const isDefined = (value: any): boolean => value !== null && value !== undefined;

export const isFunctionDefined = (value: any): boolean => isDefined(value) && value instanceof Function;

export const isNotEmptyList = (list: Array<any>): boolean => isDefined(list) && list.length > 0;

export const isEmptyList = (list: Array<any>): boolean => !isDefined(list) || list.length === 0;

export const isEmpty = (value: any): boolean =>
  !isDefined(value) || (isStringValue(value) && (value === '' || value.trim() === ''));

export const isNotEmpty = (value: any): boolean => !isEmpty(value);

export const isEmptyObject = (value: any): boolean => !isNotEmptyObject(value);

export const isNotEmptyObject = (value: any): boolean =>
  isDefined(value) && !_isEmpty(value) && !_isEmpty(_pickBy(value, _identity));

export const deepCopy = (originalObject: any): any => JSON.parse(JSON.stringify(originalObject));
