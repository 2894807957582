// @flow

import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { Autocomplete, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbAssetTelemetriesItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { MANAGER_TENANT_ATTRIBUTE_ENUM, MANAGER_TENANT_TELEMETRY_ENUM } from 'constants/TenantConstants';
import type { ChargeBeeModel } from 'types/ChargeBee.types';
import { createSubscription } from 'api/service/ChargeBeeApiService';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import Typography from '@mui/material/Typography';
import { isDefined, isEmpty } from 'util/ObjectUtils';
import { updateTelemetryAttribute } from 'api/service/ThingsboardManagerTelemetryService';

const defaultCreateSubscriptionObject = {
  startDate: DateTime.now(),
  customerId: null,
  tbTempSensors: 0,
  tbMoistSensors: 0
};

type Props = {
  open: boolean,
  handleClose: Function,
  chargeBeeCustomers: Array<ChargeBeeModel>,
  tenantInfo: TbAssetTelemetriesItem,
  fetchSubscriptions: Function,
  fetchTenantsWithTelemetries: Function,
  fetchUnbilledCharges: Function
};

const CreateSubscriptionDialog = ({
  open,
  handleClose,
  chargeBeeCustomers = [],
  tenantInfo,
  fetchSubscriptions,
  fetchTenantsWithTelemetries,
  fetchUnbilledCharges
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [fieldErrors, setFieldErrors] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [createSubscriptionObject, setCreateSubscriptionObject] = useState(defaultCreateSubscriptionObject);

  useEffect(() => {
    const telemetryData = tenantInfo?.telemetryData;
    let totalTemperatureSensorTB =
      telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_TEMPERATURE_SENSOR_COUNT]?.[0].value ?? 0;
    let totalMoistureSensorsTB =
      telemetryData[MANAGER_TENANT_TELEMETRY_ENUM.TOTAL_MOISTURE_SENSOR_COUNT]?.[0].value ?? 0;

    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      tbTempSensors: totalTemperatureSensorTB,
      tbMoistSensors: totalMoistureSensorsTB,
      tenantNumber: tenantInfo?.assetDto?.name
    }));
  }, []);

  const handleCustomerChange = (customer: ChargeBeeModel) => {
    setSelectedCustomer(customer);
    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      customerId: customer?.data?.id
    }));
  };

  const handleDateChanged = (newValue: DateTime) => {
    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      startDate: newValue
    }));
  };

  const handleTempSensorsChange = (event: Event) => {
    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      tbTempSensors: event.target.value
    }));
  };

  const handleMoistSensorsChange = (event: Event) => {
    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      tbMoistSensors: event.target.value
    }));
  };

  const handleTenantNumberChange = (event: Event) => {
    setCreateSubscriptionObject((oldData) => ({
      ...oldData,
      tenantNumber: event.target.value
    }));
  };

  const handleCloseDialog = () => {
    setSelectedCustomer(null);
    handleClose();
  };

  const handleSave = () => {
    const newCreateSubObject = {
      ...createSubscriptionObject,
      startDate: createSubscriptionObject?.startDate?.toMillis()
    };

    createSubscription(dispatch, newCreateSubObject)
      .then((subscription) => {
        let attributes = tenantInfo?.attributes;
        let cbSubscriptionIds =
          attributes.find(
            (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.CHARGEBEE_SUBSCRIPTIONS
          )?.value ?? [];

        cbSubscriptionIds.push(subscription?.data.id);

        const body = JSON.stringify({
          [MANAGER_TENANT_ATTRIBUTE_ENUM.CHARGEBEE_SUBSCRIPTIONS]: cbSubscriptionIds ?? []
        });
        return {
          entityId: tenantInfo?.assetDto?.id?.id,
          entityType: tenantInfo?.assetDto?.id?.entityType,
          body: body
        };
      })
      .then((response) => updateTelemetryAttribute(dispatch, response))
      .then(() => {
        let alert = {
          id: uuid(),
          severity: 'success',
          title: intl.formatMessage({ id: 'app.common.success' }),
          message: intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.dialog.success' })
        };
        dispatch(addAlert(alert));
      })
      .then(fetchTenantsWithTelemetries)
      .then(fetchSubscriptions)
      .then(fetchUnbilledCharges)
      .then(handleCloseDialog)
      .catch((error: any) => {
        setFieldErrors(resolveBackendValidationErrors(error));
      });
  };

  const isSaveDisabled =
    isEmpty(selectedCustomer) ||
    isEmpty(createSubscriptionObject?.startDate) ||
    isEmpty(createSubscriptionObject?.tbTempSensors) ||
    isEmpty(createSubscriptionObject?.tbMoistSensors);
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          p: 1,
          minWidth: '50vw'
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.create.dialog' })}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            pt: 2,
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column', alignItems: 'flex-start', width: '45%' }}>
            <Autocomplete
              id="customer"
              options={chargeBeeCustomers}
              value={selectedCustomer}
              getOptionLabel={(option) => option?.data?.email ?? ''}
              onChange={(event, value) => handleCustomerChange(value)}
              sx={{ width: '100%' }}
              size="small"
              renderOption={(props, option, index) => {
                const key = `customer-${index}-${option?.data?.id}`;

                let customerLabel = '-';
                if (isDefined(option?.data?.company)) {
                  customerLabel = option?.data?.company;
                } else if (isDefined(option?.data?.first_name) && isDefined(option?.data?.last_name)) {
                  customerLabel = `${option?.data?.first_name} ${option?.data?.last_name}`;
                }

                return (
                  <li {...props} key={key}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1">{customerLabel}</Typography>
                      <Typography variant="body2">{option?.data?.email}</Typography>
                    </Box>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.customer.dialog' })}
                  error={hasFieldError('customer', fieldErrors)}
                  helperText={getFieldErrorMessage(intl, 'customer', fieldErrors)}
                />
              )}
            />
            <DatePicker
              id="startDate"
              disableMaskedInput={true}
              label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.startDate.dialog' })}
              value={createSubscriptionObject?.startDate ?? ''}
              onChange={handleDateChanged}
              sx={{ width: '100%' }}
              textField={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  fullWidth
                  size="small"
                  error={hasFieldError('startDate', fieldErrors)}
                  helperText={getFieldErrorMessage(intl, 'startDate', fieldErrors)}
                />
              )}
              slotProps={{ textField: { size: 'small' } }}
            />
            <TextField
              id="tenantNumber"
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              sx={{ mt: 0, mb: 0 }}
              label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.tenantNumber.dialog' })}
              disabled
              value={createSubscriptionObject?.tenantNumber}
              onChange={handleTenantNumberChange}
              error={hasFieldError('tenantNumber', fieldErrors)}
              helperText={getFieldErrorMessage(intl, 'tenantNumber', fieldErrors)}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5, flexDirection: 'column', alignItems: 'flex-start', width: '45%' }}>
            <TextField
              id="tempSensors"
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              sx={{ mt: 0, mb: 0 }}
              label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.tempSensors.dialog' })}
              disabled
              value={createSubscriptionObject?.tbTempSensors}
              onChange={handleTempSensorsChange}
              error={hasFieldError('tempSensors', fieldErrors)}
              helperText={getFieldErrorMessage(intl, 'tempSensors', fieldErrors)}
            />
            <TextField
              id="moistSensors"
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              sx={{ mt: 0, mb: 0 }}
              label={intl.formatMessage({ id: 'app.datagrid.subscriptions.subscription.moistSensors.dialog' })}
              disabled
              value={createSubscriptionObject?.tbMoistSensors}
              onChange={handleMoistSensorsChange}
              error={hasFieldError('moistSensors', fieldErrors)}
              helperText={getFieldErrorMessage(intl, 'moistSensors', fieldErrors)}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={handleSave} disabled={isSaveDisabled}>
          {intl.formatMessage({ id: 'app.common.create' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSubscriptionDialog;
