// @flow
import type { Alert, RootStateType } from 'types/State.types';

export const getThemeMode = (state: RootStateType): string => state.application.themeMode;
export const isBooted = (state: RootStateType): boolean => state.application.booted;
export const isLoading = (state: RootStateType): boolean => state.application.loadingCounter > 0;
export const getSelectedLocale = (state: RootStateType): string => state.application.locale;
export const getApplicationAlerts = (state: RootStateType): Array<Alert> => state.application.alerts;
export const getRegistrationEnabledSetting = (state: RootStateType): boolean =>
  state.application.settings.registrationEnabled;
export const getInvitationEnabledSetting = (state: RootStateType): boolean =>
  state.application.settings.invitationEnabled;

export const getManagerUrlSetting = (state: RootStateType): string => state.application.settings.managerUrl;
export const getFarmerAppUrlSetting = (state: RootStateType): string => state.application.settings.farmerAppUrl;
export const getOdooUrlSetting = (state: RootStateType): string => state.application.settings.odooUrl;
export const getChargeBeeUrlSetting = (state: RootStateType): string => state.application.settings.chargeBeeUrl;
