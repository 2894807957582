// @flow

import PageTitle from '../../components/PageTitle';
import PageContainer from '../common/PageContainer';
import ManagerDataGrid from 'components/datagrid/manager/ManagerDataGrid';
import { APPLICATION_PAGE } from 'constants/GlobalConstants';

const ManagerTenants = (): React$Node => {
  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.manager.tenants'} />
      <ManagerDataGrid dataGridPage={APPLICATION_PAGE.MANAGER_TENANTS} />
    </PageContainer>
  );
};

export default ManagerTenants;
