// @flow
import '@react-sigma/core/lib/react-sigma.min.css';
import { deepCopy, isDefined } from '../../util/ObjectUtils';
import { useEffect, useState } from 'react';
import NetworkNodeGraph, { DEFAULT_GRAPH_DATA } from '../../components/networkgraph/NetworkNodeGraph';
import { Dispatch } from '@reduxjs/toolkit';
import {
  getTbManagerDeviceAssets,
  getTbManagerDeviceAssetsWithRelations,
  getTbManagerTenantsAssetInfo
} from '../../api/service/ThingsboardApiService';
import { TB_DEVICE_ASSET_TYPE_ENUM } from '../../constants/ThingsboardConstants';
import { useDispatch } from 'react-redux';

const createGraphData = (dispatch: Dispatch): Promise =>
  Promise.all([
    getTbManagerTenantsAssetInfo(dispatch),
    getTbManagerDeviceAssetsWithRelations(dispatch, TB_DEVICE_ASSET_TYPE_ENUM.SITE),
    getTbManagerDeviceAssets(dispatch, TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP),
    getTbManagerDeviceAssets(dispatch, TB_DEVICE_ASSET_TYPE_ENUM.SILO)
  ]).then((responses: Array<any>): object => {
    const tenants = responses[0];
    const sites = responses[1];
    const siloGroups = responses[2];
    const silos = responses[3];

    const nodeIds = [];
    const data = deepCopy(DEFAULT_GRAPH_DATA);

    tenants.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 6,
            color: '#00b5d8',
            label: item.name
          }
        });
      }
    });
    sites.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 4,
            color: '#2e9b2e',
            label: item.name
          }
        });
      }
    });
    siloGroups.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 3,
            color: '#c58b35',
            label: item.name
          }
        });
      }
    });
    silos.forEach((item: any) => {
      if (!nodeIds.includes(item.id.id)) {
        nodeIds.push(item.id.id);
        data.nodes.push({
          key: item.id.id,
          attributes: {
            x: Math.random(),
            y: Math.random(),
            size: 2,
            color: '#ed6d05',
            label: item.name
          }
        });
      }
    });

    const allEdges = [];
    const allEdgesKeys = [];
    sites.forEach((site: any) => {
      let siteEdges = site?.relations?.map((relation) => ({ from: relation.from.id, to: relation.to.id }));
      allEdges.push(...siteEdges);
    });

    const tenantEdges = sites.map((site) => ({ from: site.tenantId.id, to: site.id.id }));
    allEdges.push(...tenantEdges);

    allEdges.forEach((edge: any) => {
      const edgeKey = `${edge.from}-${edge.to}`;
      if (nodeIds.includes(edge.from) && nodeIds.includes(edge.to) && !allEdgesKeys.includes(edgeKey)) {
        allEdgesKeys.push(edgeKey);

        data.edges.push({
          attributes: {
            size: 0.1
          },
          key: edgeKey,
          source: edge.from,
          target: edge.to
        });
      } else {
        console.debug(`Missing node for edge: ${edge.from}-${edge.to}`);
      }
    });

    return data;
  });

const GeneralManagerNodesGraph = (): React$Node => {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    const graphJson = createGraphData(dispatch);
    graphJson.then((response) => setGraphData(response));
  }, []);

  return isDefined(graphData) ? (
    <NetworkNodeGraph graphData={graphData} manualLayoutSetup={false} />
  ) : (
    <div>No Graph Data</div>
  );
};

export default GeneralManagerNodesGraph;
