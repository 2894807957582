// @flow

import { DialogContent } from '@mui/material';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import React, { useEffect, useState } from 'react';
import { getTbManagerDevicesWithTelemetry, getTbManagerMasterTenantsData } from 'api/service/ThingsboardApiService';
import { useDispatch } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { TB_DEVICE_TYPE_ENUM } from 'constants/ThingsboardConstants';
import { TbTenantMaster } from 'types/Thingsboard.types';
import GatewayDataGrid from 'components/datagrid/manager/GatewayDataGrid';

const Transition = React.forwardRef(function Transition(props: any, ref: any): React$Node {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  handleClose: Function,
  tenantMaster: TbTenantMaster
};

const GatewaysDialog = ({ open, handleClose, tenantMaster }: Props): React$Node => {
  const [items, setItems] = useState([]);
  const [masterTenants, setMasterTenants] = useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();

  const tenantName = tenantMaster?.name;
  const title = intl.formatMessage({ id: 'app.datagrid.tenant.gateways.title' }, { name: tenantName });

  useEffect(() => {
    fetchGatewaysForTenant();
  }, []);

  const fetchGatewaysForTenant = () => {
    getTbManagerDevicesWithTelemetry(dispatch, TB_DEVICE_TYPE_ENUM.GATEWAY, tenantMaster?.id?.id).then((response) =>
      setItems(response ?? [])
    );
    getTbManagerMasterTenantsData(dispatch).then((response: TbTenantMaster) => setMasterTenants(response));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <GatewayDataGrid items={items} masterTenants={masterTenants} />
      </DialogContent>
    </Dialog>
  );
};

export default GatewaysDialog;
