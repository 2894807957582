// @flow
import { isDefined } from './ObjectUtils';
import { isNumber } from 'lodash';

const compactNumberFormatter = Intl.NumberFormat('en', { notation: 'compact' });

export const formatCompactNumber = (value: number): string => {
  return isDefined(value) ? compactNumberFormatter.format(value) : 'N/A';
};

export const formatNumber = (value: number, currentLocale: string): string => {
  return isDefined(value) ? new Intl.NumberFormat(currentLocale).format(value) : 'N/A';
};

export const getRandomInt = (minVal: number, maxVal: number): number => {
  const min = Math.ceil(minVal);
  const max = Math.floor(maxVal);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

/**
 * Rounds the number to 1 decimal.
 *
 * @param value the number to be rounded.
 * @returns {number} the rounded number or null if invalid value is provided.
 */
export const roundNumberWithDecimals = (value: number): number => {
  return isNumber(value) ? Math.round((value + Number.EPSILON) * 10) / 10 : null;
};
