// @flow

export const WORLD_MAP_ELEMENTS = {
  SITE: 'SITE',
  SITE_ON_PREMISE: 'SITE_ON_PREMISE',
  SENSOR_SPEAR: 'SENSOR_SPEAR',
  BUILDING: 'BUILDING',
  USER: 'USER'
};

export const WORLD_MAP_LEGEND_ELEMENTS = [
  WORLD_MAP_ELEMENTS.SITE,
  WORLD_MAP_ELEMENTS.SENSOR_SPEAR,
  WORLD_MAP_ELEMENTS.BUILDING
];

export const DATA_GRID_DIALOG_TYPE = {
  USERS_DIALOG: 'USERS_DIALOG',
  DEVICE_DIALOG_SPEARS: 'DEVICE_DIALOG_SPEARS',
  DEVICE_DIALOG_GRAIN_ANALYZERS: 'DEVICE_DIALOG_GRAIN_ANALYZERS',
  BUILDINGS_DIALOG: 'BUILDINGS_DIALOG',
  ALARMS_DIALOG: 'ALARMS_DIALOG',
  GATEWAYS_DIALOG: 'GATEWAYS_DIALOG',
  TENANT_DETAILS: 'TENANT_DETAILS',
  ACTIVE_ALARM_CHART_DIALOG: 'ACTIVE_ALARM_CHART_DIALOG',
  STALE_ALARM_CHART_DIALOG: 'STALE_ALARM_CHART_DIALOG',
  ODOO_TENANT_LINK_DIALOG: 'ODOO_TENANT_LINK_DIALOG',
  CHARGEBEE_SUBSCRIPTION_LINK_DIALOG: 'CHARGEBEE_SUBSCRIPTION_LINK_DIALOG',
  CREATE_SUBSCRIPTION_DIALOG: 'CREATE_SUBSCRIPTION_DIALOG',
  SHUTOUT_DATE_DIALOG: 'SHUTOUT_DATE_DIALOG',
  COMMENT_DIALOG: 'COMMENT_DIALOG',
  NOTE_DIALOG: 'NOTE_DIALOG'
};

export const DATA_GRID_TYPE = {
  TENANTS_DATA_GRID: 'TENANTS_DATA_GRID',
  GATEWAY_DATA_GRID: 'GATEWAY_DATA_GRID',
  CUSTOMERS_DATA_GRID: 'CUSTOMERS_DATA_GRID',
  DEVICES_DATA_GRID: 'DEVICES_DATA_GRID',
  MANAGER_DATA_GRID: 'MANAGER_DATA_GRID'
};

export const LOCATION_ITEM_TYPE = {
  SPEARS: 'SPEARS',
  SITES: 'SITES',
  COSTUMERS: 'CUSTOMERS'
};

export const HEALTH_STATUS_CHECK_ENUM = {
  MANAGER_TELEMETRY_READ_WRITE: 'MANAGER_TELEMETRY_READ_WRITE',
  FARMER_TELEMETRY_READ_WRITE: 'FARMER_TELEMETRY_READ_WRITE'
};

export const CURRENCY_UNIT = {
  EUR: '€'
};

export const APPLICATION_PAGE = {
  MANAGER_TENANTS: 'MANAGER_TENANTS',
  MANAGER_SUBSCRIPTIONS: 'MANAGER_SUBSCRIPTIONS'
};
