// @flow

import type { TbAssetTelemetriesItem, TbTenantMaster, TelemetryAttribute } from 'types/Thingsboard.types';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import { isDefined, isNotEmpty } from 'util/ObjectUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import { getManagerUrlSetting } from 'store/selector/ApplicationSelector';
import { useEffect, useState } from 'react';
import { MANAGER_TENANT_ATTRIBUTE_ENUM, TENANT_STATUS_ENUM } from 'constants/TenantConstants';
import { updateTelemetryAttribute } from 'api/service/ThingsboardManagerTelemetryService';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import { TenantStatusTranslationId } from 'mappers/translationMappers';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { updateTenant } from 'api/service/ThingsboardApiService';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from 'util/CommonUtils';
import { validateUpdateTenantForm } from 'validators/Tenant.validator';
import { ValidationError } from 'yup';
import type { OdooTenant } from 'types/Odoo.types';
import { updateOdooTenantNote } from 'api/service/OdooApiService';

const TENANT_MASTER_DETAILS_FIELDS_ENUM = {
  TENANT_ADMIN_USERNAME: 'tenantAdminUsername',
  TENANT_USER_PASSWORD: 'tenantUserPassword',
  CUSTOMER_USER_USERNAME: 'customerUserUsername',
  CUSTOMER_USER_PASSWORD: 'customerUserPassword',
  END_PAYMENT_DATE: 'endPaymentDate',
  DAYS_BEFORE_WARNING: 'daysBeforeWarning',
  DESCRIPTION: 'description'
};

type Props = {
  open: boolean,
  handleClose: Function,
  tenantMaster: TbTenantMaster,
  tenantInfo?: TbAssetTelemetriesItem,
  fetchTenantsWithTelemetries?: Function,
  fetchTenants?: Function,
  fetchOdooTenants: Array<OdooTenant>,
  odooTenants: Array<OdooTenant>
};

const TenantDetailsDialog = ({
  open,
  handleClose,
  tenantMaster,
  tenantInfo,
  fetchTenantsWithTelemetries,
  fetchTenants,
  fetchOdooTenants,
  odooTenants
}: Props): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const managerUrl = useSelector(getManagerUrlSetting);
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);
  const [selectedTenantStatus, setSelectedTenantStatus] = useState(null);
  const [selectedOdooTenant, setSelectedOdooTenant] = useState(null);
  const [isOnPremiseTenant, setIsOnPremiseTenant] = useState(false);

  const [tenantMasterState, setTenantMasterState] = useState({
    ...tenantMaster,
    endPaymentDate: DateTime.fromMillis(tenantMaster?.endPaymentDate)
  });
  const [userBlocked, setUserBlocked] = useState(tenantMaster?.endPaymentDate === 1);
  const [fieldErrors, setFieldErrors] = useState([]);

  useEffect(() => {
    if (isDefined(tenantInfo)) {
      const attributes = tenantInfo?.attributes;
      let tenantStatus = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.TENANT_STATUS
      )?.value;
      if (isNotEmpty(tenantStatus)) {
        setSelectedTenantStatus(tenantStatus);
      }

      const odooTenantId = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
      )?.value;
      let odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === odooTenantId);
      if (isDefined(odooTenant)) {
        setSelectedOdooTenant(odooTenant);
      }

      let onPremise =
        attributes.find(
          (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE
        )?.value ?? false;
      setIsOnPremiseTenant(onPremise);
    }
  }, []);

  const handleTbLogin = (username: string, password: string) => {
    const encodedPass = encodeURIComponent(password);
    const encodedUsername = encodeURIComponent(username);
    window.open(`${managerUrl}/login?username=${encodedUsername}&password=${encodedPass}`, '_blank');
  };

  const handleCloseDialog = () => {
    setSelectedTenantStatus(null);
    setTenantMasterState(null);
    handleClose();
  };

  const handleUserBlockChange = (event: Event) => {
    const checked = event.target.checked;
    setUserBlocked(checked);

    if (!checked) {
      setTenantMasterState((oldData) => ({
        ...oldData,
        endPaymentDate: DateTime.now()
      }));
    }
  };

  const handleDateChange = (newValue: DateTime) => {
    setTenantMasterState((oldData) => ({
      ...oldData,
      endPaymentDate: newValue
    }));
  };

  const handleTextFieldChanges = (event: Event, field: string) => {
    setTenantMasterState((oldData) => ({ ...oldData, [field]: event.target.value }));
  };

  const handleNoteChange = (event: Event) => {
    if (isDefined(selectedOdooTenant)) {
      setSelectedOdooTenant((oldData) => ({ ...oldData, description: event.target.value }));
    }
  };

  const handleOdooTenantChange = (value: OdooTenant) => {
    let odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === value?.id);
    setSelectedOdooTenant(odooTenant);
  };

  const handleOnPremiseChange = (event: Event) => {
    const checked = event.target.checked;
    setIsOnPremiseTenant(checked);
  };

  const updateTenantDetails = () => {
    // NOTE: To manage if the user is blocked or not (blocked: reset the values to 1 and 0 respectively)
    const updateTenantMaster = {
      ...tenantMasterState,
      endPaymentDate: userBlocked ? 1 : tenantMasterState?.endPaymentDate?.toMillis(),
      daysBeforeWarning: userBlocked
        ? 0
        : isNotEmpty(tenantMasterState?.daysBeforeWarning)
        ? parseInt(tenantMasterState.daysBeforeWarning, 10)
        : undefined
    };

    validateUpdateTenantForm(updateTenantMaster)
      .then(() => {
        setFieldErrors([]);
        updateTenant(dispatch, updateTenantMaster)
          .then(handleTenantAttributeUpdates)
          .then(() => {
            if (isDefined(selectedOdooTenant)) {
              updateOdooTenantNote(selectedOdooTenant, dispatch);
            }
          })
          .then(() => {
            let alert = {
              id: uuid(),
              severity: 'success',
              title: intl.formatMessage({ id: 'app.common.success' }),
              message: intl.formatMessage({ id: 'app.datagrid.tenant.dialog.tenant.update.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(handleCloseDialog)
          .then(fetchTenantsWithTelemetries)
          .then(fetchTenants)
          .then(fetchOdooTenants)
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };
  const handleTenantAttributeUpdates = () => {
    const odooTenantBody = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT]: selectedOdooTenant?.id ?? ''
    });
    const odooTenantUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: odooTenantBody
    };

    const tenantStatusBody = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.TENANT_STATUS]: selectedTenantStatus ?? ''
    });
    const tenantStatusUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: tenantStatusBody
    };

    const onPremiseBody = JSON.stringify({
      [MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE]: isOnPremiseTenant
    });
    const onPremiseUpdateObject = {
      entityId: tenantInfo?.assetDto?.id?.id,
      entityType: tenantInfo?.assetDto?.id?.entityType,
      body: onPremiseBody
    };

    updateTelemetryAttribute(dispatch, odooTenantUpdateObject);
    updateTelemetryAttribute(dispatch, tenantStatusUpdateObject);
    updateTelemetryAttribute(dispatch, onPremiseUpdateObject);
  };

  const handleTenantStatusChange = (value) => {
    setSelectedTenantStatus(value);
  };

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    const alert = {
      id: uuid(),
      severity: 'info',
      message: intl.formatMessage({ id: 'app.alert.info.copiedToClipboard' })
    };
    dispatch(addAlert(alert));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          minWidth: '60vw',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 500, borderBottom: `1px solid ${grey[300]}` }}>
        {intl.formatMessage({ id: 'app.datagrid.tenant.dialog.title' }, { tenant: tenantMaster?.name })}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            pt: 2,
            gap: 2
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1">
              {intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess' })}
            </Typography>
            <Box sx={{ ml: 2, display: 'flex', flexWrap: 'wrap', gap: 2, mt: 1 }}>
              <Autocomplete
                id="odooTenants"
                options={odooTenants}
                value={selectedOdooTenant ?? null}
                getOptionLabel={(option) => option?.name ?? ''}
                renderOption={(props, option, index) => {
                  const key = `odooTenant-${index}-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option?.name}
                    </li>
                  );
                }}
                onChange={(event, value) => handleOdooTenantChange(value)}
                disabled={!isAdminOrSupport}
                sx={{ width: '40%' }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={intl.formatMessage({ id: 'app.datagrid.subscriptions.odooLink.dialog.label' })}
                  />
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%'
                }}
              >
                <TextField
                  id="tenantAdminUsername"
                  variant="standard"
                  margin="normal"
                  size="small"
                  sx={{ mt: 0, mb: 0, width: '40%' }}
                  InputProps={{
                    endAdornment: isNotEmpty(tenantMasterState?.tenantAdminUsername) ? (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.tenantAdminUsername)}>
                          <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                  inputProps={{ readOnly: !isAdminOrSupport }}
                  required={isAdminOrSupport}
                  label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.tenant.username' })}
                  value={tenantMasterState?.tenantAdminUsername}
                  onChange={(event: Event) =>
                    handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_ADMIN_USERNAME)
                  }
                  error={hasFieldError('tenantAdminUsername', fieldErrors)}
                  helperText={getFieldErrorMessage(intl, 'tenantAdminUsername', fieldErrors)}
                />
                {isAdminOrSupport && (
                  <>
                    <TextField
                      id="tenantUserPassword"
                      variant="standard"
                      margin="normal"
                      size="small"
                      sx={{ mt: 0, mb: 0, width: '40%' }}
                      InputProps={{
                        endAdornment: isNotEmpty(tenantMasterState?.tenantUserPassword) ? (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.tenantUserPassword)}>
                              <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                            </IconButton>
                          </InputAdornment>
                        ) : null
                      }}
                      required
                      label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.tenant.password' })}
                      value={tenantMasterState?.tenantUserPassword}
                      onChange={(event: Event) =>
                        handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.TENANT_USER_PASSWORD)
                      }
                      error={hasFieldError('tenantUserPassword', fieldErrors)}
                      helperText={getFieldErrorMessage(intl, 'tenantUserPassword', fieldErrors)}
                    />
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                          handleTbLogin(tenantMasterState?.tenantAdminUsername, tenantMasterState?.tenantUserPassword)
                        }
                        endIcon={<OpenInNewIcon />}
                        color="warning"
                      >
                        {intl.formatMessage({ id: 'app.datagrid.tenant.dialog.loginTB' })}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%'
                }}
              >
                <TextField
                  id="customerUserUsername"
                  variant="standard"
                  margin="normal"
                  size="small"
                  sx={{ mt: 0, mb: 0, width: '40%' }}
                  InputProps={{
                    endAdornment: isNotEmpty(tenantMasterState?.customerUserUsername) ? (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.customerUserUsername)}>
                          <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                  inputProps={{ readOnly: !isAdminOrSupport }}
                  required={isAdminOrSupport}
                  label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.userAccess.customer.username' })}
                  value={tenantMasterState?.customerUserUsername}
                  onChange={(event: Event) =>
                    handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_USERNAME)
                  }
                  error={hasFieldError('customerUserUsername', fieldErrors)}
                  helperText={getFieldErrorMessage(intl, 'customerUserUsername', fieldErrors)}
                />
                {isAdminOrSupport && (
                  <>
                    <TextField
                      id="customerUserPassword"
                      variant="standard"
                      margin="normal"
                      size="small"
                      sx={{ mt: 0, mb: 0, width: '40%' }}
                      InputProps={{
                        endAdornment: isNotEmpty(tenantMasterState?.customerUserPassword) ? (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.customerUserPassword)}>
                              <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                            </IconButton>
                          </InputAdornment>
                        ) : null
                      }}
                      required
                      label={intl.formatMessage({
                        id: 'app.datagrid.tenant.dialog.userAccess.customer.password'
                      })}
                      value={tenantMasterState?.customerUserPassword}
                      onChange={(event: Event) =>
                        handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.CUSTOMER_USER_PASSWORD)
                      }
                      error={hasFieldError('customerUserPassword', fieldErrors)}
                      helperText={getFieldErrorMessage(intl, 'customerUserPassword', fieldErrors)}
                    />
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                          handleTbLogin(
                            tenantMasterState?.customerUserUsername,
                            tenantMasterState?.customerUserPassword
                          )
                        }
                        endIcon={<OpenInNewIcon />}
                        color="warning"
                      >
                        {intl.formatMessage({ id: 'app.datagrid.tenant.dialog.loginTB' })}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%'
                }}
              >
                <Autocomplete
                  id="tenantStatus"
                  options={Object.values(TENANT_STATUS_ENUM)}
                  value={selectedTenantStatus ?? null}
                  getOptionLabel={(option) =>
                    isNotEmpty(option) ? intl.formatMessage({ id: TenantStatusTranslationId[option] }) : ''
                  }
                  renderOption={(props, option) => {
                    const key = `tenantStatus-${option}`;
                    return (
                      <li {...props} key={key}>
                        {intl.formatMessage({ id: TenantStatusTranslationId[option] })}
                      </li>
                    );
                  }}
                  onChange={(event, value) => handleTenantStatusChange(value)}
                  disabled={!isAdminOrSupport}
                  sx={{ width: '30%' }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.select.status' })}
                    />
                  )}
                />
                <TextField
                  id="description"
                  variant="standard"
                  size="small"
                  margin="normal"
                  fullWidth
                  sx={{ mt: 0, mb: 0, width: '65%' }}
                  label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.description' })}
                  value={tenantMasterState?.description}
                  inputProps={{ readOnly: !isAdminOrSupport }}
                  onChange={(event: Event) =>
                    handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.DESCRIPTION)
                  }
                  InputProps={{
                    endAdornment: isNotEmpty(tenantMasterState?.description) ? (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.description)}>
                          <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                  error={hasFieldError('description', fieldErrors)}
                  helperText={getFieldErrorMessage(intl, 'description', fieldErrors)}
                />
              </Box>
              <TextField
                id="note"
                variant="standard"
                size="small"
                fullWidth
                multiline
                rows={4}
                inputProps={{ readOnly: !isAdminOrSupport || !isDefined(selectedOdooTenant), maxLength: 1000 }}
                label={intl.formatMessage({ id: 'app.datagrid.subscription.note' })}
                type={'text'}
                disabled={!isDefined(selectedOdooTenant)}
                value={
                  isDefined(selectedOdooTenant?.description) && selectedOdooTenant?.description !== 'false'
                    ? selectedOdooTenant?.description
                    : ''
                }
                onChange={handleNoteChange}
                InputProps={{
                  endAdornment: isNotEmpty(selectedOdooTenant?.description) ? (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => handleCopy(selectedOdooTenant?.description)}>
                        <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }}
              />
              {!isDefined(selectedOdooTenant) && (
                <Typography fontSize="x-small" sx={{ mb: 1, textAlign: 'center', pt: 1, color: grey[500] }}>
                  {intl.formatMessage({ id: 'app.datagrid.subscription.note.update.disabled' })}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1">
              {intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription' })}
            </Typography>
            <Box
              sx={{ ml: 2, display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', gap: 2, mt: 1, width: '100%' }}
            >
              <FormControlLabel
                label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription.blockUser' })}
                control={
                  <Checkbox
                    size="small"
                    checked={userBlocked}
                    onChange={handleUserBlockChange}
                    disabled={!isAdminOrSupport}
                  />
                }
              />
              <DateTimePicker
                id="endPaymentDate"
                label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription.end' })}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock
                }}
                readOnly={!isAdminOrSupport}
                value={tenantMasterState?.endPaymentDate}
                onChange={(value: DateTime) => handleDateChange(value)}
                slotProps={{
                  textField: {
                    size: 'small',
                    variant: 'standard',
                    error: hasFieldError('endPaymentDate', fieldErrors),
                    helperText: getFieldErrorMessage(intl, 'endPaymentDate', fieldErrors),
                    required: isAdminOrSupport
                  }
                }}
              />
              <TextField
                type="number"
                id="daysBeforeWarning"
                variant="standard"
                margin="normal"
                size="small"
                sx={{ mt: 0, mb: 0, width: '20%' }}
                InputProps={{
                  endAdornment: isNotEmpty(tenantMasterState?.daysBeforeWarning) ? (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => handleCopy(tenantMasterState?.daysBeforeWarning)}>
                        <ContentCopyOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }}
                inputProps={{ readOnly: !isAdminOrSupport }}
                required={isAdminOrSupport}
                label={intl.formatMessage({ id: 'app.datagrid.tenant.dialog.subscription.warningDays' })}
                value={tenantMasterState?.daysBeforeWarning}
                onChange={(event) =>
                  handleTextFieldChanges(event, TENANT_MASTER_DETAILS_FIELDS_ENUM.DAYS_BEFORE_WARNING)
                }
                error={hasFieldError('daysBeforeWarning', fieldErrors)}
                helperText={getFieldErrorMessage(intl, 'daysBeforeWarning', fieldErrors)}
              />
              <FormControlLabel
                label={intl.formatMessage({ id: 'app.datagrid.subscriptions.systemType.onPremise' })}
                sx={{ mt: 0, mb: 0, ml: 0.5 }}
                control={<Checkbox size="small" checked={isOnPremiseTenant} onChange={handleOnPremiseChange} />}
                disabled={!isAdminOrSupport}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        {isAdminOrSupport && (
          <Button variant="contained" size="small" onClick={updateTenantDetails}>
            {intl.formatMessage({ id: 'app.common.save' })}
          </Button>
        )}
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TenantDetailsDialog;
