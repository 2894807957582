// @flow

import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';

const AppUI = (): React$Node => {
  const intl = useIntl();

  return <Box sx={{ display: 'flex', p: 2 }}>{intl.formatMessage({ id: 'app.service.overview.ui' })}</Box>;
};

export default AppUI;
