// @flow
import { useIntl } from 'react-intl';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationError } from 'yup';
import { useNavigate } from 'react-router';

import { REGISTER_USER, REQUEST_RESET_PASSWORD } from 'routing/RouteConstants';
import { login, validateCredentials } from 'api/service/AuthenticationApiService';
import { addAlert } from 'store/slice/ApplicationSlice';
import { fetchUserDetails } from 'api/service/UserApiService';
import { validateLoginForm } from 'validators/Authentication.validator';
import { getFieldErrorMessage, hasFieldError } from 'util/ValidationUtils';
import { getRegistrationEnabledSetting } from 'store/selector/ApplicationSelector';
import { AUTHENTICATION_ACTION } from '../util/CommonUtils';
import { isDefined, isNotEmpty } from '../util/ObjectUtils';

const Login = (): React$Node => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const registrationEnabledSetting = useSelector(getRegistrationEnabledSetting);

  const handleLoginSubmit = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let formLoginData = {
      username: data.get('username'),
      password: data.get('password'),
      rememberMe: data.get('rememberMe') === 'true'
    };

    validateLoginForm(formLoginData)
      .then(() => {
        setFieldErrors([]);

        validateCredentials(formLoginData.username, formLoginData.password, dispatch).then((response: any) => {
          if (response.message === AUTHENTICATION_ACTION.ALLOW_AUTH_WITH_SMS) {
            setLoginData(formLoginData);
          } else if (response.message === AUTHENTICATION_ACTION.ALLOW_AUTH) {
            login(formLoginData, null, dispatch)
              .then(() => fetchUserDetails(dispatch))
              .then(() => {
                const alert = {
                  id: uuid(),
                  severity: 'success',
                  message: intl.formatMessage({ id: 'app.signin.success' })
                };
                dispatch(addAlert(alert));
              });
          } else {
            console.error('Unsupported authentication action');
          }
        });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const handleVerifySubmit = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const authCode = data.get('authCode');

    login(loginData, authCode, dispatch)
      .then(() => fetchUserDetails(dispatch))
      .then(() => {
        const alert = {
          id: uuid(),
          severity: 'success',
          message: intl.formatMessage({ id: 'app.signin.success' })
        };
        dispatch(addAlert(alert));
      })
      .catch(() => {
        const alert = {
          id: uuid(),
          severity: 'error',
          message: intl.formatMessage({ id: 'app.login.authCode.error' })
        };
        dispatch(addAlert(alert));
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValidCodeVerificationData =
    isDefined(loginData) && isNotEmpty(loginData.username) && isNotEmpty(loginData.password);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {intl.formatMessage({ id: 'app.signin.label' })}
      </Typography>
      {isValidCodeVerificationData ? (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}
          component="form"
          onSubmit={handleVerifySubmit}
          noValidate
        >
          <Typography>{intl.formatMessage({ id: 'app.login.authCode.title' })}</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="authCode"
            label={intl.formatMessage({ id: 'app.login.authCode.label' })}
            name="authCode"
            autoFocus
            type="number"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
            {intl.formatMessage({ id: 'app.common.submit' })}
          </Button>
        </Box>
      ) : (
        <Box component="form" onSubmit={handleLoginSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={intl.formatMessage({ id: 'app.common.username' })}
            name="username"
            autoComplete="username"
            autoFocus
            error={hasFieldError('username', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'username', fieldErrors)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={intl.formatMessage({ id: 'app.common.password' })}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={hasFieldError('password', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'password', fieldErrors)}
          />
          <FormControlLabel
            control={<Checkbox name="rememberMe" value="true" color="primary" />}
            label={intl.formatMessage({ id: 'app.signin.rememberMe' })}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {intl.formatMessage({ id: 'app.signin.label' })}
          </Button>
          <Grid container>
            <Grid item xs sx={{ display: 'flex' }}>
              <Button variant="text" onClick={() => navigate(REQUEST_RESET_PASSWORD)}>
                {intl.formatMessage({ id: 'app.signin.forgotPassword' })}
              </Button>
              {registrationEnabledSetting && (
                <Button
                  variant="text"
                  onClick={() => navigate(REGISTER_USER)}
                  sx={{
                    justifySelf: 'flex-end',
                    marginLeft: 'auto'
                  }}
                >
                  {intl.formatMessage({ id: 'app.signin.signUp' })}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Login;
