// @flow

import { Dispatch } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get, post } from 'api/RestApi';
import {
  CHARGEBEE_CUSTOMERS,
  CHARGEBEE_INVOICES,
  CHARGEBEE_SUBSCRIPTION,
  CHARGEBEE_SUBSCRIPTIONS,
  CHARGEBEE_UNBILLED_CHARGES
} from 'constants/RestApiConstants';
import { handleError } from 'api/RestApiHelper';
import { CbCreateSubscription } from 'types/ChargeBee.types';

export async function getChargeBeeCustomers(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(CHARGEBEE_CUSTOMERS)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getChargeBeeSubscriptions(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(CHARGEBEE_SUBSCRIPTIONS)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getChargeBeeInvoices(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(CHARGEBEE_INVOICES)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function getChargeBeeUnbilledCharges(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(CHARGEBEE_UNBILLED_CHARGES)
    .catch((error) => console.log(error))
    .finally(() => dispatch(stopLoading()));
}

export async function createSubscription(dispatch: Dispatch, createSubscription: CbCreateSubscription): Promise {
  dispatch(startLoading());
  return post(CHARGEBEE_SUBSCRIPTION, createSubscription)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
