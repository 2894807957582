// @flow
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { storeSelectedThemeMode } from 'api/service/LocalStorageService';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode } from 'store/slice/ApplicationSlice';
import { getThemeMode } from 'store/selector/ApplicationSelector';
import { isNotEmpty } from 'util/ObjectUtils';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightlightIcon from '@mui/icons-material/Nightlight';

export default function ThemeMode(props: any): React$Node {
  const themeMode = useSelector(getThemeMode);
  const dispatch = useDispatch();

  const handleChange = (event: Event, selectedValue: string) => {
    if (isNotEmpty(selectedValue)) {
      storeSelectedThemeMode(selectedValue);
      dispatch(setThemeMode(selectedValue));
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={themeMode}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      orientation="vertical"
      {...props}
    >
      <ToggleButton value="light">
        <WbSunnyIcon />
      </ToggleButton>
      <ToggleButton value="dark">
        <NightlightIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
