// @flow

import { Dispatch } from '@reduxjs/toolkit';
import type { TbEntityType } from 'types/Thingsboard.types';
import { TbUpdatedTelemetry } from 'types/Thingsboard.types';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get, post } from 'api/RestApi';
import { TB_MANAGER_TELEMETRY_ATTRIBUTE_UPDATE, TB_MANAGER_TELEMETRY_ENTITY } from 'constants/RestApiConstants';
import { handleError } from 'api/RestApiHelper';

export async function getTelemetriesForEntity(
  dispatch: Dispatch,
  entityType: TbEntityType,
  entityId: string,
  startTs: number,
  endTs: number,
  keys: Array<string>
): Promise {
  dispatch(startLoading());
  return get(TB_MANAGER_TELEMETRY_ENTITY, { entityType, entityId, startTs, endTs, keys })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function updateTelemetryAttribute(dispatch: Dispatch, updatedTelemetry: TbUpdatedTelemetry): Promise {
  dispatch(startLoading());
  return post(TB_MANAGER_TELEMETRY_ATTRIBUTE_UPDATE, updatedTelemetry)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
