// @flow
import Graph from 'graphology';
import { ControlsContainer, FullScreenControl, SearchControl, SigmaContainer, ZoomControl } from '@react-sigma/core';
import '@react-sigma/core/lib/react-sigma.min.css';
import { isDefined } from 'util/ObjectUtils';
import { useEffect, useState } from 'react';
import NetworkGraphAutoForceAtlas from './NetworkGraphAutoForceAtlas';
import { LayoutForceAtlas2Control } from '@react-sigma/layout-forceatlas2';

export const DEFAULT_GRAPH_DATA = {
  attributes: {},
  nodes: [],
  edges: [],
  options: {
    allowSelfLoops: true,
    multi: true,
    type: 'undirected'
  }
};

type Props = {
  graphData: any,
  manualLayoutSetup?: boolean // If true a button to start/stop layout setup will be shown.Otherwise its done by default
};

const NetworkNodeGraph = ({ graphData = DEFAULT_GRAPH_DATA, manualLayoutSetup = true }: Props): React$Node => {
  const [graph, setGraph] = useState();

  useEffect((): Function => {
    setGraph(Graph.from(graphData));
    return () => {
      graph?.clear();
      graph?.clearEdges();
    };
  }, [graphData]);

  return isDefined(graph) ? (
    <SigmaContainer
      id={Math.random()}
      graph={Graph.from(graphData)}
      style={{ height: '75vh' }}
      settings={{
        labelDensity: 0.07,
        labelGridCellSize: 60,
        labelRenderedSizeThreshold: 15,
        zIndex: true
      }}
    >
      <ControlsContainer position={'bottom-right'}>
        <ZoomControl />
        <FullScreenControl />
        {manualLayoutSetup ? <LayoutForceAtlas2Control barnesHutOptimize={true} /> : <NetworkGraphAutoForceAtlas />}
      </ControlsContainer>
      <ControlsContainer position={'top-right'}>
        <SearchControl style={{ width: '200px' }} />
      </ControlsContainer>
    </SigmaContainer>
  ) : (
    <div>No Graph Data</div>
  );
};

export default NetworkNodeGraph;
