// @flow

import PageContainer from 'pages/common/PageContainer';
import PageTitle from 'components/PageTitle';
import ManagerDataGrid from 'components/datagrid/manager/ManagerDataGrid';
import { APPLICATION_PAGE } from 'constants/GlobalConstants';

const ManagerSubscriptions = (): React$Node => {
  return (
    <PageContainer>
      <PageTitle titleKey={'app.page.title.manager.subscriptions'} />
      <ManagerDataGrid dataGridPage={APPLICATION_PAGE.MANAGER_SUBSCRIPTIONS} />
    </PageContainer>
  );
};

export default ManagerSubscriptions;
