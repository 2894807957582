// @flow

import Dialog from '@mui/material/Dialog';
import { grey } from '@mui/material/colors';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { TbTenantMaster } from 'types/Thingsboard.types';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { isDefined, isEmpty, isNotEmpty } from 'util/ObjectUtils';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { updateTenant } from 'api/service/ThingsboardApiService';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { validateShutoutDateUpdateForm } from 'validators/Tenant.validator';
import { ValidationError } from 'yup';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';

type Props = {
  open: boolean,
  handleClose: Function,
  tenantMaster: TbTenantMaster,
  fetchTenants: Function
};

const ShutOutDateDialog = ({ open, handleClose, tenantMaster, fetchTenants }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [shutOutDate, setShutOutDate] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);

  useEffect(() => {
    const shutoutDateValue = tenantMaster?.endPaymentDate;
    if (isNotEmpty(shutoutDateValue)) {
      setShutOutDate(DateTime.fromMillis(shutoutDateValue));
    }
  }, [tenantMaster]);

  const handleDateChange = (newValue: DateTime) => {
    setShutOutDate(newValue);
  };

  const handleCloseDialog = () => {
    setShutOutDate(null);
    handleClose();
  };

  const updateShutoutDate = () => {
    const updateTenantMaster = {
      ...tenantMaster,
      endPaymentDate: shutOutDate?.toMillis()
    };

    validateShutoutDateUpdateForm(updateTenantMaster)
      .then(() => {
        setFieldErrors([]);
        updateTenant(dispatch, updateTenantMaster)
          .then(() => {
            let alert = {
              id: uuid(),
              severity: 'success',
              title: intl.formatMessage({ id: 'app.common.success' }),
              message: intl.formatMessage({ id: 'app.datagrid.subscriptions.shutoutDate.dialog.success' })
            };
            dispatch(addAlert(alert));
          })
          .then(handleCloseDialog)
          .then(fetchTenants)
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const isSaveDisabled = isEmpty(shutOutDate) || isDefined(shutOutDate?.invalid);
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: '500px',
          p: 1
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ fontWeight: 400, borderBottom: `1px solid ${grey[300]}`, p: 1 }}>
        {intl.formatMessage({ id: 'app.datagrid.subscriptions.shutoutDate.dialog' })}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pt: 2 }}>
          <DateTimePicker
            id="shutOutDate"
            label={intl.formatMessage({ id: 'app.datagrid.subscriptions.shutoutDate.dialog' })}
            value={shutOutDate}
            onChange={handleDateChange}
            sx={{ width: '100%' }}
            textField={(params) => (
              <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} fullWidth size="small" />
            )}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock
            }}
            slotProps={{
              textField: {
                size: 'small',
                error: hasFieldError('endPaymentDate', fieldErrors),
                helperText: getFieldErrorMessage(intl, 'endPaymentDate', fieldErrors),
                required: isAdminOrSupport
              },
              field: { clearable: true }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 1, pb: 0.5, pt: 1, borderTop: `1px solid ${grey[300]}` }}>
        <Button variant="contained" size="small" onClick={updateShutoutDate} disabled={isSaveDisabled}>
          {intl.formatMessage({ id: 'app.common.save' })}
        </Button>
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: grey[500],
            color: '#fff',
            ':hover': {
              backgroundColor: grey[700]
            }
          }}
        >
          {intl.formatMessage({ id: 'app.common.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShutOutDateDialog;
