// @flow
import { Tab, Tabs } from '@mui/material';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/DeviceConstants';
import { FarmerDeviceTabNavigationTranslationId } from 'mappers/translationMappers';
import { GRAIN_ANALYZERS, TB_FARMER_DEVICE_TYPE_VALUE_ENUM } from 'constants/ThingsboardConstants';

const TabIndexDeviceTypeMap = {
  0: [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR],
  1: GRAIN_ANALYZERS
};

type Props = {
  fetchDevices: Function
};

const FarmerAppDevicesTabNavigation = ({ fetchDevices }: Props): React$Node => {
  const intl = useIntl();
  const [tabIndex, setTabindex] = useState(0);

  useEffect(() => {
    fetchDevices(TabIndexDeviceTypeMap[tabIndex]);
  }, [tabIndex]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabindex(newValue);
  };

  const deviceTypes = Object.values(FARMER_DEVICE_TAB_NAVIGATION_ENUM);

  return (
    <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
      {deviceTypes?.map((type) => {
        const label = intl.formatMessage({ id: FarmerDeviceTabNavigationTranslationId[type] });
        return <Tab label={label} key={type} />;
      })}
    </Tabs>
  );
};

export default FarmerAppDevicesTabNavigation;
