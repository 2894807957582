// @flow

import { Divider, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import { WORLD_MAP_LEGEND_ELEMENTS } from 'constants/GlobalConstants';
import { WorldMapElementsTranslationId } from 'mappers/translationMappers';
import { WorldMapElementsColorMap } from 'mappers/colorMappers';
import { useTheme } from '@mui/styles';

const MapLegend = (): React$Node => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 1,
        pb: 0,
        pt: 0.5,
        maxHeight: theme.spacing(9)
      }}
    >
      <Typography variant="subtitle1">{intl.formatMessage({ id: 'app.general.overview.legend' })}</Typography>
      <Divider sx={{ borderColor: grey[300] }} />
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', pt: 1, pb: 1 }}>
        {WORLD_MAP_LEGEND_ELEMENTS?.map((element) => (
          <Box sx={{ display: 'flex', mr: 1.5, alignItems: 'center' }} key={element}>
            <Box
              component="span"
              sx={{ backgroundColor: WorldMapElementsColorMap[element], width: 10, height: 10, borderRadius: '50%' }}
            />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {intl.formatMessage({ id: WorldMapElementsTranslationId[element] })}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default MapLegend;
