// @flow
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { GENERAL_OVERVIEW, LOGIN } from 'routing/RouteConstants';
import { isUserAuthenticated } from 'store/selector/UserSelector';

const PageNotFound = (): React$Node => {
  const authenticated = useSelector(isUserAuthenticated);

  let navigateTo = LOGIN;
  if (authenticated) {
    navigateTo = GENERAL_OVERVIEW;
  }

  console.debug('Route not found! Redirecting to fallback');

  return <Navigate to={navigateTo} replace />;
};

export default PageNotFound;
