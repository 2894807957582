// @flow
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { ValidationError } from 'yup';
import { IconButton, InputAdornment, Paper, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { resetPassword, verifyToken } from 'api/service/AuthenticationApiService';
import { addAlert } from 'store/slice/ApplicationSlice';
import { validateResetPasswordForm } from 'validators/Authentication.validator';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { isNotEmpty } from 'util/ObjectUtils';
import { LOGIN } from 'routing/RouteConstants';
import { VALIDATION_TOKEN_ENUM } from 'constants/UserConstants';

const ResetPassword = (): React$Node => {
  let { token } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [verifySuccess, setVerifySuccess] = useState(null);

  useEffect(() => {
    if (isNotEmpty(token)) {
      verifyToken(token, VALIDATION_TOKEN_ENUM.PASSWORD_RESET, dispatch)
        .then(() => setVerifySuccess(true))
        .catch(() => setVerifySuccess(false));
    }
  }, [token, dispatch]);

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let resetData = {
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword')
    };

    validateResetPasswordForm(resetData)
      .then(() => {
        setFieldErrors([]);
        resetPassword(
          {
            token,
            password: resetData.password
          },
          dispatch
        )
          .then(() => {
            const alert = {
              id: uuid(),
              severity: 'success',
              message: intl.formatMessage({ id: 'app.resetPassword.success.message' })
            };
            dispatch(addAlert(alert));
          })
          .then(() => navigate(LOGIN))
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {intl.formatMessage({ id: 'app.resetPassword.label' })}
      </Typography>
      {!verifySuccess ? (
        <Paper elevation={1} sx={{ m: 2, p: 5 }}>
          <Box
            sx={{
              color: 'error.light',
              fontSize: 'h3.fontSize',
              fontWeight: 'light',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <ErrorOutlineTwoToneIcon sx={{ fontSize: 50 }} />
              {intl.formatMessage({ id: 'app.common.error' })}
            </Stack>
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 'h8.fontSize', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'app.resetPassword.verify.error' })}
          </Box>
        </Paper>
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            name="password"
            label={intl.formatMessage({ id: 'app.common.password' })}
            type={showPassword ? 'text' : 'password'}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={hasFieldError('password', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'password', fieldErrors)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label={intl.formatMessage({ id: 'app.common.confirmPassword' })}
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={hasFieldError('confirmPassword', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'confirmPassword', fieldErrors)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {intl.formatMessage({ id: 'app.resetPassword.label' })}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ResetPassword;
