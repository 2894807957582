// @flow
import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { red } from '@mui/material/colors';
import { ValidationError } from 'yup';
import { useDispatch } from 'react-redux';

import { validateEmail } from 'validators/User.validator';
import { getFieldErrorMessage, hasFieldError, resolveBackendValidationErrors } from 'util/ValidationUtils';
import { isEmpty, isEmptyList } from 'util/ObjectUtils';
import { inviteUsers, validateUserEmail } from 'api/service/AuthenticationApiService';

const Transition = React.forwardRef(function Transition(props: any, ref: any): React$Node {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  handleClose: Function
};

export default function InviteUsersDialog({ open, handleClose }: Props): React$Node {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [fieldErrors, setFieldErrors] = useState([]);
  const [emails, setEmails] = React.useState([]);
  const [email, setEmail] = React.useState('');

  const handleEmailChange = (event: Event) => {
    setEmail(event.target.value);
  };

  const handleAddEmail = () => {
    const userEmail = { emails: emails, email: email };
    validateEmail(userEmail)
      .then(() => {
        setFieldErrors([]);
        validateUserEmail(email, dispatch)
          .then(() => {
            setEmails((prevState) => [...prevState, email]);
            setEmail('');
          })
          .catch((error: any) => {
            setFieldErrors(resolveBackendValidationErrors(error));
          });
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  const handleDeleteEmail = (value: string) => {
    const filteredEmails = emails.filter((userEmail) => userEmail !== value);
    setEmails(filteredEmails);
  };

  const handleInviteUsers = () => {
    inviteUsers(emails, dispatch).then(setEmails([])).then(handleClose);
  };

  const isAddingDisabled = isEmpty(email);
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {intl.formatMessage({ id: 'app.manageUsers.inviteUsers.dialog.title' })}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            mt: 4,
            width: '100%',
            maxWidth: '450px'
          }}
        >
          <Grid item sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <TextField
              onKeyDown={(event) => (event.key === 'Enter' ? handleAddEmail() : null)}
              size="small"
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'app.manageUsers.inviteUsers.dialog.placeholder' })}
              value={email}
              onChange={handleEmailChange}
              error={hasFieldError('email', fieldErrors)}
              helperText={getFieldErrorMessage(intl, 'email', fieldErrors)}
              sx={{ width: '100%' }}
            />
            <IconButton
              onClick={handleAddEmail}
              disabled={isAddingDisabled}
              sx={{ display: 'flex', alignSelf: 'baseline', ml: 1 }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          {emails.map((userEmail: string) => (
            <Grid item key={userEmail} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>{userEmail}</Typography>
              <IconButton onClick={() => handleDeleteEmail(userEmail)} sx={{ marginLeft: 'auto' }}>
                <DeleteOutlineOutlinedIcon sx={{ color: red[500] }} />
              </IconButton>
            </Grid>
          ))}
          <Grid item></Grid>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              onClick={handleInviteUsers}
              disabled={isEmptyList(emails)}
              size={'large'}
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                width: 1 / 2
              }}
            >
              {intl.formatMessage({ id: 'app.manageUsers.inviteUsers.invite.label' })}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
}
