// @flow
import { isDefined } from 'util/ObjectUtils';
import { DateTime } from 'luxon';

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_YEAR = 525600;
const MINUTES_IN_MONTH = 43800;
const MINUTES_IN_DAY = 1440;
const MINUTES_IN_HOUR = 60;

/**
 * Converts milliseconds into time spent in years, months, days.
 * @param milliseconds total number of milliseconds.
 */
export const formatTimeSpent = (milliseconds: number): string => {
  if (isDefined(milliseconds) && milliseconds >= 0) {
    let seconds = Math.floor(milliseconds / 1000);
    let leftMinutes = Math.floor(seconds / SECONDS_IN_MINUTE);

    let years = Math.floor(leftMinutes / MINUTES_IN_YEAR);
    leftMinutes = leftMinutes - MINUTES_IN_YEAR * years;

    let months = Math.floor(leftMinutes / MINUTES_IN_MONTH);
    leftMinutes = leftMinutes - MINUTES_IN_MONTH * months;

    let days = Math.floor(leftMinutes / MINUTES_IN_DAY);
    leftMinutes = leftMinutes - MINUTES_IN_DAY * days;

    let hours = Math.floor(leftMinutes / MINUTES_IN_HOUR);
    leftMinutes = leftMinutes - MINUTES_IN_HOUR * hours;

    const yearLabel = years > 0 ? `${years}y` : '';
    const monthLabel = months > 0 ? `${months}m` : '';
    const dayLabel = days > 0 ? `${days}d` : '';
    const hourLabel = hours > 0 ? `${hours}h` : '';
    const minutesLabel = leftMinutes > 0 ? `${leftMinutes}min` : '';

    return `${yearLabel} ${monthLabel} ${dayLabel} ${hourLabel} ${minutesLabel}`;
  }
  return '/';
};

export const formatTimeSpentInDays = (milliseconds: number): string => {
  const days = milliseconds / (1000 * 3600 * 24);
  return Math.round(days);
};

export const getRelativeTime = (ts: number): string => {
  const resolvedDateTime = DateTime.fromMillis(ts);
  const relativeTimeUnit = resolveRelativeTimeUnit(resolvedDateTime);
  const timeOptions = isDefined(relativeTimeUnit) ? { unit: relativeTimeUnit } : undefined;
  return resolvedDateTime.toRelativeCalendar(timeOptions);
};

export const resolveRelativeTimeUnit = (date: DateTime): string => {
  const now = DateTime.local().toUTC();
  const hourDifference = now.diff(date, 'hours').toObject(); //=> { hours: 12168.75 }

  if (hourDifference.hours < 1) {
    return 'minutes';
  } else if (hourDifference.hours < 24) {
    return 'hours';
  } else if (hourDifference.hours < 168) {
    // less than 1 week
    return 'days';
  } else {
    return null;
  }
};

export const getDateTimeString = (ts: number): string => {
  if (isDefined(ts)) {
    return DateTime.fromMillis(ts).toLocaleString({
      day: '2-digit',
      month: 'short',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  } else {
    return null;
  }
};

export const getDetailedRelativeTime = (ts: number): string => {
  let latestSampleDateTime = DateTime.fromMillis(ts);
  let currentDateTime = DateTime.now();
  let duration = currentDateTime.diff(latestSampleDateTime).toObject().milliseconds;
  return formatTimeSpent(duration);
};
