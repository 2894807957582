import { configureStore } from '@reduxjs/toolkit';
import ApplicationReducer from 'store/slice/ApplicationSlice';
import UserReducer from 'store/slice/UserSlice';

export const store = configureStore({
  reducer: {
    application: ApplicationReducer,
    user: UserReducer
  }
});
