// @flow

import type { HealthStatus } from 'types/Global.types';
import { green, grey, red } from '@mui/material/colors';
import { Card, CardContent, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { isDefined } from 'util/ObjectUtils';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { TelemetryHealthStatusTranslationId } from 'mappers/translationMappers';

type Props = {
  healthStatus: HealthStatus
};

const ServiceHealthCard = ({ healthStatus }: Props): React$Node => {
  const intl = useIntl();

  const isTelemetryHealthy = healthStatus?.healthy;
  const checkedAtTime = isDefined(healthStatus?.checkedAt)
    ? DateTime.fromISO(healthStatus?.checkedAt).toLocaleString(DateTime.DATETIME_MED)
    : null;

  return (
    <Card sx={{ minWidth: 300, border: '1px solid', borderColor: isTelemetryHealthy ? green[500] : red[500] }}>
      <CardHeader
        sx={{ p: '12px !important' }}
        avatar={
          <Avatar sx={{ backgroundColor: isTelemetryHealthy ? green[500] : red[500], width: 35, height: 35 }}>
            <HealthAndSafetyOutlinedIcon />
          </Avatar>
        }
      />
      <CardContent sx={{ pb: '8px !important', pt: '8px !important' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ mr: 1, fontSize: '18px', fontWeight: 500 }}>
            {intl.formatMessage({ id: TelemetryHealthStatusTranslationId[isTelemetryHealthy] })}
          </Typography>
          {healthStatus?.healthy ? (
            <CheckCircleOutlinedIcon color="success" fontSize="large" />
          ) : (
            <HighlightOffOutlinedIcon color="error" fontSize="large" />
          )}
        </Box>
        <Typography sx={{ fontSize: '12px', fontWeight: 400, fontStyle: 'italic', mt: 2, color: grey[600] }}>
          {checkedAtTime}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceHealthCard;
