// @flow
import Box from '@mui/material/Box';
import { CardMedia } from '@mui/material';
import AppLogoIcon from 'assets/app_logo.svg';

const AppLogo = (props: any): React$Node => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '200px',
      '&:hover': {
        cursor: 'pointer'
      }
    }}
    {...props}
  >
    <CardMedia
      component="img"
      height="200"
      image={AppLogoIcon}
      alt="Agrolog Logo"
      sx={{ pt: 6, width: 1, height: 'auto' }}
    />
  </Box>
);

export default AppLogo;
