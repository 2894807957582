// @flow
import _get from 'lodash/get';
import _omit from 'lodash/omit';

import {
  createFetchConfig,
  getRequestTimeoutPromise,
  isEmptyResponse,
  isHalResponse,
  isJsonResponse,
  isXmlResponse
} from 'api/RestApiHelper';
import { store } from 'store';
import { getSelectedLocale } from 'store/selector/ApplicationSelector';
import { isDefined } from 'util/ObjectUtils';

export async function get(url: string = '', params: Object = {}): Promise {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${isDefined(params[k]) ? encodeURIComponent(params[k]) : ''}`)
    .join('&');
  const urlWithParams = query === '' ? url : `${url}?${query}`;

  let locale = getSelectedLocale(store.getState());

  return Promise.race([
    fetch(urlWithParams, createFetchConfig('GET', locale)).then((response) => handleResponse(response)),
    getRequestTimeoutPromise()
  ]);
}

export async function put(url: string = '', data: Object = {}): Promise {
  let locale = getSelectedLocale(store.getState());

  return Promise.race([
    fetch(url, createFetchConfig('PUT', locale, data)).then((response) => handleResponse(response)),
    getRequestTimeoutPromise()
  ]);
}

export async function post(url: string = '', data: Object = {}): Promise {
  let locale = getSelectedLocale(store.getState());

  return Promise.race([
    fetch(url, createFetchConfig('POST', locale, data)).then((response) => handleResponse(response)),
    getRequestTimeoutPromise()
  ]);
}

export async function deleteRequest(url: string = '', data: Object = {}): Promise {
  let locale = getSelectedLocale(store.getState());

  return Promise.race([
    fetch(url, createFetchConfig('DELETE', locale, data)).then((response) => handleResponse(response)),
    getRequestTimeoutPromise()
  ]);
}

const handleResponse = (response: Response): Promise => {
  const { status } = response;
  if (!response.redirected) {
    if (isEmptyResponse(response)) {
      if (status >= 200 && status < 300) {
        return;
      }
      throw Error('No response for failed request.');
    }
    if (isJsonResponse(response)) {
      return response.json().then((jsonResponse: Object): Promise => {
        const localJsonResponse = jsonResponse;
        if (status >= 200 && status < 300) {
          return localJsonResponse;
        }

        localJsonResponse.status = status;
        return Promise.reject(localJsonResponse);
      });
    }
    if (isHalResponse(response)) {
      return response.json().then((halResponse: Object): Promise => {
        const localJsonResponse = halResponse;
        if (status >= 200 && status < 300) {
          const links = _get(halResponse, '_links');
          const data = Object.values(_get(halResponse, '_embedded', {}))[0];
          const withOmittedHalData = _omit(halResponse, ['_links', '_embedded']);
          return {
            ...withOmittedHalData,
            data,
            links
          };
        }

        localJsonResponse.status = status;
        return Promise.reject(localJsonResponse);
      });
    }
    if (isXmlResponse(response)) {
      throw Error('XML Responses are not supported!');
    }
    throw Error('Non-JSON responses are not supported!');
  } else {
    throw Error(`Redirection is not supported. The redirect (${response.url}) cannot be handled properly!`);
  }
};
