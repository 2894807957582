// @flow
import { isDefined, isNotEmpty } from 'util/ObjectUtils';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import {
  defaultCustomersDataGridColumnSettings,
  defaultDevicesDataGridColumnSettings,
  defaultGatewayDataGridColumnSettings,
  defaultSubscriptionsDataGridColumnSettings,
  defaultTenantsDataGridColumnSettings
} from 'constants/DataGridConstants';

const SELECTED_THEME_KEY = 'selected-theme-mode';
const WORLD_MAP_FOCUS_DATA = 'world-map-focus-data';
const WORLD_MAP_ELEMENTS_SETTINGS = 'world-map-elements-settings';
export const TENANT_DATAGRID_COLUMN_VISIBILITY_V6 = 'tenant-datagrid-column-visibility_v6';
export const SUBSCRIPTIONS_DATAGRID_COLUMN_VISIBILITY_V6 = 'subscriptions-datagrid-column-visibility_v6';
export const GATEWAY_DATAGRID_COLUMN_VISIBILITY = 'gateway-datagrid-column-visibility';
export const CUSTOMERS_DATAGRID_COLUMN_VISIBILITY = 'customers-datagrid-column-visibility';
export const DEVICES_DATAGRID_COLUMN_VISIBILITY_V3 = 'devices-datagrid-column-visibility_v3';

export const getSelectedThemeMode = (): string => {
  const selectedThemeMode = localStorage.getItem(SELECTED_THEME_KEY);
  return isNotEmpty(selectedThemeMode) ? selectedThemeMode : 'light';
};

export const storeSelectedThemeMode = (selectedMode: string) => localStorage.setItem(SELECTED_THEME_KEY, selectedMode);

const defaultWorldMapFocus = {
  zoomLevel: 2,
  longitude: 10.310726636036026,
  latitude: 55.536284982296145
};

const defaultWorldMapElementsSettings = {
  [WORLD_MAP_ELEMENTS.SITE]: true,
  [WORLD_MAP_ELEMENTS.SENSOR_SPEAR]: true,
  [WORLD_MAP_ELEMENTS.BUILDING]: true
};

export type WorldMapFocus = {
  zoomLevel: number,
  longitude: number,
  latitude: number
};

export const getWorldMapFocusData = (): WorldMapFocus => {
  const value = localStorage.getItem(WORLD_MAP_FOCUS_DATA);
  return isDefined(value) ? JSON.parse(value) : defaultWorldMapFocus;
};

export const storeWorldMapFocusData = (zoomLevel: number, latitude: number, longitude: number) => {
  const newValue = {
    zoomLevel,
    longitude,
    latitude
  };
  localStorage.setItem(WORLD_MAP_FOCUS_DATA, JSON.stringify(newValue));
};

export const storeWorldMapElementsSettings = (worldMapSettings: Object) => {
  localStorage.setItem(WORLD_MAP_ELEMENTS_SETTINGS, JSON.stringify(worldMapSettings));
};

export const getWorldMapElementsSettings = (): Object => {
  const worldMapSettings = localStorage.getItem(WORLD_MAP_ELEMENTS_SETTINGS);
  return isDefined(worldMapSettings) ? JSON.parse(worldMapSettings) : defaultWorldMapElementsSettings;
};

export const storeDataGridColumnVisibility = (key: string, dataGridColumnVisibility: Object) => {
  localStorage.setItem(key, JSON.stringify(dataGridColumnVisibility));
};

export const getDataGridColumnVisibility = (key: string): Object => {
  const dataGridColumnVisibility = localStorage.getItem(key);
  return isDefined(dataGridColumnVisibility)
    ? JSON.parse(dataGridColumnVisibility)
    : getDefaultColumnVisibilitySettings(key);
};

const getDefaultColumnVisibilitySettings = (key: string): Object => {
  if (key === TENANT_DATAGRID_COLUMN_VISIBILITY_V6) {
    return defaultTenantsDataGridColumnSettings;
  } else if (key === GATEWAY_DATAGRID_COLUMN_VISIBILITY) {
    return defaultGatewayDataGridColumnSettings;
  } else if (key === CUSTOMERS_DATAGRID_COLUMN_VISIBILITY) {
    return defaultCustomersDataGridColumnSettings;
  } else if (key === DEVICES_DATAGRID_COLUMN_VISIBILITY_V3) {
    return defaultDevicesDataGridColumnSettings;
  } else if (key === SUBSCRIPTIONS_DATAGRID_COLUMN_VISIBILITY_V6) {
    return defaultSubscriptionsDataGridColumnSettings;
  }

  return null;
};
