// @flow

import { DateTime } from 'luxon';
import type { TelemetryData } from 'types/Thingsboard.types';

const createDefaultOptions = (): any => {
  return {
    chart: {
      type: 'line',
      width: '100%',
      sparkline: {
        enabled: false
      },
      toolbar: {
        show: true,
        tools: {
          pan: false
        }
      },
      selection: {
        enabled: false
      },
      zoom: {
        enabled: true
      }
    },
    colors: [],
    stroke: {
      curve: 'smooth',
      width: []
    },
    fill: {
      opacity: 0.7,
      type: 'solid'
    },
    tooltip: {
      shared: true,
      style: {
        color: 'black'
      },
      y: {
        formatter(value) {
          return value;
        }
      },
      x: {
        formatter(timestamp) {
          let dateTime = DateTime.fromMillis(timestamp);
          return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
        }
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 5,
      labels: {
        useSeriesColors: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      type: 'datetime',
      labels: {
        show: true,
        style: {
          fontSize: '14px'
        },
        offsetX: 3,
        offsetY: 0,
        position: 'bottom',
        rotate: -15,
        formatter: function (val, timestamp) {
          return DateTime.fromMillis(timestamp).toISODate();
        }
      }
    },
    yaxis: {
      show: true,
      min: 0,
      forceNiceScale: true,
      labels: {
        show: true,
        style: {
          fontSize: '12px'
        },
        formatter(value): string {
          return value;
        }
      },
      crosshairs: {
        show: false
      }
    },
    plotOptions: {
      area: {
        fillTo: 'origin'
      }
    },
    grid: {
      show: false
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
        fontWeight: 'regular'
      }
    }
  };
};

export const createAlarmChartConfiguration = (items: Array<TelemetryData>, chartLabel: string) => {
  let defaultOptions = createDefaultOptions();
  const chartSeries = [];
  const data = [];

  items?.forEach((item: TelemetryData) => {
    data.push(item.value);

    const ts = item.ts;
    defaultOptions.xaxis.categories.push(ts);
  });

  chartSeries.push({
    name: chartLabel,
    data: data
  });

  return { options: defaultOptions, series: chartSeries };
};
