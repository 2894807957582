// @flow

import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { checkFarmerTelemetriesStatus } from 'api/service/HealthApiService';
import { useDispatch } from 'react-redux';
import type { HealthStatus } from 'types/Global.types';
import ServiceHealthCard from 'components/serviceOverview/ServiceHealthCard';
import { isDefined } from 'util/ObjectUtils';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

const AppThingsboard = (): React$Node => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [telemetriesHealthStatus, setTelemetriesHealthStatus] = useState(null);

  useEffect(() => {
    checkFarmerTelemetriesStatus(dispatch).then((response: HealthStatus) => setTelemetriesHealthStatus(response));
  }, []);

  return (
    <Box sx={{ display: 'flex', p: 2 }}>
      {isDefined(telemetriesHealthStatus) ? (
        <ServiceHealthCard healthStatus={telemetriesHealthStatus} />
      ) : (
        <Typography variant="subtitle1" sx={{ color: grey[500] }}>
          {intl.formatMessage({ id: 'app.service.thingsboard.healthStatus.noData' })}
        </Typography>
      )}
    </Box>
  );
};

export default AppThingsboard;
