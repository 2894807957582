// @flow

import { Dispatch } from '@reduxjs/toolkit';
import { TbUpdatedTelemetry } from 'types/Thingsboard.types';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { post } from 'api/RestApi';
import { TB_FARMER_TELEMETRY_ATTRIBUTE_UPDATE } from 'constants/RestApiConstants';
import { handleError } from 'api/RestApiHelper';

export async function updateTelemetryAttribute(dispatch: Dispatch, updatedTelemetry: TbUpdatedTelemetry): Promise {
  dispatch(startLoading());
  return post(TB_FARMER_TELEMETRY_ATTRIBUTE_UPDATE, updatedTelemetry)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
