// @flow

import { TbLocationItem } from 'types/Thingsboard.types';
import { isDefined } from 'util/ObjectUtils';
import WorldMap, { MAP_BOX_STYLE } from 'components/worldmap/WorldMap';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import { useEffect, useState } from 'react';
import { getTbLocationData } from '../../api/service/ThingsboardApiService';
import { useDispatch } from 'react-redux';

const PublicWorldMapWrapper = (): React$Node => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [itemsFetched, setItemsFetched] = useState(false);

  useEffect(() => {
    getTbLocationData(dispatch)
      .then((response) => setItems(response ?? []))
      .then(() => setItemsFetched(true));
  }, []);

  const sitesGeoJson = { features: [], type: 'FeatureCollection' };
  const spearsGeoJson = { features: [], type: 'FeatureCollection' };
  const buildingsGeoJson = { features: [], type: 'FeatureCollection' };
  const usersGeoJson = { features: [], type: 'FeatureCollection' };

  items
    .filter((item: TbLocationItem) => isDefined(item.latitude) && isDefined(item.longitude))
    .forEach((item: TbLocationItem) => {
      const locationItem = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude]
        },
        properties: {
          name: item.name
        }
      };

      if (item.type === WORLD_MAP_ELEMENTS.SITE) {
        sitesGeoJson.features.push(locationItem);
      } else if (item.type === WORLD_MAP_ELEMENTS.SENSOR_SPEAR) {
        spearsGeoJson.features.push(locationItem);
      } else if (item.type === WORLD_MAP_ELEMENTS.BUILDING) {
        buildingsGeoJson.features.push(locationItem);
      } else if (item.type === WORLD_MAP_ELEMENTS.USER) {
        usersGeoJson.features.push(locationItem);
      }
    });

  const locationGeoJsonMap = {
    [WORLD_MAP_ELEMENTS.SITE]: sitesGeoJson,
    [WORLD_MAP_ELEMENTS.SENSOR_SPEAR]: spearsGeoJson,
    [WORLD_MAP_ELEMENTS.BUILDING]: buildingsGeoJson,
    [WORLD_MAP_ELEMENTS.USER]: usersGeoJson
  };

  return itemsFetched ? (
    <WorldMap locationGeoJsonMap={locationGeoJsonMap} view={MAP_BOX_STYLE.LIGHT} useStoredPosition={false} planetView />
  ) : null;
};

export default PublicWorldMapWrapper;
