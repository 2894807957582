// @flow

import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { isNotEmpty } from 'util/ObjectUtils';
import { activateUser, verifyToken } from 'api/service/AuthenticationApiService';
import { VALIDATION_TOKEN_ENUM } from 'constants/UserConstants';
import { LOGIN } from 'routing/RouteConstants';

const CompleteRegistration = (): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [verifySuccess, setVerifySuccess] = useState(null);

  useEffect(() => {
    if (isNotEmpty(token)) {
      verifyToken(token, VALIDATION_TOKEN_ENUM.REGISTER_USER, dispatch)
        .then(() => setVerifySuccess(true))
        .catch(() => setVerifySuccess(false));
    }
  }, [token, dispatch]);

  const handleClick = () => {
    activateUser(token, dispatch).then(() => navigate(LOGIN));
  };

  return (
    <>
      {!verifySuccess ? (
        <Paper elevation={1} sx={{ m: 2, p: 5 }}>
          <Box
            sx={{
              color: 'error.light',
              fontSize: 'h3.fontSize',
              fontWeight: 'light',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <ErrorOutlineTwoToneIcon sx={{ fontSize: 50 }} />
              {intl.formatMessage({ id: 'app.common.error' })}
            </Stack>
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 'h8.fontSize', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'app.signUp.verify.error' })}
          </Box>
        </Paper>
      ) : (
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleClick} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {intl.formatMessage({ id: 'app.signUp.success.activate' })}
          </Button>
        </Box>
      )}
    </>
  );
};

export default CompleteRegistration;
