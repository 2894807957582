// @flow
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { grey } from '@mui/material/colors';
import { useIntl } from 'react-intl';
import { Divider, Paper, Popover, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { UserProfile } from 'types/State.types';
import Button from '@mui/material/Button';
import UserProfileDialog from 'components/user/UserProfileDialog';
import { deleteUser, fetchAllUsers, toggleUserActivation } from 'api/service/AdminApiService';
import { isDefined } from 'util/ObjectUtils';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InviteUsersDialog from 'components/user/InviteUsersDialog';
import { getInvitationEnabledSetting } from 'store/selector/ApplicationSelector';
import PageContainer from '../common/PageContainer';

const AdminUsers = (): React$Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userProfileDialogOpen, setUserProfileDialogOpen] = React.useState(false);
  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverConfirm, setPopoverConfirm] = React.useState(null);
  const popoverOpen = Boolean(anchorEl);

  const invitationEnabled = useSelector(getInvitationEnabledSetting);

  const handleRemoveUserClick = (event: Event, userId: number) => {
    setAnchorEl(event.currentTarget);
    setPopoverConfirm(() => () => removeUser(userId));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverConfirm(null);
  };

  const handlePopoverConfirm = () => {
    popoverConfirm();
    handlePopoverClose();
  };

  const handleCloseUserProfile = () => {
    setSelectedUser(null);
    setUserProfileDialogOpen(false);
  };

  const handleOpenUserProfile = (selectedUser: UserProfile) => {
    setSelectedUser(selectedUser);
    setUserProfileDialogOpen(true);
  };

  const handleCloseInviteUsers = () => {
    setInviteUsersDialogOpen(false);
  };

  const handleOpenInviteUsers = () => {
    setInviteUsersDialogOpen(true);
  };

  const getAllUsers = () => {
    fetchAllUsers(dispatch).then((response) => setUsers(response));
  };

  const removeUser = (userId: number) => {
    deleteUser(userId, dispatch).then(getAllUsers);
  };

  const toggleActivation = (user: UserProfile) => {
    toggleUserActivation(user.id, !user.activated, dispatch).then(getAllUsers);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <PageContainer>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Typography component="h1" variant="h5" sx={{ flexGrow: 1 }}>
          {intl.formatMessage({ id: 'app.manageUsers.title' })}
        </Typography>
        <Button variant="contained" onClick={() => handleOpenUserProfile(null)}>
          {intl.formatMessage({ id: 'app.manageUsers.addUser' })}
        </Button>
        {invitationEnabled && (
          <Button variant="contained" onClick={() => handleOpenInviteUsers()}>
            {intl.formatMessage({ id: 'app.manageUsers.inviteUsers' })}
          </Button>
        )}
      </Stack>
      <Divider />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing="3" sx={{ mt: 4, flexWrap: 'wrap', gap: 3 }}>
        {users.map((user: UserProfile) => (
          <Paper key={`user-container-${user.id}`} sx={{ p: 2 }}>
            <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Stack direction="column">
                <Typography component="h6" variant="h6" color="primary">
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography color={grey[500]} fontSize="small">
                  {user.email}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Button
                  key={`activation-btn-${user.id}`}
                  size="small"
                  variant="outlined"
                  onClick={() => toggleActivation(user)}
                  color={user?.activated ? 'success' : 'warning'}
                >
                  {user?.activated ? <LockOpenIcon color="success" /> : <HttpsIcon color="warning" />}
                </Button>
                <Button
                  key={`edit-btn-${user.id}`}
                  sx={{ ml: 2 }}
                  size="small"
                  variant="outlined"
                  onClick={() => handleOpenUserProfile(user)}
                >
                  <EditIcon />
                </Button>
                <Button
                  id={`delete-btn-${user.id}`}
                  key={`delete-btn-${user.id}`}
                  color="error"
                  sx={{ ml: 2 }}
                  size="small"
                  variant="outlined"
                  onClick={(event) => handleRemoveUserClick(event, user.id)}
                >
                  <DeleteIcon />
                </Button>
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
      <UserProfileDialog
        isCreate={!isDefined(selectedUser)}
        isUpdateFromAdmin
        userProfile={selectedUser}
        open={userProfileDialogOpen}
        handleClose={handleCloseUserProfile}
        successCallback={getAllUsers}
      />
      <InviteUsersDialog open={inviteUsersDialogOpen} handleClose={handleCloseInviteUsers} />
      <Popover
        id="confirm-popover"
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ pb: 2 }}>{intl.formatMessage({ id: 'app.common.areYouSure' })}</Typography>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button
              key="confirm-popover-btn"
              color="success"
              size="small"
              variant="outlined"
              onClick={handlePopoverConfirm}
            >
              {intl.formatMessage({ id: 'app.common.confirm' })}
            </Button>
            <Button
              key="cancel-popover-btn"
              sx={{ ml: 2 }}
              size="small"
              variant="outlined"
              onClick={handlePopoverClose}
              color="info"
            >
              {intl.formatMessage({ id: 'app.common.cancel' })}
            </Button>
          </Box>
        </Box>
      </Popover>
    </PageContainer>
  );
};

export default AdminUsers;
