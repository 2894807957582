// @flow
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { IntlShape, useIntl } from 'react-intl';
import { TbAssetItem } from 'types/Thingsboard.types';
import { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isNotEmpty } from 'util/ObjectUtils';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';

const createColumns = (intl: IntlShape, showCustomerId: boolean, showTenantId: boolean): GridColDef[] => {
  const columns = [
    { field: 'itemNo', headerName: '#', width: 60 },
    { field: 'id', headerName: intl.formatMessage({ id: 'app.datagrid.id' }), width: 300 }
  ];

  if (showCustomerId) {
    columns.push({
      field: 'customerId',
      headerName: intl.formatMessage({ id: 'app.datagrid.customerId' }),
      width: 300
    });
  }

  if (showTenantId) {
    columns.push({
      field: 'tenantId',
      headerName: intl.formatMessage({ id: 'app.datagrid.tenant' }),
      width: 300
    });
  }

  columns.push(
    {
      field: 'type',
      headerName: intl.formatMessage({ id: 'app.datagrid.type' }),
      width: 300
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 400
    },
    {
      field: 'lastActivity',
      headerName: intl.formatMessage({ id: 'app.datagrid.lastActivity' }),
      width: 400
    }
  );
  return columns;
};

type Props = {
  items: Array<TbAssetItem>,
  showCustomerId?: boolean,
  showTenantId?: boolean
};

const AssetsDataGrid = ({ items, showCustomerId = false, showTenantId = false }: Props): React$Node => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const [dataGridItems, setDataGridItems] = useState([]);

  useEffect(() => {
    const filteredItems = items?.filter(
      (item: TbAssetItem) =>
        item.type.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setDataGridItems(filteredItems);
  }, [items, searchText]);

  const rows = dataGridItems?.map((item, index) => ({
    itemNo: index + 1,
    id: item.id.id,
    customerId: item.customerId.id,
    tenantId: item.tenantId.id,
    name: item.name,
    type: item.type
  }));

  const handleSearchChange = (event: Event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      <TextField
        variant="outlined"
        size="small"
        margin="normal"
        fullWidth
        label={intl.formatMessage({ id: 'app.common.search' })}
        type={'text'}
        sx={{
          width: '30%'
        }}
        InputProps={{
          style: {
            borderRadius: '8px'
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: isNotEmpty(searchText) ? (
            <InputAdornment position="end">
              <IconButton aria-label="search bar" edge="end" onClick={handleClearSearch}>
                <CloseOutlinedIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        value={searchText}
        onChange={handleSearchChange}
      />
      <DataGrid
        rows={rows}
        columns={createColumns(intl, showCustomerId, showTenantId)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[100]}
        slots={{
          toolbar: GridToolbar
        }}
        density={'compact'}
        disableColumnSelector
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default AssetsDataGrid;
