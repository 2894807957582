// @flow

import { blueGrey, grey } from '@mui/material/colors';
import { Drawer } from '@mui/material';
import { useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  CustomersDataGridColumnTranslationId,
  DevicesDataGridColumnTranslationId,
  GatewayDataGridColumnTranslationId,
  ManagerDataGridColumnTranslationId
} from 'mappers/translationMappers';
import { DATA_GRID_TYPE } from 'constants/GlobalConstants';
import { topBarHeight } from 'components/navigation/NavigationBar';

export const containersPadding = 2.5;
const drawerWidth = 63;

const resolveTranslationId = (dataGridType, key) => {
  if (dataGridType === DATA_GRID_TYPE.GATEWAY_DATA_GRID) {
    return GatewayDataGridColumnTranslationId[key];
  } else if (dataGridType === DATA_GRID_TYPE.CUSTOMERS_DATA_GRID) {
    return CustomersDataGridColumnTranslationId[key];
  } else if (dataGridType === DATA_GRID_TYPE.DEVICES_DATA_GRID) {
    return DevicesDataGridColumnTranslationId[key];
  } else if (dataGridType === DATA_GRID_TYPE.MANAGER_DATA_GRID) {
    return ManagerDataGridColumnTranslationId[key];
  }
};

type Props = {
  dataGridType: string,
  open: boolean,
  closeDrawer: Function,
  visibleColumns: Object,
  setVisibleColumns: Function
};

const DataGridSettingsDrawer = ({ dataGridType, open, closeDrawer, visibleColumns, setVisibleColumns }: Props) => {
  const theme = useTheme();
  const intl = useIntl();

  const handleColumnVisibilityChange = (columnGroupLabel: string, columnLabel: string, checked: boolean) => {
    const columnGroup = visibleColumns[columnGroupLabel];

    const updatedColumnGroup = {
      ...columnGroup,
      [columnLabel]: checked
    };

    setVisibleColumns({
      ...visibleColumns,
      [columnGroupLabel]: updatedColumnGroup
    });
  };

  const handleGroupVisibilityChange = (groupLabel: string, checked: boolean) => {
    const groupColumns = visibleColumns[groupLabel];

    let updatedColumnGroup;
    Object.keys(groupColumns)?.map(
      (columnLabel) =>
        (updatedColumnGroup = {
          ...updatedColumnGroup,
          [columnLabel]: checked
        })
    );

    setVisibleColumns({
      ...visibleColumns,
      [groupLabel]: updatedColumnGroup
    });
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: theme.spacing(drawerWidth),
          overflow: 'hidden'
        }
      }}
      sx={{ zIndex: theme.zIndex.modal + 1 }}
    >
      <Box
        sx={{
          height: `calc(${window.innerHeight}px - ${theme.spacing(topBarHeight)})`,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            height: theme.spacing(topBarHeight),
            borderBottom: `1px solid ${grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: containersPadding
          }}
        >
          <Typography variant="h6" sx={{ color: blueGrey[800] }}>
            {intl.formatMessage({ id: 'app.datagrid.drawer.visible.columns.title' })}
          </Typography>
          <IconButton onClick={closeDrawer}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            p: containersPadding
          }}
        >
          {Object.keys(visibleColumns)?.map((groupKey) => {
            const groupColumns = visibleColumns[groupKey];
            let isGroupChecked = true;
            Object.keys(groupColumns)?.forEach(
              (columnLabel) => (isGroupChecked = isGroupChecked && groupColumns[columnLabel])
            );

            const groupTitle = groupKey?.replace('_', ' ');
            return (
              <Box key={groupKey}>
                <FormControlLabel
                  label={groupTitle}
                  control={
                    <Checkbox
                      size="small"
                      checked={isGroupChecked}
                      onChange={(event) => handleGroupVisibilityChange(groupKey, event.target.checked)}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  {Object.keys(groupColumns)?.map((columnLabel) => {
                    return (
                      <FormControlLabel
                        key={columnLabel}
                        label={intl.formatMessage({ id: resolveTranslationId(dataGridType, columnLabel) })}
                        control={
                          <Checkbox
                            checked={groupColumns[columnLabel]}
                            onChange={(event) =>
                              handleColumnVisibilityChange(groupKey, columnLabel, event.target.checked)
                            }
                            size="small"
                            sx={{ p: 0.5 }}
                          />
                        }
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DataGridSettingsDrawer;
