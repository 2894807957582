// @flow
import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import * as locales from '@mui/material/locale';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from 'App';
import { getSelectedLocale, getThemeMode, isBooted, isLoading } from 'store/selector/ApplicationSelector';
import { getMessages } from 'i18n/LocaleUtils';
import AppAlerts from 'AppAlerts';
import bootApplication from 'Bootstrap';
import CssBaseline from '@mui/material/CssBaseline';

const ThemedApp = (): React$Node => {
  const isAppBooted = useSelector(isBooted);
  const isAppLoading = useSelector(isLoading);
  const selectedLocale = useSelector(getSelectedLocale);
  const themeMode = useSelector(getThemeMode);
  const dispatch = useDispatch();

  let muiLocale = selectedLocale.replace('-', '');
  const themeWithLocale = React.useMemo(
    () =>
      createTheme(
        {
          typography: {
            fontFamily: ['Ubuntu', 'Roboto'].join(',')
          },
          palette: {
            mode: themeMode,
            primary: {
              main: '#384044',
              contrastText: 'white'
            }
          }
        },
        locales[muiLocale]
      ),
    [muiLocale, themeMode]
  );

  const messages = getMessages(selectedLocale);

  useEffect(() => {
    bootApplication(dispatch);
  }, []);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={selectedLocale}>
        <IntlProvider locale={selectedLocale} messages={messages}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isAppLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <AppAlerts />
          <CssBaseline />
          {isAppBooted && <App />}
        </IntlProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ThemedApp;
