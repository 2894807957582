// @flow
import { ValidationError } from 'yup';
import { IntlShape } from 'react-intl';
import { isEmpty, isNotEmptyList } from 'util/ObjectUtils';

export const hasFieldError = (fieldName: string, fieldErrors: Array<ValidationError>): boolean => {
  const index = fieldErrors?.findIndex((error) => error.path === fieldName) ?? -1;
  return index > -1;
};

export const getFieldErrorMessage = (
  intl: IntlShape,
  fieldName: string,
  fieldErrors: Array<ValidationError>
): boolean => {
  const validationError = fieldErrors?.find((error) => error.path === fieldName);
  let message = validationError?.message;

  if (isEmpty(message)) {
    return;
  }

  // Falling back to the default error message from YUP if no custom translation found.
  return intl.formatMessage({ id: message, defaultMessage: message });
};

export const resolveBackendValidationErrors = (error: any): Array<ValidationError> => {
  const errorsList = error?.errors;
  let validationErrors = [];
  if (isNotEmptyList(errorsList)) {
    validationErrors = errorsList.map((errorItem) => ({
      path: errorItem.fieldName,
      message: errorItem.error
    }));

    return validationErrors ?? [];
  }
};
