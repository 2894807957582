// @flow

import { grey } from '@mui/material/colors';
import { InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isEmpty, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { useRef, useState } from 'react';
import type { TbLocationItem } from 'types/Thingsboard.types';
import { createStyles, makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

type Props = {
  items: Array<TbLocationItem>,
  setSearchedItem: Function,
  mapElementsSettings: Object
};

const useDropdownStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: '0'
    }
  })
);

const SEARCH_WIDTH = '315px';

const WorldMapSearch = ({ items, setSearchedItem, mapElementsSettings }: Props): React$Node => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const [suggestedItems, setSuggestedItems] = useState([]);

  const anchorTextField = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const searchMenuOpen = Boolean(anchorEl) && isNotEmptyList(suggestedItems);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchText(searchTerm);

    const timer = setTimeout(() => {
      let filteredItems = items?.filter((item: TbLocationItem): boolean => {
        const nameMatch =
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.customerName?.toLowerCase()?.includes(searchTerm?.toLowerCase());

        return nameMatch && mapElementsSettings[item.type];
      });
      setSuggestedItems(filteredItems);
      if (isNotEmptyList(filteredItems)) {
        handleSuggestedSearchOptionsOpen(anchorTextField);
      }
    }, 500);

    return () => clearTimeout(timer);
  };

  const handleSearchClear = () => {
    setSearchText('');
    setSearchedItem(null);
  };

  const handleSuggestedSearchOptionsOpen = (anchor) => {
    setAnchorEl(anchor.current);
  };

  const handleSuggestedSearchOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleSearchedItemSelection = (item) => {
    setSearchedItem(item);
    setSearchText(item?.name);
  };

  const dropdownMenuStyles = useDropdownStyles();
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="dropdown-menu"
        open={searchMenuOpen}
        onClose={handleSuggestedSearchOptionsClose}
        onClick={handleSuggestedSearchOptionsClose}
        classes={{ list: dropdownMenuStyles.list }}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            maxHeight: '50vh',
            width: SEARCH_WIDTH,
            border: `1px solid ${grey[400]}`
          }
        }}
      >
        {suggestedItems?.map((item: TbLocationItem) => {
          let name = item?.name;
          return (
            <MenuItem
              key={`asset-${name}`}
              sx={{
                pt: 1,
                pb: 1,
                color: grey[200],
                ':hover': {
                  cursor: 'pointer',
                  borderRadius: '4px',
                  backgroundColor: grey[300],
                  color: grey[700]
                }
              }}
              onClick={() => handleSearchedItemSelection(item)}
            >
              <ListItemText>
                <Typography variant="body1" sx={{ fontWeight: 500, color: grey[700] }}>
                  {`${name} ${isEmpty(item.longitude) || isEmpty(item.latitude) ? '(No Coordinates)' : ''}`}
                </Typography>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      <TextField
        ref={anchorTextField}
        variant="outlined"
        size="small"
        margin="normal"
        fullWidth
        label={intl.formatMessage({ id: 'app.general.overview.search.placeholder' })}
        sx={{ width: SEARCH_WIDTH }}
        InputLabelProps={{
          style: {
            color: grey[600],
            fontSize: '14px',
            paddingLeft: '8px',
            paddingTop: '3px'
          }
        }}
        inputProps={{
          style: {
            form: {
              autocomplete: 'off'
            }
          }
        }}
        InputProps={{
          style: {
            borderRadius: '8px'
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: isNotEmpty(searchText) ? (
            <InputAdornment position="end">
              <IconButton aria-label="search bar" edge="end" onClick={handleSearchClear}>
                <CloseOutlinedIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        value={searchText}
        onChange={handleSearchChange}
      />
    </>
  );
};

export default WorldMapSearch;
