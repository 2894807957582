// @flow

export const SUBSCRIPTION_STATUS_ENUM = {
  ACTIVE: 'active',
  FUTURE: 'future',
  CANCELED: 'cancelled',
  NON_RENEWING: 'non_renewing'
};

export const INVOICE_STATUS_ENUM = {
  PAID: 'paid',
  NOT_PAID: 'not_paid',
  PAYMENT_DUE: 'payment_due'
};

export const SUBSCRIPTION_TYPES_ENUM = {
  TEMPERATURE_SENSORS_EUR_YEARLY: 'Temperature-Sensors-EUR-Yearly',
  MOISTURE_SENSORS_EUR_YEARLY: 'Moisture-Sensors-EUR-Yearly',
  AGROLOG_SUBSCRIPTION_EUR_EVERY_3_YEARS: 'Agrolog-Subscription-EUR-Every-3-years',
  AGROLOG_MANAGER_BASE_FEE_EUR_YEARLY: 'AgroLog-Manager-Base-Fee-EUR-Yearly',
  AGROLOG_SUBSCRIPTION_EUR_YEARLY: 'Agrolog-Subscription-EUR-Yearly',
  CSS_TM_SUBSCRIPTION_EUR_YEARLY: 'CSS-TM-Subscription-EUR-Yearly',
  CSS_TM_SUBSCRIPTION_EUR_EVERY_3_YEARS: 'CSS-TM-Subscription-EUR-Every-3-years'
};
