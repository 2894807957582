// @flow
import { fetchUserDetails } from 'api/service/UserApiService';
import { setApplicationSettings, setBooted, startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { fetchApplicationSettings } from 'api/service/AuthenticationApiService';
import type { ApplicationSettings } from 'types/State.types';

const bootApplication = (dispatch: Dispatch) => {
  dispatch(startLoading());

  fetchApplicationSettings(dispatch).then((response: ApplicationSettings) => {
    dispatch(setApplicationSettings(response));
  });
  fetchUserDetails(dispatch).finally(() => {
    dispatch(setBooted(true));
    dispatch(stopLoading());
  });
};

export default bootApplication;
