// @flow
import { useIntl } from 'react-intl';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import { useDispatch } from 'react-redux';
import { ValidationError } from 'yup';

import { LOGIN } from 'routing/RouteConstants';
import { requestPasswordReset } from 'api/service/AuthenticationApiService';
import { validateRequestResetForm } from 'validators/Authentication.validator';
import { getFieldErrorMessage, hasFieldError } from 'util/ValidationUtils';
import { Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

const RequestResetPassword = (): React$Node => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [fieldErrors, setFieldErrors] = useState([]);
  const [requestSuccess, setRequestSuccess] = useState(false);

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let resetData = {
      email: data.get('email')
    };

    validateRequestResetForm(resetData)
      .then(() => {
        setFieldErrors([]);
        requestPasswordReset(resetData, dispatch).then(() => setRequestSuccess(true));
      })
      .catch((validationResult: ValidationError) => {
        setFieldErrors(validationResult?.inner ?? []);
      });
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {intl.formatMessage({ id: 'app.resetPassword.label' })}
      </Typography>
      {requestSuccess ? (
        <Paper elevation={1} sx={{ m: 2, p: 5 }}>
          <Box
            sx={{
              color: 'success.light',
              fontSize: 'h3.fontSize',
              fontWeight: 'light',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <DoneSharpIcon sx={{ fontSize: 60 }} />
              {intl.formatMessage({ id: 'app.common.success' })}
            </Stack>
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 'h8.fontSize', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'app.resetPasswordRequest.success.message' })}
          </Box>
        </Paper>
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={intl.formatMessage({ id: 'app.common.email' })}
            name="email"
            autoComplete="email"
            autoFocus
            error={hasFieldError('email', fieldErrors)}
            helperText={getFieldErrorMessage(intl, 'email', fieldErrors)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {intl.formatMessage({ id: 'app.resetPassword.label' })}
          </Button>
          <Grid container>
            <Grid item xs>
              <Button variant="text" onClick={() => navigate(LOGIN)}>
                {intl.formatMessage({ id: 'app.resetPassword.back' })}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default RequestResetPassword;
